import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Card } from 'react-bootstrap';
// import PropTypes from 'prop-types';
import {  forgotPasswordRequest } from '../../actions/ForgotPasswordAction';
import { verifyAccount } from "../../actions/VerifyAccountAction";
import { FirstLetterCapitalize } from '../../utils/common';

const ForgotPasswordScreen = ({ forgotPasswordRequest, isLoading, data }) => {
    const [emailIdOrMobileNumber, setEmailIdOrMobileNumber] = useState();
    const [error,setError]=useState('');
    const onChange = e =>
    {
        const emailIdOrMobileNumber = e.target.value?.trim();
        setError(validateEmailOrMobile(emailIdOrMobileNumber)?'Invalid Email or Mobile':'');
        setEmailIdOrMobileNumber(emailIdOrMobileNumber);
    }

    const validateEmailOrMobile =(emailIdOrMobileNumber)=> !emailIdOrMobileNumber.includes("@") && (emailIdOrMobileNumber.length !== 10 || isNaN(emailIdOrMobileNumber));

  const onVerify = e => {
    e.preventDefault();
    if (validateEmailOrMobile(emailIdOrMobileNumber)) return setError("Invalid Email or Mobile.");
    else setError('');
    forgotPasswordRequest(emailIdOrMobileNumber);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gracewell - Forgot Password</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="row" style={{margin:'45px 0 45px 0'}}>
        <div className="col-sm-3"></div>
        <Card className="col-sm-5 p-4 bg-light">
          {/* <h1 className="large text-primary">Verify</h1> */}
          <p className="lead">
            <i className="fa fa-user" /> Forgot Password
      </p>
          <hr />
          {data ? (<p className={data.status ? "alert alert-info" : "alert alert-danger"}> {FirstLetterCapitalize(data.message)} </p>) : null}
          <form className="form">
            <div className="row">
              <div className="col-12">
                <label>Please Enter your Registered Email Id or Mobile Number</label>
                <input
                  className={`form-control ${error? 'is-invalid':''}`}
                  type="text"
                  placeholder="Email Id /  Mobile Number"
                  name="emailIdOrMobileNumber"
                  value={emailIdOrMobileNumber}
                  onChange={onChange}
                />
                {error ? (<p className="text text-danger">{error}</p>) : null}
              </div>
            </div>
            <div className="form-group">
              {!isLoading ? (<>
                <button type="button" className="btn btn-success" onClick={onVerify} ><i className="fa fa-check"></i> Reset Password</button>
              </>) : <i className="fa fa-spin fa-spinner"></i>}
            </div>
          </form>
          <p className="my-1">
            {/* Don't have an account? <Link to="/register">Login Up</Link> */}
          </p>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  isLoading: state.ForgotReducer.isLoading,
  data: state.ForgotReducer.data,
});

export default connect(mapStateToProps, { forgotPasswordRequest })(ForgotPasswordScreen);