import React from 'react';
import { Redirect } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function SliderComponent(props) {
    const { sliders} = props;
    
    return (
        <Carousel infiniteLoop autoPlay showStatus={false} showIndicators={false} 
            showThumbs={false}
            onClickItem={(item)=>{
                const category =  sliders[item];
                window.open(`/store/${category.categoryid}/${category.categoryname}`,"_self");
            }}
            >
            {sliders?.map((slider, index) =>
                <div key={index}>
                    <img src={slider.bannerphoto} alt={slider.bannerphoto} />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
            )}
        </Carousel>
    )
}

export default React.memo(SliderComponent);
