import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Card } from 'antd';
// import Custom Components
import Breadcrumb from '../common/breadcrumb';
import { fetchingFavourite, addFavourite } from '../../actions/FavouriteActions';
import { addToCartFromWishlist } from '../../actions/QuickViewModelsActions';

import { FirstLetterCapitalize } from '../../utils/common';
import EmptyStateAnime from '../anime/EmptyStateAnime';

function Wishlist(props) {
    const { wishlist, fetchingFavourite, isLoading } = props;

    useEffect(() => {
        fetchingFavourite();
        let items = document.querySelectorAll(".action-col .dropdown");

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.addEventListener("click", dropDownHandle);
        }
        document.addEventListener("click", hiddenHandle);

        return () => {
            document.removeEventListener("click", hiddenHandle);
            let items = document.querySelectorAll(".action-col .dropdown");
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                item.removeEventListener("click", dropDownHandle);
            }
        }
    }, [fetchingFavourite])

    const dropDownHandle = (e) => {
        let item = e.currentTarget;
        item.classList.add("show");
        item.querySelector(".dropdown-menu").classList.add("show");
        item.querySelector(".dropdown-menu").setAttribute("style", "position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 40px, 0px);");
        e.stopPropagation();
    }

    const hiddenHandle = () => {
        let items = document.querySelectorAll(".action-col .dropdown");
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.classList.remove("show");
            item.querySelector(".dropdown-menu").classList.remove("show");
            item.querySelector(".dropdown-menu").setAttribute("style", "");
        }
    }

    return (
        <>
            <Helmet>
                <title>Gracewell | Wishlist</title>
            </Helmet>
            <h1 className="d-none">Gracewell - Wishlist</h1>
            <div className="main">
                <Breadcrumb title="Wishlist" />
                <div className="page-content">                 
                    <div className="container">
                        <div className="row">
                            {
                                !isLoading ?
                                    wishlist && wishlist?.favvariables?.length > 0 ?
                                        wishlist.favvariables.map((item, index) =>
                                            <div className="col-3">
                                                <Card
                                                    // style={{ width: 300 }}
                                                    cover={
                                                        <img
                                                            style={{ height: "220px" }}
                                                            alt={item.productname}
                                                            src={item.productphoto}
                                                        />
                                                    }
                                                    actions={[
                                                        <button className="btn btn-sm-danger"
                                                            onClick={(e) => props.addFavourite(item.productid)}
                                                        > <i className="fa fa-times"></i>
                                                        </button>
                                                    ]}
                                                >
                                                    <Link to={`${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}`}>
                                                        {FirstLetterCapitalize(item.productname)}
                                                    </Link>
                                                    <br/>
                                                    <span className="text text-success">₹ {
                                                        item.productdisprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                    }</span>
                                                    <span className="text text-danger"> <del>₹ {item.productprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</del> </span>
                                                
                                                    <br/>
                                                    <span className={0 < item.productquantity ? 'in-stock' : 'out-of-stock'}>
                                                        <small>
                                                            {0 < item.productquantity ? 'In stock' : 'Out of stock'}
                                                        </small>
                                                    </span>
                                                </Card>
                                            </div>
                                        ) :
                                    <div className="row justify-content-sm-center">
                                        <div className="form-group tw-justify-center">
                                            <EmptyStateAnime />
                                            <h4 className="tw-text-8xl">Your Wishlist is Empty.</h4>
                                            <h2 className="tw-text-gray-400 tw-flex tw-justify-center">Looks like you haven't added anything to your wishlist yet.</h2>
                                            <br/>
                                            <Link to="/" className="btn btn-primary tw-flex">Continue Shopping</Link>
                                        </div>
                                    </div> : 
                                ""
                            }
                        </div>
                        <br/>
                        <div className="wishlist-share">
                            <div className="social-icons social-icons-sm mb-2">
                                <label className="social-label">Share on:</label>
                                <a href="https://www.facebook.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="icon-facebook-f"></i></a>
                                <a href="https://twitter.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Twitter"><i className="icon-twitter"></i></a>
                                <a href="https://instagram.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Instagram"><i className="icon-instagram"></i></a>
                                <a href="https://youtube.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Youtube"><i className="icon-youtube"></i></a>
                                <a href="https://pinterest.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Pinterest"><i className="icon-pinterest"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.favouriteReducer.isLoading,
    wishlist: state.favouriteReducer.data
});

export default connect(mapStateToProps, { addToCartFromWishlist, fetchingFavourite, addFavourite })(Wishlist);