import React from "react";
import { Modal,Tab, Row, Nav,Col, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Switch } from '@headlessui/react'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import { fetchingFilters } from "../../actions/FiltersAction";
import { findIndex } from '../../utils/index';
import { FirstLetterCapitalize } from '../../utils/common';
const FilterModal = ({ show, handleClose,filters,sizes,setSizes,colors,setColors,brands,setBrands,priceRange,setPriceRange,discounted,setDiscounted,applyFilters}) => {

    function handleSizeFilter(sizeId) {
        let size=sizes;
        let index = findIndex(size, item => item === sizeId);
        if (-1 === index) {
            size.push(sizeId);
        } else {
            size.splice(index, 1);
        }
        setSizes([...size]);
    }

    function handleColorFilter(colorId) {
        let previousColors = colors;
        let index = findIndex(colors, item => item === colorId);
        if (-1 === index) {
            previousColors.push(colorId);
        } else {
            previousColors.splice(index, 1);
        }
        setColors([...previousColors]);
    }

    function handleBrandFilter(brandId) {
        let previousBrands=brands;
        let index = findIndex(brands, item => item === brandId);
        if (-1 === index) {
            previousBrands.push(brandId);
        } else {
            previousBrands.splice(index, 1);
        }
        setBrands([...previousBrands]);
    }

    function isAvailable(arr,item){
        return -1 < findIndex(arr, filter => filter === item)?true:false
    }

    return (
    <>
    <Modal 
        show={show} 
        onHide={handleClose} 
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="modal-90w"
        >
        <Modal.Header closeButton>
          <Modal.Title>Filters </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tab.Container id="left-tabs-example" defaultActiveKey="size" >
                <Row>
                    <Col sm={3} >
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item className="filter-tabs">
                                <Nav.Link className="p-3" eventKey="size">SIZE</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="filter-tabs">
                                <Nav.Link className="p-3" eventKey="color">COLOR</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="filter-tabs">
                                <Nav.Link className="p-3" eventKey="brand">BRAND</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="filter-tabs">
                                <Nav.Link className="p-3" eventKey="price">PRICE</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="filter-tabs">
                                <Nav.Link className="p-3" eventKey="discount">DISCOUNT</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="size">
                                <div className="tw-grid tw-grid-cols-6 tw-gap-6" >
                                    {filters?.sizevariables?.map((item, index) => (
                                        <Switch.Group key={index}>
                                            <div className="flex items-center">
                                                <Switch
                                                    checked={isAvailable(sizes,item.sizeid)}
                                                    onChange={(e) => handleSizeFilter(item.sizeid)}
                                                    className={`${
                                                        isAvailable(sizes,item.sizeid) ? 'theme-color' : 'tw-bg-gray-200'
                                                    } tw-relative tw-inline-flex tw-items-center tw-h-6 tw-rounded-full tw-w-11`}
                                                    >
                                                    <span
                                                        className={`${
                                                            isAvailable(sizes,item.sizeid) ? 'tw-translate-x-6' : 'tw-translate-x-1'
                                                        } tw-inline-block tw-w-4 tw-h-4 tw-transform tw-bg-white tw-rounded-full`}
                                                    />
                                                </Switch>
                                                <Switch.Label className="ml-2">{item.size.toUpperCase()}</Switch.Label>
                                            </div>
                                        </Switch.Group>
                                    ))}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="color">
                                <div className="tw-grid tw-grid-cols-6 tw-gap-6" >
                                    {
                                        filters?.colorvariables?.map((item, index) => (
                                            <>
                                                <OverlayTrigger
                                                    key="bottom"
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip-bottom`}>
                                                            <strong className="tw-text-xl">{ FirstLetterCapitalize(item.colorname)}</strong>
                                                        </Tooltip>
                                                    }
                                                    >
                                                    <div className="round" key={index}>
                                                        <input type="checkbox"
                                                            id={`color-${index + 1}`}
                                                            onClick={(e) => handleColorFilter(item.colorid)}
                                                            defaultChecked={isAvailable(colors,item.colorid)}
                                                        />
                                                        <label 
                                                            htmlFor={`color-${index + 1}`} 
                                                            style={{backgroundColor:item.color}}
                                                            >
                                                        </label>
                                                    </div>
                                                </OverlayTrigger>
                                            </>
                                            ))
                                    }
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="brand">
                            <div className="tw-grid tw-grid-cols-4 tw-gap-6" >
                                    {filters?.brandvariables?.map((item, index) => (
                                        <Switch.Group key={index}>
                                            <div className="flex items-center">
                                                <Switch
                                                    checked={isAvailable(brands,item.brandid)}
                                                    onChange={(e) => handleBrandFilter(item.brandid)}
                                                    className={`${
                                                        isAvailable(brands,item.brandid) ? 'theme-color' : 'tw-bg-gray-200'
                                                    } tw-relative tw-inline-flex tw-items-center tw-h-6 tw-rounded-full tw-w-11`}
                                                    >
                                                    <span
                                                        className={`${
                                                            isAvailable(brands,item.brandid) ? 'tw-translate-x-6' : 'tw-translate-x-1'
                                                        } tw-inline-block tw-w-4 tw-h-4 tw-transform tw-bg-white tw-rounded-full`}
                                                    />
                                                </Switch>
                                                <Switch.Label className="ml-2">{item.brand.toUpperCase()}</Switch.Label>
                                            </div>
                                        </Switch.Group>
                                    ))}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="price">
                            <div className="filter-price">
                                <div className="filter-price-text">
                                    Price Range:&nbsp;
                                    <span className="filter-price-range">₹{priceRange?.min} - ₹{priceRange?.max}</span>
                                </div>

                                <div className="price-slider">
                                    <InputRange
                                        formatLabel={value => `₹${value}`}
                                        maxValue={parseInt(filters?.pricevariables[0]?.maxprice || 1000)}
                                        minValue={parseInt(filters?.pricevariables[0]?.minprice || 0)}
                                        step={50}
                                        value={priceRange}
                                        onChange={value => { 
                                            setPriceRange(value) }}
                                    />
                                </div>
                            </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="discount">
                                <div className="filter-price-text">
                                    Discount Range:&nbsp;
                                    <span className="filter-price-range">{discounted?.min}% - {discounted?.max}%</span>
                                </div>

                                <div className="price-slider">
                                    <InputRange
                                        formatLabel={value => `${value}`}
                                        maxValue={parseInt(filters?.discountvariables[0]?.maxdiscount || 100)}
                                        minValue={parseInt(filters?.discountvariables[0]?.mindiscount || 0)}
                                        step={5}
                                        value={discounted}
                                        onChange={value => { 
                                            setDiscounted(value) }}
                                    />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
            <button 
                onClick={handleClose}
                className="btn btn-outline-danger"
            >
                Close
            </button>
            <button 
                onClick={applyFilters}
                className="btn btn-outline-primary"
            >
                    Apply Filters
            </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export const mapStateToProps = (state) => {
    return {
        filters: state.FilterReducer.data
    }
}
export default connect(mapStateToProps, { fetchingFilters,})(FilterModal);;