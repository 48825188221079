import { 
    SIZE_CHART_LOADING,
    SIZE_CHART,
    SIZE_CHART_FAILED
 } from "../actions/types";

const initialState={
    isLoading:true,
    data : null
};

function SizeChartReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case SIZE_CHART_LOADING:
            return {
                isLoading : true
            };
        case SIZE_CHART:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
            };
        case SIZE_CHART_FAILED:
            return {
                ...state,
                isLoading:false,
                data:{...payload}
            };
        default:
            return state;
    }
}

export default SizeChartReducer;