import React, { useState } from 'react';
import { connect } from 'react-redux';
// import Modal from 'react-modal';
import { notification } from 'antd';
import { closeModal } from '../../../actions/QuickViewModelsActions';

import { FirstLetterCapitalize } from '../../../utils/common';

// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';

// import PropTypes from 'prop-types';
import { addAddress } from '../../../actions/AddressAction';
import { fetchingFromPincode } from "../../../actions/PincodeAction";

function EditAddressScreen(props) {
    const { isLoading, data , addAddress,fetchingFromPincode,isPincodeLoading,pincodeData,setIsShown,isShown} = props;
    
    var initialState = {
        addressType: '',
        firstName: '',
        lastName: '',
        address1: '',
        //address2: '',
        pincode: '',
        country: 'India',
        countryId: '1',
        state: '',
        stateId: '',
        city: '',
        cityId: '',
    };
    
    var [formData, setFormData] = useState(initialState);

    const [mobileError, setMobileError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [pincodeError,setPincodeError]=useState('');

    const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onAddAddress = e => {
        e.preventDefault();
        notification.open({
            message: 'Address Saving',
            key:'adding',
            placement:'bottomRight',
            description:
              'Your Request is in Progress.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });

        addAddress({
            ...formData,
            state:pincodeData.state,
            city : pincodeData.district
        });
    };

    if (data && data.status) {
        if(data.status) notification.success({
            message: 'Address Saved',
            key:'adding',
            placement:'bottomRight',
            description:
              'Your Request successfully executed.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        else notification.warn({
            message: 'Error',
            key:'adding',
            placement:'bottomRight',
            description:
              'Something went wrong.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });;
       setIsShown(false);
       delete data.status;
    }

    const fetchingPincode=(e)=>{
        var pincode = e.target.value;
        if(pincode){
            if(pincode.length === 6 && !isNaN(pincode))
            {
                setPincodeError('');
                fetchingFromPincode(pincode);
            }
            else
                setPincodeError('Enter a valid pincode.');
        }else setPincodeError('');
    }

    return (
        <>
            <div className="main">
                <PageHeader title="Edit Address" subTitle=" Edit Addresses" />
                <Breadcrumb title="Addresses" />
                <div className="page-content">
                    <div className="dashboard">
                        <div className="container">
                            <div className="col-4">
                                <h3 className="border-bottom mb-3">Edit Address</h3>
                                <div className="tab-content">
                                    <form onSubmit={onAddAddress} >
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor={formData.addressType}>Address Type *</label>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="addressTypeHome" name="addressType" class="custom-control-input" value="home" onChange={onChange} />
                                                    <label class="custom-control-label" for="addressTypeHome">Home</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="addressTypeOffice" name="addressType" class="custom-control-input" value="office" onChange={onChange} />
                                                    <label class="custom-control-label" for="addressTypeOffice">Office</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor={formData.firstName}>First Name *</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${mobileError ? "is-invalid" : ""}`}
                                                    name="firstName"
                                                    value={formData.firstName}
                                                    onChange={onChange}
                                                    required />
                                                {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                                            </div>
                                            <div className="col">
                                                <label htmlFor={formData.lastName}>Last Name *</label>
                                                <input type="text"
                                                    className={`form-control ${passwordError ? "is-invalid" : ""}`}
                                                    name="lastName"
                                                    value={formData.lastName}
                                                    onChange={onChange}
                                                    required />
                                                {passwordError ? (<p className="text text-danger">{passwordError}</p>) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor={formData.address1}>Address *</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${mobileError ? "is-invalid" : ""}`}
                                                    name="address1"
                                                    value={formData.address1}
                                                    onChange={onChange}
                                                    required />
                                                {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor={formData.pincode}>Pincode *</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${pincodeError ? "is-invalid" : ""}`}
                                                    name="pincode"
                                                    value={formData.pincode}
                                                    onChange={onChange}
                                                    onKeyUp={fetchingPincode}
                                                    maxLength="6"
                                                    autoComplete="false"
                                                    required />
                                                {pincodeError ? (<p className="text text-danger">{pincodeError}</p>) : null}
                                            </div>
                                            <div className="col">
                                                <label htmlFor={formData.country}>Country *</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${mobileError ? "is-invalid" : ""}`}
                                                    name="country"
                                                    value={formData.country}
                                                    onChange={onChange}
                                                    disabled
                                                    required />
                                                {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor={formData.state}>State *</label>
                                                <input
                                                    type="text"
                                                    placeholder={isPincodeLoading ? 'Data Loading...':pincodeData?.state || ''}
                                                    className={`form-control ${mobileError ? "is-invalid" : ""}`}
                                                    name="state"
                                                    value={FirstLetterCapitalize(pincodeData?.state)}
                                                    // onChange={onChange}
                                                    disabled
                                                    required />
                                                {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                                            </div>
                                            <div className="col">
                                                <label htmlFor={formData.city}>City *</label>
                                                <input
                                                    type="text"
                                                    placeholder={isPincodeLoading ? 'Data Loading...':pincodeData?.district || ''}
                                                    className={`form-control ${mobileError ? "is-invalid" : ""}`}
                                                    name="city"
                                                    value={FirstLetterCapitalize(pincodeData?.district)}
                                                    //onChange={onChange}
                                                    disabled
                                                    required />
                                                {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                                            </div>
                                        </div>
                                        {data && data.status ? <>
                                            <p
                                                className={data.status ? "alert alert-success" : "alert alert-danger"}
                                            >
                                                {FirstLetterCapitalize(data.message)}
                                            </p></>
                                            :
                                            ''
                                        }
                                        <div className="form-footer">
                                            {!isLoading ?
                                            <button type="submit" className="btn btn-outline-primary-2 mr-2" onClick={onAddAddress} >
                                                <span>Add</span>
                                                <i className="icon-long-arrow-right"></i>
                                            </button>
                                            : <i className="fa fa-spin fa-spinner"></i>}
                                            <button className="btn btn-outline-danger" onClick={()=>setIsShown(!isShown)}>
                                                <span>Cancel</span>
                                                <i className="icon-times"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.AddressReducer.isLoading,
    data: state.AddressReducer.addAddress,
    isPincodeLoading : state.PincodeReducer.isLoading,
    pincodeData : state.PincodeReducer.data
})

export default connect(mapStateToProps, { closeModal, addAddress,fetchingFromPincode })(EditAddressScreen);
