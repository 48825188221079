import qs from 'querystring';
import api from "../utils/api";
import { FETCH_COUPONS, FETCH_COUPONS_FAILED, APPLY_COUPON_LOADING, APPLY_COUPON, APPLY_COUPON_FAILED, REMOVE_COUPON } from "./types";

import {
    FetchUserIdFromLocalStorage,
    FetchGuestUserIdFromLocalStorage,
} from '../utils/common';

export const fetchingCoupons = ()=> async dispatch => {
    const body ={
        method:'coupons',
    };
    try{
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:FETCH_COUPONS,
            payload : response.data
        });
    }
    catch(err){
        dispatch({type:FETCH_COUPONS_FAILED,payload:{status:false,message:'Something went wrong.',},});
    }
}

export const applyingCoupon = (couponCode)=> async dispatch=>{
    const body ={
        method:'applycoupon',
        couponcode:couponCode,
        custid:FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        cartvalue:0,
        productid:0
    }
    try{
        dispatch({
            type:APPLY_COUPON_LOADING
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        if ( response.data.status){
            dispatch({
                type:APPLY_COUPON,
                payload : response.data
            });
        }else{
            dispatch({type:APPLY_COUPON_FAILED,payload:{status:false,message:'Invalid Coupon Code or Expired.',},});
        }
    }catch(err){
        dispatch({type:APPLY_COUPON_FAILED,payload:{status:false,message:'Something went wrong.',},});
    }
}

export const RemoveCoupon = (couponAmount) => async dispatch => {
    dispatch({
        type:REMOVE_COUPON,
        payload : {
            couponAmount:couponAmount
        }
    })
}