import { 
    CHANGE_PASSWORD, 
    CHANGE_PASSWORD_FAILED, 
    CHANGE_PASSWORD_LOADING,
 } from "../actions/types";

const initialState={
    isLoading:false,
    data : null
};

function changePasswordReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                isLoading:true,
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
            };
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                isLoading:false,
                data:{...payload}
            };
        default:
            return state;
    }
}

export default changePasswordReducer;