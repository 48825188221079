import qs from 'querystring';
import api from "../utils/api";
import { LOADING, LOGIN, LOGOUT, SENDOTP, GUEST_LOGIN,ACCOUNT_CREATION_ERROR, REMOVE_LOGIN_REGISTER_ERROR } from "./types";


export const tryLogin = (account, password) => async dispatch => {
    const body = {
        method: 'login',
        phone: account,
        pwd: password,
        devicetype: 1,
    };
    try {
        dispatch({
            type: LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body),);
        if (response.data.status) {
            localStorage.setItem("__cust__id", response.data.loginvariables[0].custid);
            localStorage.setItem("__cust__code", response.data.loginvariables[0].custcode);
            localStorage.setItem("__cust__phone", response.data.loginvariables[0].custphone);
            localStorage.setItem("__cust__is__account__active", response.data.loginvariables[0].isaccountactive);
            localStorage.setItem("__cust__email", response.data.loginvariables[0].custemail);
            dispatch({
                type: LOGIN,
                payload: response.data
            });
        } else {
            dispatch({
                type: LOGOUT,
                payload: response.data
            });
        }
    }
    catch (err) {
        dispatch({ type: LOGOUT, payload: { status: false, message: err, }, });
    }
}
export const sendOtp = (email, phone) => async dispatch => {
    const body = {
        method: 'sendotp',
        account: phone,
        email: email,
        uniqueid: null,
    }
    try {
        dispatch({
            type: LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body));
        dispatch({
            type: SENDOTP,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({
            type: LOGOUT,
            payload: {
                status: false,
                mMessage: err.Message,
            }
        });
    }
}

export const tryLogout = () => async dispatch => {
    try {
        localStorage.removeItem("__cust__id");
        localStorage.removeItem("__cust__code");
        localStorage.removeItem("__cust__phone");
        localStorage.removeItem("__cust__is__account__active");
        localStorage.removeItem("__cust__email");
        dispatch({
            type: LOGOUT
        });
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const guestLogin = () => async dispatch => {
    const body = {
        method: 'guestlogin',
    };
    try {
        dispatch({
            type: LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body),);
        if (response.data.status){
            localStorage.setItem("__guest__cust__id", response.data.loginvariables[0].custid);
            dispatch({
                type: GUEST_LOGIN,
                payload: response.data
            });
        }
        else
            dispatch({
                type: LOGOUT,
                payload: response.data,
            });
    }
    catch (err) {
        dispatch({ type: LOGOUT, payload: { status: false, message: err, }, });
    }
}

export const removeLoginRegisterError = () => async dispatch =>{
    dispatch({
        type:REMOVE_LOGIN_REGISTER_ERROR
    });
}