import { FETCH_CATEGORY_SUBCATEGORY_HEADER_LOADING,
FETCH_CATEGORY_SUBCATEGORY_HEADER,
FETCH_CATEGORY_SUBCATEGORY_HEADER_FAILED } from "../actions/types";

// import  { getCartTotal,getCartProductCount }  from '../services';
const initialState={
    isLoading:true,
};

function CategoryAndSubcategoryReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case FETCH_CATEGORY_SUBCATEGORY_HEADER_LOADING:
            return {
                ...state,
                isLoading:true,
            };
        case FETCH_CATEGORY_SUBCATEGORY_HEADER:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
            };
        case FETCH_CATEGORY_SUBCATEGORY_HEADER_FAILED:
            return {
                ...state,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default CategoryAndSubcategoryReducer;