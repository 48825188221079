import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCartTotal,getCartProductCount } from '../../../services';
import { removeCartItem } from '../../../actions/CartAction';
import { safeContent } from '../../../utils/index';

function CartMenu( { removeCartItem,cart } ) {

    let total = cart?.cartvariables ? getCartTotal( cart.cartvariables ) : 0;

    return (
        <div className="dropdown cart-dropdown">
            <Link to={ `${process.env.PUBLIC_URL}/cart` } className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                {/* <i className="icon-shopping-cart"></i> */}
                <i className="fa fa-shopping-cart tw-text-white"></i>
                <span className="cart-count">{cart && cart.status && cart.cartvariables ? getCartProductCount(cart.cartvariables) : <span>0</span>}</span>
                {/* <span className="cart-txt">&#8377; { total.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span> */}
            </Link>
            <div className={ `dropdown-menu dropdown-menu-right ${ cart && cart.status && cart?.cartvariables &&  cart?.cartvariables?.length === 0 ? 'text-center' : ''}` } >
                {
                    cart == null || 0 === cart?.cartvariables?.length ?
                        <p>No products in the cart.</p> :
                        <>
                            <div className="dropdown-cart-products">
                                { cart?.cartvariables?.map( ( item, index ) => (
                                    <div className="product" key={ index }>
                                        <div className="product-cart-details">
                                            <h4 className="product-title">
                                                <Link to={ `${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}` } className="tw-capitalize theme-text-color">{item.productname}</Link>
                                            </h4>
                                            <span className="cart-product-info">
                                                <span className="cart-product-qty">{ item.productquantity }</span>
                                                x ₹{ item.discount ? item.salePrice.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) : (item.productamount).toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }
                                            </span>
                                        </div>

                                        <figure className="product-image-container">
                                            <Link to={ `${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}` } className="product-image">
                                                <img src={ item.productphoto} data-oi={ item.productphoto } alt={item.productphoto} />
                                            </Link>
                                        </figure>
                                        <button className="btn-remove" title="Remove Product" onClick={ () => removeCartItem( item.cartid ) }><i className="icon-close"></i></button>
                                    </div>
                                ) ) }
                            </div>
                            <div className="dropdown-cart-total">
                                <span>Total</span>
                                <span className="cart-total-price">₹{ total.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span>
                            </div>
                            <Link to={ `${process.env.PUBLIC_URL}/cart` } className="btn btn-block btn-primary">View Cart</Link>
                        </>
                }
            </div>
        </div>
    );
}

function mapStateToProps( state ) {
    return {
        cart: state.cartReducer.data
    }
}

export default connect( mapStateToProps, { removeCartItem } )( CartMenu );