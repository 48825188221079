// useraddresslist

import qs from 'querystring';
import api from "../utils/api";
import {
    CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAILED

} from "./types";
import { FetchUserIdFromLocalStorage, FetchGuestUserIdFromLocalStorage } from '../utils/common';

export const changePassword = (oldPassword,newPassword) => async dispatch => {
    const body = {
        method: 'changepassword',
        // custid:"G895201",
        custid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        oldpassword:oldPassword,
        newpassword:newPassword
    };
    try {
        dispatch({
            type: CHANGE_PASSWORD_LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body),);
        dispatch({
            type: CHANGE_PASSWORD,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({ 
            type: CHANGE_PASSWORD_FAILED, 
            payload: { 
                status: false, 
                message: 'Something went wrong.', 
            }, 
        });
    }
}