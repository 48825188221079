import { LOGIN, VERIFYACCOUNT, LOGOUT, LOADING, SENDOTP,TOKEN_EXISTENCE,GUEST_LOGIN, REMOVE_LOGIN_REGISTER_ERROR } from "../actions/types";

const initialState = {
    token: localStorage.getItem('__cust__id'),
    isAuthenticated: null,
    isLoading: false,
    user: null,
};

function authReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TOKEN_EXISTENCE:
            return {
                ...state,
                isAuthenticated:true,
                isLoading:false,
            }
        case LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case LOGIN:
            return {
                ...state,
                data: payload,
                isAuthenticated: true,
                isLoading: false,
            };
        case SENDOTP:
            return {
                data: payload,
            }
        case VERIFYACCOUNT:
            return {
                ...state,
                data: payload,
                isAuthenticated: true,
                isLoading: false
            };
        case GUEST_LOGIN:
            return {
                ...state,
                guestLogin : payload.data
            };
        case LOGOUT:
            return {
                ...state,
                isLoading:false,
                isAuthenticated : false,
                data: payload,
            };
        case REMOVE_LOGIN_REGISTER_ERROR : 
            return {
                ...state,
                data:null
            };
        default:
            return state;
    }
}

export default authReducer;