import { HOME,FAILED, } from "../actions/types";

const initialState={
    isLoading:true,
};

function dashboardReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case HOME:
            return {
                ...state,
                data:payload,
                isLoading:false,
            };
        case FAILED:
            return {
                ...state,
                data:payload,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default dashboardReducer;