import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
// import Custom Components
import PageHeader from "../common/page-header";
import Breadcrumb from "../common/breadcrumb";

import { FirstLetterCapitalize } from "../../utils/common";
import { fetchingShop } from "../../actions/ShopActions";
import ProductNine from "../features/product/product-nine";
import { addFavourite } from "../../actions/FavouriteActions";
import { fetchingFilters } from "../../actions/FiltersAction";
import {
  toggleWishlist,
  addToCompare,
  showQuickViewModal,
} from "../../actions/QuickViewModelsActions";

import FilterModal from "./filterModal";
import ProductDetail from "./productDetail";

function ShopList({
  match,
  isLoading,
  shop,
  fetchingFilters,
  fetchingShop,
  addFavourite,
  cat,
  filters,
}) {
  let categoryAndSubcategoryId = match.params.storeId.split("-");
  let categoryId = categoryAndSubcategoryId[0];
  let subCategoryId = categoryAndSubcategoryId[1];
  let store = match.params.store;

  var cate;
  if (cat) cate = cat?.category?.find((cat) => cat.id === categoryId);

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  let [sizes, setSizes] = useState([]);
  let [colors, setColors] = useState([]);
  let [brands, setBrands] = useState([]);
  const [priceRange, setPriceRange] = useState({
    max: parseInt(filters?.pricevariables[0]?.maxprice || 1000),
    min: parseInt(filters?.pricevariables[0]?.minprice || 0),
  });
  const [discounted, setDiscounted] = useState({
    max: parseInt(filters?.discountvariables[0]?.maxdiscount || 5),
    min: parseInt(filters?.discountvariables[0]?.mindiscount || 0),
  });

  const [padding, setPadding] = useState();
  const [braStyle, setBraStyle] = useState();
  const [wired, setWired] = useState();
  const [coverage, setCoverage] = useState();
  const [orderby, setOrderBy] = useState();
  const [limit, setLimit] = useState(50);

  useEffect(() => {
    fetchingShop(
      categoryId,
      subCategoryId,
      sizes.toString(),
      colors.toString(),
      priceRange,
      padding,
      braStyle,
      discounted,
      wired,
      coverage,
      orderby,
      limit
    );
  }, [
    fetchingShop,
    categoryId,
    subCategoryId,
    padding,
    braStyle,
    wired,
    coverage,
    orderby,
    limit,
  ]);

  useEffect(() => {
    fetchingFilters();
  }, [fetchingFilters]);

  console.log("s", sizes, colors, brands, priceRange);

  const applyFilters = () => {
    handleClose();
    fetchingShop(
      categoryId,
      subCategoryId,
      sizes.toString(),
      colors.toString(),
      priceRange.min + "-" + priceRange.max,
      padding,
      braStyle,
      discounted.max,
      wired,
      coverage,
      orderby,
      limit
    );
  };

  return (
    <>
      <Helmet>
        <title>Gracewell | {store}</title>
      </Helmet>
      <h1 className="d-none">Gracewell - {store}</h1>
      <div className="main home-page">
        <PageHeader
          bannerImage={cate?.bannerimage}
          title={FirstLetterCapitalize(store)}
          subTitle=""
        />
        <Breadcrumb title={FirstLetterCapitalize(store)} adClass="mb-2" />
        <div className="page-wrapper">
          <div className="tw-px-10">
            <div className="row">
              <div className="col-lg-12 skel-shop-products">
                <div className="row m-2">
                  <button
                    className="pull-right btn btn-outline-info"
                    onClick={handleShow}
                  >
                    <i className="fa fa-filter"></i>Filters
                  </button>
                </div>
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <Grid container xs={12}  spacing={2}>
                    {shop.status ? (
                      shop.productsvariables.length > 0 ? (
                        shop.productsvariables.map((product, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            xxl={2}
                            key={`best_${index}`}
                          >
                            <ProductDetail
                              product={product}
                              onAddToCompare={addToCompare}
                              onToggleWishlist={toggleWishlist}
                              showQuickView={showQuickViewModal}
                              onAddToWishlist={addFavourite}
                            />
                          </Grid>
                        ))
                      ) : (
                        <div className="alert alert-warning">
                          {" "}
                          No items Available.
                        </div>
                      )
                    ) : (
                      <div className="tw-px-40">
                        <img src={cate?.nodataimage} alt={cate?.nodataimage} />
                        <h4 className="tw-text-8xl tw-flex tw-justify-center">
                          OOPS.
                        </h4>
                        <h2 className="tw-text-gray-400 tw-flex tw-justify-center">
                          Looks like we don't have any offers for you in this
                          section.
                        </h2>
                        <br />
                        <Link to="/" className="btn btn-primary tw-flex">
                          Continue Shopping
                        </Link>
                      </div>
                    )}
                  </Grid>
                )}
              </div>
              <div className="col-lg-3 order-lg-first ">
                <div className="skel-widget"></div>
                <div className="skel-widget"></div>
                <div className="skel-widget"></div>
                <div className="skel-widget"></div>
                {/* <ShopSidebar 
                                    adClass="sidebar sidebar-shop"
                                    setSizes ={setSizes}
                                    setColors={setColors}
                                    setPriceRange={setPriceRange}
                                    setPadding={setPadding}
                                    setBraStyle={setBraStyle}
                                    setDiscounted = {setDiscounted}
                                    setWired={setWired}
                                    setCoverage={setCoverage}
                                    /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterModal
        show={showModal}
        handleClose={handleClose}
        sizes={sizes}
        setSizes={setSizes}
        colors={colors}
        setColors={setColors}
        brands={brands}
        setBrands={setBrands}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        discounted={discounted}
        setDiscounted={setDiscounted}
        applyFilters={applyFilters}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.ShopReducer.isLoading,
  shop: state.ShopReducer.shop,
  cat: state.CategoryAndSubcategoryReducer.data,
  filters: state.FilterReducer.data,
});

export default connect(mapStateToProps, {
  fetchingShop,
  addFavourite,
  fetchingFilters,
})(ShopList);
