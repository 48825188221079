import qs from 'querystring';
import api from "../utils/api";
import {
    CHECKOUT_FINAL_LOADING,
    CHECKOUT_FINAL,
    CHECKOUT_FINAL_FAILED
} from "./types";

import {
    FetchUserIdFromLocalStorage,
    FetchGuestUserIdFromLocalStorage,
    FetchOrderIdFromLocalStorage
} from '../utils/common';

import { fetchingCart } from './CartAction';

export const finalCheckout = (orderId, paymentMode, paymentRefNo, paymentStatus, paymentVia, isPaymentConfirm) => async dispatch => {
    const body = {
        method: 'orderproducts',
        userid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        orderid: orderId || FetchOrderIdFromLocalStorage(),
        paymentmode: paymentMode,
        paymentrefno: paymentRefNo,
        paymentstatus: paymentStatus,
        paymentvia: paymentVia,
        ispaymentconfirm: isPaymentConfirm,
    };
    debugger;
    try {
        dispatch({
            type:CHECKOUT_FINAL_LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body), );
        console.log("response",response);
        if(response.data.status){
            dispatch(fetchingCart());
        }
        dispatch({
            type: CHECKOUT_FINAL,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: CHECKOUT_FINAL_FAILED,
            payload: {
                status: false,
                message: 'Something went wrong.',
            }
        });
    }
}