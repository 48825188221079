import React from 'react';
import { Grid } from "@mui/material";
import { FirstLetterCapitalize } from '../../utils/common';

const AddressItem = ({
    item,
    index,
    deleteAddress,
    isBillingOrShippingAddress,
    addressType,
    selectButtonUrl,
    history,
    isAddressDeleting,
    AddressIndex
}) => {
    

    const addressSelect=(e,addressId)=>{
        e.preventDefault();
        if(addressType === "1"){
            localStorage
            .setItem("billing_address",addressId);
            return history.push(selectButtonUrl);
        }else{
            localStorage
            .setItem("shipping_address",addressId);
            return history.push(selectButtonUrl);
        }
    }
    return (
        <Grid item  xs={12} sm={4} key={ 'address-item-' + index }>
            <div className="card card-dashboard">
                <div className="card-body">
                    <h3 class="card-title">
                        <i className={`fa fa-${item.custaddresstype === "office" ? "briefcase" : "home"}`}></i> 
                        {FirstLetterCapitalize(item.custaddresstype)} Address
                        <span className="pull-right">
                            {isAddressDeleting && AddressIndex === index ? 
                                <>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Loading...</span>
                                </>
                            :
                                <span  onClick={(e)=>deleteAddress(e,item.addressid,index)}>
                                    <i className="fa fa-trash text-danger"></i>
                                </span>
                            }
                        </span>
                    </h3>
                    <hr/>
                    <p>{item.custfirstname} {item.custlastname}</p>
                    <p>
                        {item.custaddress1}
                        <br/>
                        {item.custaddress2 || ''}
                        <br/>
                        {item.custcity}({item.custpincode})-{item.custstate}({item.custcountryname})
                    </p>
                    <br/>
                    {
                        isBillingOrShippingAddress ? 
                        <>
                            <button className="btn btn-primary btn-block" 
                            onClick={(e)=>addressSelect(e,item.addressid)}>
                                <i className="fa fa-check"></i> CHOOSE {isBillingOrShippingAddress === "1" ? 'BILLING' : 'SHIPPING'} ADDRESS</button>
                            <br/><br/>
                        </>
                        : ''
                    }
                    <div className="card-bottom">
                        {/* <Link className="btn btn-default" to={`/address/edit/`+item.addressid}><i className="fa fa-edit"></i> Edit</Link> */}
                        
                    </div>
                </div>
            </div>
        </Grid>
    );
}
export default AddressItem;