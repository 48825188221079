import { PRODUCT_DETAIL, PRODUCT_DETAIL_FAILED } from "../actions/types";

const initialState = {
    isLoading: true,
};

function productDetailReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRODUCT_DETAIL:
            return {
                ...state,
                data: payload,
                isLoading: false,
            };
        case PRODUCT_DETAIL_FAILED:
            console.log(state);
            return {
                ...state,
                data: payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default productDetailReducer;