import React, { useState } from 'react';
import { connect } from 'react-redux';
//  import Modal from 'react-modal';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import CustomizedDialogs from  "../../common/CommonModel";

import { FirstLetterCapitalize } from '../../../utils/common';

//  import PropTypes from 'prop-types';
import { addAddress } from '../../../actions/AddressAction';
import { fetchingFromPincode } from "../../../actions/PincodeAction";

function AddAddressScreen(props) {
    const { isLoading, data , addAddress,fetchingFromPincode,isPincodeLoading,pincodeData,setIsShown,isShown} = props;
    const initialState = {
        email:'',
        phone:'',
        addressType: 'home',
        firstName: '',
        lastName: '',
        address1: '',
        // address2: '',
        pincode: '',
        country: 'India',
        countryId: '1',
        state: '',
        stateId: '',
        city: '',
        cityId: '',
    };
    
    const [formData, setFormData] = useState(initialState);

    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [pincodeError,setPincodeError]=useState('');
    const [firstNameError,setFirstNameError]=useState('');
    const [lastNameError,setLastNameError]=useState('');
    const [addressError,setAddressError]=useState('');

    const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleSave = e => {
        e.preventDefault();
        
        
        if(!formData.email || !formData.email.includes("@")) return setEmailError("Please Enter A Valid Email.") 
        else setEmailError('');
        if(!formData.phone || formData.phone.length != 10 || isNaN(formData.phone) ) return setMobileError("Please Enter A Valid Phone.");
        else setMobileError('');
        if(!formData.pincode || formData.pincode.length != 6 || isNaN(formData.pincode)) return setPincodeError("Please Enter A Valid Pincode.");
        else setPincodeError('');
        if(!formData.firstName) return setFirstNameError("Please Enter A Valid First Name");
        else setFirstNameError('');
        if(!formData.lastName) return setLastNameError("Please Enter A Valid Last Name");
        else setLastNameError('');
        if(!formData.address1) return setAddressError("Please Enter A Valid Address Name");
        else setAddressError('');
        if(!pincodeData || !pincodeData.state || !pincodeData.district) return setPincodeError("Please Enter A Valid Pincode.");
        else setPincodeError('');
        
        addAddress({
            ...formData,
            state:pincodeData.state,
            city : pincodeData.district
        });
    };

    if (data && data.status) {
       setIsShown(false);
       delete data.status;
    }

    const fetchingPincode=(e)=>{
        var pincode = e.target.value;
        if(pincode){
            if(pincode.length === 6 && !isNaN(pincode))
            {
                setPincodeError('');
                fetchingFromPincode(pincode);
            }
            else
                setPincodeError('Enter a valid pincode.');
        }else setPincodeError('');
    }
    
    return (
        <CustomizedDialogs 
            open={isShown} 
            headerText="Add New Address" 
            handleClose={(e)=>{
                e.preventDefault();
                setIsShown(!isShown);
            }}
            handleSave={handleSave}
            isLoading={isLoading}
            buttonText="Save"
        >
            <form onSubmit={handleSave} >
                <div className="row">
                    <div className="col">
                        <FormControl>
                            <FormLabel id="addressType">Address Type</FormLabel>
                            <RadioGroup 
                                row
                                aria-labelledby="addressType"
                                name="addressType"
                                value={formData.addressType}
                                onChange={onChange}
                            >
                                <FormControlLabel value="home" control={<Radio />} label="Home" />
                                <FormControlLabel value="office" control={<Radio />} label="Office" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor={formData.email}>Email Id *</label>
                        <input
                            type="text"
                            className={`form-control ${emailError ? "is-invalid" : ""}`}
                            name="email"
                            value={formData.email}
                            onChange={onChange}
                            required />
                        {emailError ? (<p className="text text-danger">{emailError}</p>) : null}
                    </div>
                    <div className="col">
                        <label htmlFor={formData.phone}>Phone *</label>
                        <input type="text"
                            className={`form-control ${mobileError ? "is-invalid" : ""}`}
                            name="phone"
                            value={formData.phone}
                            onChange={onChange}
                            required />
                        {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor={formData.firstName}>First Name *</label>
                        <input
                            type="text"
                            className={`form-control ${firstNameError ? "is-invalid" : ""}`}
                            name="firstName"
                            value={formData.firstName}
                            onChange={onChange}
                            required />
                        {firstNameError ? (<p className="text text-danger">{firstNameError}</p>) : null}
                    </div>
                    <div className="col">
                        <label htmlFor={formData.lastName}>Last Name *</label>
                        <input type="text"
                            className={`form-control ${lastNameError ? "is-invalid" : ""}`}
                            name="lastName"
                            value={formData.lastName}
                            onChange={onChange}
                            required />
                        {lastNameError ? (<p className="text text-danger">{lastNameError}</p>) : null}
                    </div>
                </div>
                <div className="row">
                                 <div className="col">
                                     <label htmlFor={formData.address1}>Address *</label>
                                     <input
                                         type="text"
                                         className={`form-control ${addressError ? "is-invalid" : ""}`}
                                         name="address1"
                                         value={formData.address1}
                                         onChange={onChange}
                                         required />
                                     {addressError ? (<p className="text text-danger">{addressError}</p>) : null}
                                 </div>
                             </div>
                {/* <div className="row">
                    <div className="col">
                        <label htmlFor={formData.address2}>Alternative Address *</label>
                        <input
                            type="text"
                            className={`form-control ${mobileError ? "is-invalid" : ""}`}
                            name="address2"
                            value={formData.address2}
                            onChange={onChange}
                            required />
                        {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                    </div>
                </div> */}
                <div className="row">
                    <div className="col">
                        <label htmlFor={formData.pincode}>Pincode *</label>
                        <input
                            type="text"
                            className={`form-control ${pincodeError ? "is-invalid" : ""}`}
                            name="pincode"
                            value={formData.pincode}
                            onChange={onChange}
                            onKeyUp={fetchingPincode}
                            maxLength="6"
                            autoComplete="false"
                            required />
                        {pincodeError ? (<p className="text text-danger">{pincodeError}</p>) : null}
                    </div>
                    <div className="col">
                        <label htmlFor={formData.country}>Country *</label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="country"
                            value={formData.country}
                            onChange={onChange}
                            disabled
                            required />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor={formData.state}>State *</label>
                        <input
                            type="text"
                            placeholder={isPincodeLoading ? 'Data Loading...':pincodeData?.state || ''}
                            className={`form-control tw-capitalize `}
                            name="state"
                            value={pincodeData?.state}
                            onChange={onChange}
                            disabled
                            required />
                    </div>
                    <div className="col">
                        <label htmlFor={formData.city}>City *</label>
                        <input
                            type="text"
                            placeholder={isPincodeLoading ? 'Data Loading...':pincodeData?.district || ''}
                            className={`form-control tw-capitalize `}
                            name="city"
                            value={pincodeData?.district}
                            onChange={onChange}
                            disabled
                            required />
                    </div>
                </div>
            
                {data && data.status ? <>
                    <p
                        className={data.status ? "alert alert-success" : "alert alert-danger"}
                    >
                        {FirstLetterCapitalize(data.message)}
                    </p></>
                    :
                    ''
                }
            </form>
        </CustomizedDialogs>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.AddressReducer.isLoading,
    data: state.AddressReducer.addAddress,
    showModal: state.modal.showModal,
    modal: state.modal.modal,
    isPincodeLoading : state.PincodeReducer.isLoading,
    pincodeData : state.PincodeReducer.data
})

export default connect(mapStateToProps, { addAddress,fetchingFromPincode })(AddAddressScreen);
