import {  PAYMENT_GATEWAY } from "../actions/types";

const initialState = {
    isLoading: true,
    payment:{}
};

function PaymentGatewayReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PAYMENT_GATEWAY:
            return {
                ...state,
                payment: payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default PaymentGatewayReducer;