import qs from 'querystring';
import api from "../utils/api";
import { ACCOUNT_CREATION_LOADING, LOGIN,ACCOUNT_CREATION_ERROR } from "./types";

export const verifyAccount = (firstName, lastName, phone, email, password, type, imageurl, otp) => async dispatch => {
    const body = {
        method: 'verifyotp',
        name: firstName + lastName,
        phone: phone,
        deviceid: null,
        otp: otp,
        email: email,
        password: password,
        type: type,
        imageurl: imageurl
    };
    try {
        dispatch({
            type: ACCOUNT_CREATION_LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body),);
        if (response.data.status){
            localStorage.setItem("__cust__id", response.data.loginvariables[0].custid);
            localStorage.setItem("__cust__code", response.data.loginvariables[0].custcode);
            localStorage.setItem("__cust__phone", response.data.loginvariables[0].custphone);
            localStorage.setItem("__cust__is__account__active", response.data.loginvariables[0].isaccountactive);
            localStorage.setItem("__cust__email", response.data.loginvariables[0].custemail);
            dispatch({
                type: LOGIN,
                payload: response.data
            });
        }
        else
            dispatch({
                type: ACCOUNT_CREATION_ERROR,
                payload: response.data,
            });
    }
    catch (err) {
        dispatch({ type: ACCOUNT_CREATION_ERROR, payload: { status: false, message: err, }, });
    }
}
