import React from 'react';

import { FirstLetterCapitalize } from '../../utils/common';

const NotificationComponent=({notification,index})=>{
    return(
        <div class="card mb-3" key={index}>
            <div class="row no-gutters">
                <div class="col-md-4">
                <img src={notification.image} class="card-img" alt={notification.title}/>
                </div>
                <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title">{FirstLetterCapitalize(notification.title)}</h5>
                    <p class="card-text">{FirstLetterCapitalize(notification.msg)}</p>
                    <p class="card-text"><small class="text-muted">{notification.date}</small></p>
                </div>
                </div>
            </div>
        </div>
    );
}
export default NotificationComponent;