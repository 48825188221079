import React from "react";
import { Route,Switch } from "react-router-dom";

import LoginScreen from "../Components/Authentication/LoginScreen";
import VerifyOtpScreen  from "../Components/Authentication/VerifyOtpScreen";
import ForgotPasswordScreen  from "../Components/Authentication/ForgotPasswordScreen";
import HomeScreen from "../Components/Dashboard/DashboardScreen";
import CartScreen from "../Components/Cart/CartScreen";
import FavouriteScreen from "../Components/Favourite/FavouriteScreen";
import RegisterScreen from "../Components/Authentication/RegisterScreen";
import ProductDetailScreen from "../Components/ProductDetails/ProductDetailScreen";
import Wishlist from '../Components/Wishlist/wishlist';
import contactUs from '../Components/Others/contactUs';
import faq from '../Components/Others/faq';

import SingleProduct from '../Components/ProductDetails/default';
import ChangePasswordScreen from '../Components/Settings/ChangePasswordScreen';

import AddressScreen from '../Components/Address/AddressScreen';
import EditAddressScreen from '../Components/Address/Edit/EditAddressScreen';

import MyProfile from "../Components/MyProfile/MyProfileScreen";
import MyOrders from "../Components/MyOrders/MyOrdersScreen";
import OrderDetailScreen from '../Components/OrderDetail/OrderDetailScreen';
import NotificationScreen from '../Components/Notification/NotificationScreen';
// others

import BuyAddressScreen from '../Components/Address/Buy/BuyAddressScreen';
import CheckoutScreen from "../Components/Checkout/Checkout";
import ErrorPage  from '../Components/Others/404';
import PrivacyPolicyScreen from '../Components/Others/PrivacyPolicyScreen';
import TermAndConditionScreen from '../Components/Others/TermAndConditionScreen';

import PricingScreen from '../Components/Others/PricingScreen';
import CancellationPolicyScreen from '../Components/Others/CancellationPolicyScreen';


import OrderPlacedDetail from '../Components/OrderPlaced/OrderPlacedDetail';
import OrderFailedDetail from '../Components/OrderPlaced/OrderFailedDetail';

//store screens
import StoreScreen from '../Components/Store/storeScreen';

const Routes=props=>{
    return (
        <section className="tw-bg-gray-100">
            <Switch>
                <Route exact path="/login" component={LoginScreen}/>
                <Route exact path="/register" component={RegisterScreen} />
                <Route exact path="/verifyotp" component={VerifyOtpScreen} />
                <Route exact path="/forgot-password" component={ForgotPasswordScreen} />
                <Route exact path="/" component={HomeScreen} />
                <Route exact path="/cart" component={CartScreen}/>
                <Route exact path="/wishlist" component={Wishlist}/>
                <Route exact path="/favourite" component={FavouriteScreen} />
                <Route exact path="/product-detail" component={ProductDetailScreen}/>
                <Route exact path="/product-detail/:id" component={SingleProduct}/>

                <Route exact path="/address" component={AddressScreen} />
                <Route exact path="/address/edit/:addressid" component={EditAddressScreen} />
                
                <Route exact path="/changepassword" component={ChangePasswordScreen} />
                <Route exact path="/my-profile" component={MyProfile} />
                <Route exact path="/my-orders" component={MyOrders} />
                <Route exact path="/my-order-detail" component={OrderDetailScreen}/>
                <Route exact path="/contact-us" component={contactUs} />
                <Route exact path="/faq" component={faq} />
                <Route exact path="/store/:storeId/:store" component={StoreScreen}/>

                <Route exact path="/buy-address/:addresstype" component={BuyAddressScreen} />
                <Route exact path="/checkout" component={CheckoutScreen} />

                <Route exact path="/notification" component={NotificationScreen}/>
                <Route exact path='/pages/404' component={ErrorPage} />

                <Route exact path="/privacy-policy" component={PrivacyPolicyScreen} />
                <Route exact path="/term-and-condition" component={TermAndConditionScreen} />
                <Route exact path="/refund-cancel" component={CancellationPolicyScreen} />
                <Route exact path="/pricing" component={PrivacyPolicyScreen} />

                <Route path="/order/success" component={OrderPlacedDetail} />
                <Route path="/order/failure" component={OrderFailedDetail} />
            </Switch>
        </section>
    );
}

export default Routes;