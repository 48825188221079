import qs from 'querystring';
import api from "../utils/api";
import {
    FETCH_CATEGORY_SUBCATEGORY_HEADER,
    FETCH_CATEGORY_SUBCATEGORY_HEADER_LOADING,
    FETCH_CATEGORY_SUBCATEGORY_HEADER_FAILED
} from "./types";

import {
    fetchingCart
} from './CartAction';
import {
    fetchingNotification
} from './NotificationAction';
import {
    guestLogin
} from './authentications';

import {
    FetchGuestUserIdFromLocalStorage
} from '../utils/common';

export const fetchingHomePageCategoryAndSubcategory = () => async dispatch => {
    const body = {
        method: 'categoryweb',
        // custid:"G895201",
    };
    try {
        dispatch({
            type: FETCH_CATEGORY_SUBCATEGORY_HEADER_LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body), );
        dispatch({
            type: FETCH_CATEGORY_SUBCATEGORY_HEADER,
            payload: response.data
        });
        dispatch(fetchingCart());
        dispatch(fetchingNotification());
        if (!FetchGuestUserIdFromLocalStorage())
            dispatch(guestLogin());
    } catch (err) {
        dispatch({
            type: FETCH_CATEGORY_SUBCATEGORY_HEADER_FAILED,
            payload: {
                status: false,
                message: 'Something went wrong.',
            },
        }, );
    }
}