import qs from 'querystring';
import api from "../utils/api";
import { notification } from 'antd';
import {
    ADDRESS_LOADING,
    FETCH_ADDRESS,
    ADDRESS_FAILED,

    // DELETE_ADDRESS_LOADING,
    DELETE_ADDRESS,
    DELETE_ADDRESS_FAILED,

    // ADD_ADDRESS_LOADING,
    ADD_ADDRESS,
    ADD_ADDRESS_FAILED

} from "./types";
import { FetchUserIdFromLocalStorage,FetchGuestUserIdFromLocalStorage } from '../utils/common';

export const addAddress = (
    { addressType, firstName, lastName, address1, city, cityId, pincode, country, countryId, state, stateId }
    ) => async dispatch => {
    const body = {
        method: 'addaddress',
        custid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        "AddressType": addressType,
        "CustFirstName": firstName,
        "CustLastName": lastName,
        "CustAddress1": address1,
        "CustAddress2": "",// address2,
        "CustCity": city,
        "CustCityId": cityId,
        "CustZipCode": pincode,
        "Country": country,
        "CountryId": countryId,
        "State": state,
        "StateId": stateId
    }
    try {
        
        notification.open({
            message: 'Address Saving',
            key:'adding',
            placement:'bottomRight',
            description:
              'Your Request is in Progress.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
       const response = await api.post("services.aspx", qs.stringify(body));
        if (response.data.status) {
            dispatch({
                type: ADDRESS_LOADING,
            });
            dispatch(fetchingAddress());
        }
        debugger;
        dispatch({
            type: ADD_ADDRESS,
            payload: response.data
        });
        if(response.data.status) notification.success({
            message: 'Address Saved',
            key:'adding',
            placement:'bottomRight',
            description:
                response.data.message,
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        else notification.warn({
            message: 'Error',
            key:'adding',
            placement:'bottomRight',
            description:
              response.data.message,
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
    } catch (err) {
        dispatch({
            type: ADD_ADDRESS_FAILED,
            payload: {
                status: false,
                message: err,
            }
        });
    }
}

export const fetchingAddress = () => async dispatch => {
    const body = {
        method: 'useraddresslist',
        // custid:"G895201",
        custid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage()
    };
    try {
        const response = await api.post('services.aspx', qs.stringify(body),);
        dispatch({
            type: FETCH_ADDRESS,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({ type: ADDRESS_FAILED, payload: { status: false, message: err, }, },);
    }
}

export const deleteAddressAction = (addresId) => async dispatch => {
    const body = {
        method: 'deleteaddress',
        addressid: addresId
    };
    try {
        // dispatch({
        //     type: DELETE_ADDRESS_LOADING,
        // });
        const response = await api.post('services.aspx', qs.stringify(body),);
        dispatch({
            type: DELETE_ADDRESS,
            payload: response.data
        });
        if (response.data.status) {
            dispatch(fetchingAddress());
        }
    }
    catch (err) {
        dispatch({
            type: DELETE_ADDRESS_FAILED,
            payload: {
                status: false,
                message: err,
            },
        });
    }
}