import {
    ADDRESS_LOADING,
    FETCH_ADDRESS,
    ADDRESS_FAILED,

    DELETE_ADDRESS,
    DELETE_ADDRESS_LOADING,
    DELETE_ADDRESS_FAILED,

    ADD_ADDRESS,
    ADD_ADDRESS_LOADING,
    ADD_ADDRESS_FAILED
} from "../actions/types";

import {
    persistReducer
} from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    isLoading: true,
};

function AddressReducer(state = initialState, action) {
    const {
        type,
        payload
    } = action;
    switch (type) {
        case ADDRESS_LOADING:
            return {
                ...state,
            };
        case FETCH_ADDRESS:
            return {
                ...state,
                data: {
                        ...payload
                    },
                isLoading: false,
            };
        case ADDRESS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case DELETE_ADDRESS_LOADING:
            return {
                isDeleteLoading: true
            };
        case DELETE_ADDRESS:
            return {
                isDeleteLoading: false,
                isDeleted: {
                    ...payload
                }
            };
        case DELETE_ADDRESS_FAILED:
            return {
                isDeleteLoading: false,
                isDeleted: {
                    ...payload
                }
            };
        case ADD_ADDRESS: {
            return {
                ...state,
                addAddress: {
                    ...payload
                },
                isLoading:false,
            };
        }
        default:
            return state;
    }
}


// const persistConfig = {
//     keyPrefix: "gw-",
//     key: "addresses",
//     storage
// }
export default AddressReducer;
// persistReducer(persistConfig, AddressReducer);