import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {Helmet} from 'react-helmet';
// import PropTypes from 'prop-types';
import { fetchingFavourite } from '../../actions/FavouriteActions';
import FavouriteItem from './FavouriteItemComponent';

const FavouriteScreen = ({ fetchingFavourite, isAuthenticated, isLoading, data }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        otp: ''
    });
    useEffect(() => {
        fetchingFavourite();
    }, []);

    const { email, password, otp } = formData;

    const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    return (
        <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Gracewell - Favourite</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
        <div className="col-sm-offset-3 col-sm-4">
            <h1 className="large text-primary">Favourites { data && data.Status && data.FavVariables.length > 0 ? <span className="text text-success"> ({data.FavVariables.length}) </span> :""}</h1>
            {isLoading ?
                <i className="fa fa-spin fa-spinner"></i>
                :
                data && data.Status ?
                    (
                        <>
                            <section>
                                <FavouriteItem cartItem={data.FavVariables} />
                            </section>
                        </>
                    )
                    : <p className="alert alert-info"> {data.Message} </p>
            }
        </div>
        </>
    );
};

// LoginScreen.propTypes = {
//   login: PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    isLoading: state.favouriteReducer.isLoading,
    data: state.favouriteReducer.data
});

export default connect(mapStateToProps, { fetchingFavourite })(FavouriteScreen);