import { MY_PROFILE,MY_PROFILE_LOADING,MY_PROFILE_FAILED } from "../actions/types";

const initialState={
    isLoading:true,
    data:null,
};

function myProfileReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case MY_PROFILE_LOADING:
            return {
                ...state,
                isLoading:true,
            };
        case MY_PROFILE:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
            };
        case MY_PROFILE_FAILED:
            return {
                ...state,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default myProfileReducer;