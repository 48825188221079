import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { isIEBrowser } from '../../utils/index';
import { fetchRating } from '../../actions/RatingAction';

function ViewRatingScreen(props) {
    const { isLoading,productId,reviews, fetchRating} = props;

    useEffect(()=>{
        fetchRating(productId);
    },[fetchRating,productId]);

    console.log("reviews", reviews);
    return (
        <>
        {isLoading ? 
        <i className="fa fa-spin fa-spinner"></i>
        :
            <div className="reviews p-3">
                {reviews && reviews.status ? reviews?.productcomments?.length>0 ? reviews.productcomments.map((item,i)=>
                    <div className="review" key={i}>
                        <div className="row no-gutters" style={ isIEBrowser() ? { flexDirection: 'row' } : {} }>
                            <div className="col-auto">
                                {/* <h4><Link to="#">{item.customername}</Link></h4> */}

                                <div className="ratings-container">
                                    <div className="ratings">
                                        <div className="ratings-val" style={{ width: `${item.rating * 20}%`}}></div>
                                    </div>
                                </div>
                                <span className="review-date">{item.commentsdate}</span>
                            </div>
                            <div className="col">
                                <h4 className="tw-capitalize">{item.customername}</h4>

                                <div className="review-content">
                                    <p>{item.comments}</p>
                                </div>

                                {/* <div className="review-action">
                                    <Link to="#"><i className="icon-thumbs-up"></i>Helpful (2)</Link>
                                    <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>              
                ):<div className="alert alert-danger"><i className="fa fa-info-circle"></i> No Reviews found yet.</div>:
                <div className="alert alert-danger"><i className="fa fa-info-circle"></i> Something went wrong.</div>}
            </div>
        }     
        </>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.RatingReducer.isRatingFetching,
    reviews: state.RatingReducer.ratings
});

export default connect(mapStateToProps, { fetchRating })(ViewRatingScreen);