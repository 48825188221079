import { FAQ_LOADING,FAQ,FAQ_FAILED } from "../actions/types";

const initialState={
    isLoading:true,
    faq:[]
};

function faqReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case FAQ_LOADING:
            return {
                ...state,
            };
        case FAQ:
            return {
                ...state,
                faq:payload,
                isLoading:false,
            };
        case FAQ_FAILED:
            return {
                ...state,
                faq:payload,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default faqReducer;