import {
    CANCEL_REMARKS
} from "../actions/types";

const initialState={
    isLoading: true,
    remarks: null,
};

function cancelRemarksReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case CANCEL_REMARKS:
            return {
                ...state,
                remarks:payload,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default cancelRemarksReducer;