import { FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD, FORGOT_PASSWORD_FAILED } from "../actions/types";

const initialState={
    isLoading:false,
};

function ForgotReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case FORGOT_PASSWORD_LOADING:
            return {
                isLoading:true
            };
        case FORGOT_PASSWORD : 
            return {
                ...state,
                isLoading : false,
                data : payload,
            };
        case FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                token:null,
                isAuthenticated : false,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default ForgotReducer;