import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import Custom Components
import MainMenu from './partials/main-menu';
import CartMenu from './partials/cart-menu';
import NotificationMenu from './partials/notification-menu';

import LoginModal from '../features/modal/login-modal';

import { showModal } from '../../actions/QuickViewModelsActions';

import { tryLogout } from '../../actions/authentications';

function Header(props) {
    const {totalWishlist, isAuthenticated, tryLogout, container, } = props;

    function openLoginModal(e) {
        props.showModal('login');
        e.preventDefault();
    }
    const _logout=()=>{
        console.log("logout");
        tryLogout();
    }
    return (
        <header className="header header-6">
            <div className="header-top p-2">
                <div className={container}>
                    <div className="header-left">
                        <ul className="top-menu top-link-menu d-none d-md-block">
                            <li>
                                <Link to="#">Links</Link>
                                <ul>
                                    <li><a target="_blank" href="http://gracewell.co.in/blog/" rel="noreferrer">Gracewell Blog</a></li>
                                    {/* <li>|</li>
                                    <li>Our Store</li> */}
                                    <li>|</li>
                                    <li><Link to="my-order-detail">Track/Return Order</Link></li>
                                    <li>|</li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li>|</li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="header-right">
                        <div className="social-icons social-icons-color">
                            <a href="https://www.facebook.com" className="social-icon social-facebook" rel="noopener noreferrer" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                            <a href="https://twitter.com" className="social-icon social-twitter" rel="noopener noreferrer" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                            <a href="https://instagram.com" className="social-icon social-instagram" rel="noopener noreferrer" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                            <a href="https://youtube.com" className="social-icon social-youtube" rel="noopener noreferrer" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
                            <a href="https://pinterest.com" className="social-icon social-pinterest" rel="noopener noreferrer" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
                        </div>
                        {!isAuthenticated ?
                            <ul className="top-menu top-link-menu">
                                <li>
                                    <Link to="#">Links</Link>
                                    <ul>
                                        <li>
                                            <Link to="#signin-modal" data-toggle="modal" onClick={openLoginModal}><i className="icon-user"></i>Login/Register</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul> :
                            <div className="header-dropdown">
                                <Link to="#">User Settings</Link>
                                <div className="header-menu">
                                    <ul>
                                        <li className="border-bottom m-2 "><Link to="/my-profile"> <i className="fa fa-user p-2"></i> My Profile</Link></li>
                                        <li className="border-bottom m-2 "><Link to="/my-orders"> <i className="fa fa-briefcase p-2"></i> My Orders</Link></li>
                                        <li className="border-bottom">
                                            <dl>
                                                <dt className="p-2"><i className="fa fa-cog"></i> Settings</dt>
                                                <dd><Link to="/address"><i className="fa fa-home p-2"></i> Address</Link></dd>
                                                <dd><Link to="/changepassword"><i className="fa fa-lock p-2"></i>Change Password</Link></dd>
                                            </dl>
                                        </li>
                                        <li><Link to="/" onClick={_logout}><i className="fa fa-lock"></i> Logout</Link></li>
                                    </ul>
                                </div>
                            </div>
                        }
                        {/* <div className="header-dropdown">
                            <Link to="#">USD</Link>
                            <div className="header-menu">
                                <ul>
                                    <li><Link to="#">Eur</Link></li>
                                    <li><Link to="#">Usd</Link></li>
                                </ul>
                            </div>
                        </div> */}

                        {/* <div className="header-dropdown">
                            <Link to="#">Eng</Link>
                            <div className="header-menu">
                                <ul>
                                    <li><Link to="#">English</Link></li>
                                    <li><Link to="#">French</Link></li>
                                    <li><Link to="#">Spanish</Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="tw-hidden md:tw-block">
                <div className={container}>
                    <div className="header-left">
                        <Link to={`${process.env.PUBLIC_URL}/`} className="logo" style={{ left:110 }}>
                            <img 
                                style={{height:70}} 
                                src="https://dashboard.gracewell.co.in/assets/media/logos/logo-letter-1.png" 
                                className="img img-responsive"
                                alt="Gracewell Logo"
                            />
                        </Link>
                    </div>
                    <div className="header-right">
                        <div className="header-search header-search-extended header-search-visible d-none d-lg-block">
                            <Link to="#" className="search-toggle" role="button"><i className="icon-search"></i></Link>
                            <form action="#" method="get">
                                <div className="header-search-wrapper search-wrapper-wide">
                                    <label htmlFor="q" className="sr-only">Search</label>
                                    <input type="search"  className="form-control" name="q" id="q" placeholder="Search product ..." required />
                                    <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>

            <div className="header-bottom sticky-header" >
                <div className={container}>
                    <div className="header-left">
                        <MainMenu />
                        <button className="mobile-menu-toggler">
                            <span className="sr-only">Toggle mobile menu</span>
                            <i className="icon-bars"></i>
                        </button>
                    </div>

                    <div className="header-right">
                        <Link to={`${process.env.PUBLIC_URL}/wishlist`} className="wishlist-link">
                            <i className="fa fa-heart tw-text-white"></i>
                            <span className="wishlist-count tw-text-white">{totalWishlist || 0}</span>
                        </Link>
                        {/* <Link to={`${process.env.PUBLIC_URL}/notification`} className="wishlist-link">
                            <i className="fa fa-bell"></i>
                        </Link> */}
                        <NotificationMenu/>
                        <CartMenu />
                    </div>
                </div>
            </div>

            <LoginModal />
        </header>
    )
}

function mapStateToProps(state) {
    return {
        wishlist: state.wishlist.list,
        totalWishlist : state.favouriteReducer.totalProducts,
        isAuthenticated: state.authReducer.isAuthenticated
    }
}

export default (connect)(mapStateToProps, { showModal, tryLogout })(Header);