import React from 'react';

function PageHeader( props ) {
    const { title, subTitle, bannerImage } = props;
    return (
        <div className="page-header text-center tw-h-96" style={ 
            { backgroundImage: bannerImage ? `url(${bannerImage})` :  `url(${process.env.PUBLIC_URL}/assets/images/page-header-bg.jpg)` } } >
            <div className="container">
                <h1 className="page-title" style={{visibility:'hidden'}}>{ title }<span>{ subTitle }</span></h1>
            </div>
        </div>
    );
}

export default React.memo( PageHeader );