import { 
    CONTACT_US, 
    CONTACT_US_FAILED
 } from "../actions/types";

const initialState={
    isLoading:true,
    data : null
};

function SupportReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case CONTACT_US:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
            };
        case CONTACT_US_FAILED:
            return {
                ...state,
                isLoading:false,
                data:{...payload}
            };
        default:
            return state;
    }
}

export default SupportReducer;