export const FirstLetterCapitalize = (value) => value?.charAt(0).toUpperCase() + value?.slice(1);
export const FetchUserIdFromLocalStorage = () => localStorage.__cust__id;
export const FetchGuestUserIdFromLocalStorage = () => localStorage.__guest__cust__id;
export const FetchBillingAddressIdFromLocalStorage = () => localStorage.billing_address;
export const FetchShippingAddressIdFromLocalStorage = () => localStorage.shipping_address;
export const FetchTotalAmountAddressIdFromLocalStorage = () => localStorage.total_amount;
export const FetchOrderIdFromLocalStorage = ()=> localStorage.__order__id;
export const CheckPasswordComplexity = (pwd) => {
  var regularExpression = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  return regularExpression.test(pwd);
}

export function loveTheFour(length) {
  var lastDigitDivisibleByFour;
  if (length > 3) {
    for (var i = 0; i <= length; i++) {
      if (i % 4 === 0) {
        lastDigitDivisibleByFour = i;
      }
    }
    return lastDigitDivisibleByFour - 1;
  }
  return length;
}