import qs from 'querystring';
import api from "../utils/api";

import 'react-toastify/dist/ReactToastify.css';
import { FetchUserIdFromLocalStorage, FetchGuestUserIdFromLocalStorage } from '../utils/common';
import { FAVOURITE,ADD_FAVOURITE, FAILED, } from "./types";

export const fetchingFavourite = ()=> async dispatch => {
    const body ={
        method:'myfavourite',
        custid:FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
    };
    try{
        const response = await api.post('services.aspx',qs.stringify(body),);
        console.log("my fav response", response);
        dispatch({
            type:FAVOURITE,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:FAILED,
            payload:{status:false,message:'Something went wrong.',}
        },);
    }
}

export const addFavourite = (productid)=> async dispatch => {
    const body ={
        method:'addfavourite',
        custid:FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        productid:productid
    };
    try{
        const response = await api.post('services.aspx',qs.stringify(body),);
        console.log("response", response);
        if(response.data.status){
         dispatch({
             type:ADD_FAVOURITE,
             payload : response.data
         });
        dispatch(fetchingFavourite());
        }
    }
    catch(err){
        dispatch({
            type:FAILED,
            payload:{status:false,message:err,}
        },);
    }
}