import { FAVOURITE,FAILED,ADD_FAVOURITE } from "../actions/types";

import  { getCartProductCount }  from '../services';

const initialState={
    token : localStorage.getItem('token'),
    isLoading:true,
};

function favouriteReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case FAVOURITE:
            return {
                ...state,
                data:payload,
                totalProducts : payload.favvariables ? getCartProductCount(payload.favvariables) : 0,
                isAuthenticated:true,
                isLoading:false,
            };
        case ADD_FAVOURITE : 
            return {
                ...state,
                isLoading : false,
                data : payload,
            };
        case FAILED:
            return {
                ...state,
                token:null,
                isAuthenticated : false,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default favouriteReducer;