import axios from 'axios';
import store from '../store';
import {LOGOUT} from '../actions/types';

const api = axios.create({
    // baseURL:'http://gracewell.aksomtechnology.com/', https://zipbhati.payesmoney.in/
    // withCredentials:false,
    baseURL:'https://api.prod.gracewell.co.in/',
    headers :{
        'Content-Type':'application/x-www-form-urlencoded'
    },
    // responseType:'json'
});

api.interceptors.response.use(
    res=>res,
    err=>{
        if(err.response.status === 401){
            store.dispatch({type:LOGOUT});
        }
        return Promise.reject(err);
    }
);

export default api;