import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// import Custom Components
import PageHeader from '../common/page-header';
import Breadcrumb from '../common/breadcrumb';
import Accordion from '../features/accordion/accordion';
import Card from '../features/accordion/card';

import { isIEBrowser } from '../../utils';
import { connect } from 'react-redux';

import  {fetchingFaq} from "../../actions/FaqActions";
import { FirstLetterCapitalize } from  "../../utils/common";

function PrivacyPolicyScreen({fetchingFaq,isLoading, faq}) {

    useEffect(()=>{
        fetchingFaq();
    },[fetchingFaq]);
    return (
        <div className="main">
            <Helmet>
                <title>Gracewell - Privacy Policy</title>
            </Helmet>

            <h1 className="d-none">Gracewell - Privacy Policy</h1>

            <PageHeader title="Privacy Policy" subTitle="Privacy Policy" />
            <Breadcrumb title="Privacy Policy"  />

            <div className="page-content">
                <div className="container">
                    {isLoading ? 
                        <center><i className="fa fa-spin fa-spinner"></i></center>:
                    faq && faq.status?
                    faq.faqs.map((item,index)=>{
                        return <Accordion adClass="accordion-rounded" key={index}>
                            <Card title={ FirstLetterCapitalize(item.quest)} adClass="card-box card-sm bg-light">
                                { FirstLetterCapitalize(item.ans)}
                            </Card>
                        </Accordion>
                    })
                    :<p>{faq.message}</p>
                    }
                </div>
            </div>

            <div className="cta cta-display bg-image pt-4 pb-4" style={ { backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/cta/bg-7.jpg)` } }>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9 col-xl-7">
                            <div className={ `row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center` } >
                                <div className="col">
                                    <h3 className="cta-title text-white">If You Have More Questions</h3>
                                </div>
                                <div className="col-auto">
                                    <Link to={ `${process.env.PUBLIC_URL}/contact-us` } className="btn btn-outline-white"><span>CONTACT US</span><i className="icon-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const mapStateToProps = (state) => ({
    isLoading: state.FaqReducer.isLoading,
    faq: state.FaqReducer.faq
})
export default connect(mapStateToProps,{fetchingFaq})(  React.memo( PrivacyPolicyScreen ));