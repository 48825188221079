import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
// import { Magnifier, SideBySideMagnifier } from "react-image-magnifiers";
import ReactImageMagnify from 'react-image-magnify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import { quantityInputs } from '../../../../utils/index';

function MediaOne(props) {
    const { product, adClass = "product-gallery-vertical" } = props;
    // if ( !product ) {
    //     window.location = process.env.PUBLIC_URL + "pages/404";
    // }

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setStatus] = useState(false);
    const {onImageLoad} = props;
    const bigImages = product;

    useEffect(()=>{
        setPhotoIndex(product.length > photoIndex ? photoIndex : 0);
    });

    function openLightBox() {
        let index = parseInt(document.querySelector(".product-main-image").getAttribute("index"));

        if (!index) {
            index = 0;
        }
        //     setStatus( true );
        setPhotoIndex(index);
    }

    function closeLightBox() {
        setStatus(false);
    }

    const setNextHandler = () => {
        setPhotoIndex(photoIndex => (photoIndex + 1) % bigImages.length);
    }

    const setPrevHandler = () => {
        setPhotoIndex(photoIndex => (photoIndex + bigImages.length - 1) % bigImages.length);
    }

    return (
        <>

                <Grid container xs={12} spacing={1}>
                    <Grid item xs={12}>
                        {/* { product.new ? <span className="product-label label-new">New</span> : '' }

                        { product.top ? <span className="product-label label-top">Top</span> : '' }

                        { product.discount ? <span className="product-label label-sale">{ product.discount }% off</span> : '' }

                        { ( 0 === product.stock ) ? <span className="product-label label-out">Out of Stock</span> : '' } */}
                        < ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Ted Baker London',
                                    isFluidWidth: true,
                                    src: product[photoIndex ||0]?.productphoto,
                                    onLoad:img=>onImageLoad(img)
                                },
                                largeImage: {
                                    src: product[ photoIndex|| 0]?.productphoto,
                                    width: 1200,
                                    height: 1800,
                                   // onLoad:img=>onImageLoad(img)
                                },
                                isHintEnabled: true,
                            }}
                        />
                    </Grid>

                    <Grid item container direction="row">
                        {
                            product?.map((item, index) => 
                                     <button 
                                        onClick={()=>setPhotoIndex(index)} 
                                        className={`product-gallery-item ${ photoIndex === index ? 'active' : ''}`} 
                                        to="#" 
                                        data-image={item.productphoto} 
                                        data-zoom-image={bigImages[index].productphoto} 
                                        key={index}>
                                            <img 
                                                src={product[index].productphoto} alt={product[index].productphoto} />
                                    </button>
                            )
                        }
                    </Grid>
                </Grid>


            {
                isOpen ?
                    <Lightbox
                        mainSrc={bigImages[photoIndex].productphoto}
                        nextSrc={bigImages[(photoIndex + 1) % bigImages.length].productphoto}
                        prevSrc={bigImages[(photoIndex + bigImages.length - 1) % bigImages.length].productphoto}
                        onCloseRequest={closeLightBox}
                        onMovePrevRequest={setNextHandler}
                        onMoveNextRequest={setPrevHandler}
                    />
                    : ''
            }
        </>
    )
};

export default MediaOne;