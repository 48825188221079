import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Breadcrumb from '../common/breadcrumb';
import { connect } from 'react-redux';

function OrderFailedDetail({isLoading,}) {
    return (
        <div className="main">

            <h1 className="d-none">Gracewell - Order Failed</h1>

            <Breadcrumb title="Failure" />

            <div className="page-content">
                <div className="row justify-content-sm-center">
                    <div className="d-flex bg-white">
                        <div className="tw-px-24 tw-py-24 justify-content-sm-center">
                            <div className="tw-text-center">
                                <i className="fa fa-times-circle-o fa-4x tw-text-center text-danger"></i>
                            </div>
                            <h1 className="tw-font-xl tw-text-center" style={{fontSize:"30px"}}>
                                <strong>Prefer contactless delivery?.</strong>
                            </h1>
                            <p className="tw-text-center">Your order Id is 12345678900.</p>
                            <p className="tw-text-center">Your payment attempt fort his order has failed. Please try again.</p>
                            <div className="text-center">
                                <Link to="/" className="btn btn-primary">
                                CONTINUE SHOPPING</Link>    
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    )
}
export const mapStateToProps = (state) => ({
    isLoading: state.FaqReducer.isLoading,
})
export default connect(mapStateToProps,{})(  React.memo( OrderFailedDetail ));