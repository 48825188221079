import React from 'react';

const FavouriteItem = (props) => {
    console.log(props);
    return (
        props.cartItem ?
            props.cartItem.map((item) =>
                <div key={item.ProductID}>
                    <img src={item.ProductPhoto} />
                    <p>{item.ProductName}</p>
                    <p>Amount :₹{item.ProductDisPrice} <del> {item.ProductPrice}</del></p>
                </div>
            )
            : <p>No Item Available</p>
    );
}
export default FavouriteItem;