import React, { Fragment, useState } from 'react';
import { connect, } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {Helmet} from "react-helmet";
// import PropTypes from 'prop-types';
import { tryLogin } from '../../actions/authentications';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

const LoginScreen = ({ tryLogin, isLoading, data }) => {
  const [formData, setFormData] = useState({
    account: '',
    password: ''
  });

  const [mobileError, setMobileError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { account, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (!account.includes("@") && (account.length !== 10 || isNaN(account))) return setMobileError("Invalid Email or Mobile.");
    else setMobileError('');
    if (password.length < 6 || password.length > 16) return setPasswordError('Please Enter Valid Password. Password must be in between 8 to 16 digit.');
    else setPasswordError('');
    tryLogin(account,password);
  };
 console.log('data.message',data.message);
  if (data && data.status) {
    console.log("status");
    return <Redirect to="/home" />
//    return <Redirect to={`/verifyotp/${account}`} />;
  }
  const responseFacebook = (response) => {
    console.log(response);
  }
  const responseGoogle =(response)=>{
    console.log(response);
  }
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Gracewell -Login</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className="row">
      <div className="col-offset-sm-3 col-sm-4">
        <h1 className="large text-primary">Login In</h1>
        <p className="lead">
          <i className="fa fa-user" /> Login Into Your Account
      </p>
        {data ? (<p className={data.status ? "alert alert-success" : "alert alert-danger"}> {data.message} </p>) : null}
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Enter Email Address or Phone Number"
              name="account"
              value={account}
              onChange={onChange}
            />
            {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
          </div>
          {/* <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Mobile Number"
              name="phone"
              value={phone}
              maxLength="10"
              onChange={onChange}
            />
          </div> */}
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={onChange}
              minLength="6"
            />
            {passwordError ? (<p className="text text-danger">{passwordError}</p>) : null}
          </div>
          {!isLoading ? <button type="submit" className="btn btn-primary "><i className="fa fa-lock"></i> Login</button> : <i className="fa fa-spin fa-spinner"></i>}
        </form>
        <p className="my-1">
          Don't have an account? <Link to="/register">Login Up</Link>
        </p>
        <hr/>
        <FacebookLogin
          appId="1088597931155576"
          autoLoad={true}
          fields="name,email,picture"
          //onClick={componentClicked}
          callback={responseFacebook} />
        <GoogleLogin
          clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
      />
      </div>
    </div>
    </>
  );
};

// LoginScreen.propTypes = {
//   login: PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
  isAuthenticated: state.isAuthenticated,
  isLoading: state.authReducer.isLoading,
  data: state.authReducer.data
});

export default connect(mapStateToProps, { tryLogin })(LoginScreen);