import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { Link, Redirect } from 'react-router-dom';
import{Helmet} from'react-helmet';
// import PropTypes from 'prop-types';
import { fetchingHome, } from '../../actions/Dashboard';
import HomeLoadingAnime from '../anime/HomeLoadingAnime';

import CTA from './cta-section';
import Banner from './banner';
// import TrendyCollection from './trendy-collection';
// import BlogSection from './blog-section';
// import InstagramSection from './instagram-section';
// import QuickViewTwo from '../features/product/common/quickview-two';
import Service from '../features/service';
// import NewsletterModal from '../features/modal/newsletter-modal';
// import style from './style.scss';

import SliderComponent from './SliderComponent';

const HomeScreen = ({ fetchingHome, isLoading, data, }) => {
    useEffect(() => {
        // style.use();
        fetchingHome();
    }, [fetchingHome]);

return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Gracewell - Home</title>
    </Helmet>
    <h1 className="d-none">Gracewell - Fashion Store</h1>
    <div className="main">
        {isLoading ?
            <div className="p-4">
                <HomeLoadingAnime/>
            </div>
            :
            data && data.status ?
                (
                    <>
                        <div className="intro-section bg-lighter">
                            <div className="">
                                <div className="banner-group">
                                    <div className="row">
                                        {/* <div className="col-md-5">
                                            <Banner banner={data.banvariables[ 0 ]} adClass="banner-big" type="bottom" title="" height="590"/>
                                        </div>
                                        <div className="col-md-7">
                                            <Banner banner={ data.banvariables[ 1 ] } adClass="" height="265" type="right" title=""/>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Banner banner={ data.banvariables[ 2 ] } adClass="" height="265" type="top" title="" />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Banner banner={ data.banvariables[ 3 ] } adClass="" height="265" type="top banner-content-reverse" title=""/>
                                                </div>
                                            </div>
                                        </div> */}
                                        <SliderComponent sliders={data?.banvariables} />
                                    </div>
                                </div>
                            </div>
                            <Service />
                        </div>
                        {/* {data?.productisnew?.length > 0 &&<div className="container-fluid form-group tw-my-16">
                            <div className="card">
                                <h5 class="card-header tw-py-8 tw-px-12 tw-text-4xl border-bottom"><strong>NEW OFFERS</strong></h5>
                                <div class="card-body">
                                    <CarouselItem items={data.productisnew} />
                                </div>
                            </div>
                        </div>
                        } */}
                        {/* {data?.productistop?.length >0 && <div className="container-fluid form-group tw-my-16">
                            <div className="card">
                                <h5 class="card-header tw-py-8 tw-px-12 tw-text-4xl border-bottom"><strong>TOP OFFERS</strong></h5>
                                <div class="card-body">
                                    <CarouselItem items={data.productistop} />
                                </div>
                            </div>
                        </div>
                        } */}
                        {/* {data?.productoffer?.length > 0 &&<div className="container-fluid form-group tw-my-16">
                            <div className="card">
                                <h5 class="card-header tw-py-8 tw-px-12 tw-text-4xl border-bottom"><strong>OFFERS</strong></h5>
                                <div class="card-body">
                                    <CarouselItem items={data.productoffer} />
                                </div>
                            </div>
                        </div>
                        } */}
                        {/* {data.productisbest.length > 0 &&<div className="container-fluid form-group tw-my-16">
                            <div className="card">
                                <h5 class="card-header tw-py-8 tw-px-12 tw-text-4xl border-bottom"><strong>BEST OFFERS</strong></h5>
                                <div class="card-body">
                                    <CarouselItem items={data.productisbest} />
                                </div>
                            </div>
                        </div>
                        } */}
                        {data && data.homebanners.length > 0 && data.homebanners.map((banners)=>
                            <Link to={`/store/${banners.id}/${banners.category}`} >
                                <img src={banners.bannerimage} alt={banners.bannerimage} className="img mg-fluid"
                                style={{ width : "100%"}}/>
                            </Link>
                        )}
                        <CTA />
                    </>
                )
                : <p className="alert alert-danger"> {data.message} </p>
        }
        {/* <BlogSection />
        <div className="container-fluid">
            <div className="heading text-center">
                <h2 className="title">Follow us on instagram</h2>
                <p className="title-desc">Donec nec justo eget felis facilisis fermentum.</p>
            </div>
            <InstagramSection />
        </div> */}
    </div>
    {/* <QuickViewTwo /> */}

    {/* <NewsletterModal /> */}
    </>
    );
};

// LoginScreen.propTypes = {
//   login: PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    isLoading: state.homeReducer.isLoading,
    data: state.homeReducer.data
});

export default connect(mapStateToProps, { fetchingHome })(HomeScreen);