import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import qs from 'querystring';
import { Helmet } from "react-helmet";

import Breadcrumb from '../common/breadcrumb';
import { getProductDetail } from '../../actions/ProductDetailAction';
import { addFavourite } from '../../actions/FavouriteActions';
import { addToCart,removeCartItem, CartAddedShowComplete } from '../../actions/CartAction';

import ProductDetailOne from './partials/details/detail-one';
import RelatedProducts from './partials/related-products';
import SizeGuideModal from './partials/SizeGuideModal';

import QuickView from '../features/product/common/quickview';
import { showModal } from '../../actions/QuickViewModelsActions';
import { guestLogin } from '../../actions/authentications';
import LoadingDetail from './LoadingDetail';

const ProductDetailScreen = ({ getProductDetail, addFavourite, addToCart,removeCartItem, isAuthenticated, isLoading, data, location, showModal,guestLogin, CartAddedShowComplete, categoryAndSubcategory }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [sizeGuide, setSizeGuide] = useState();

    const handleShow = (sizeGuide)=>{
        setSizeGuide(sizeGuide);
        setShow(true);
    }

    var qss = qs.parse(location.search.substring(1));
    useEffect(() => {
        const fetchData = async () => {
            await getProductDetail(qss.productid);
        }
        fetchData();
    }, [getProductDetail, qss.productid]);

    const [bCategory, setBCategory] = useState("");
    
    useEffect(()=>{
        if(data){
            categoryAndSubcategory.category.filter((category)=> {
                
                if(category.id === data.productsvariables[0].categoryid){
                    category.subcategory.filter((subc)=>{
                        if(subc.id === data.productsvariables[0].subcategoryid){
                            setBCategory({
                                category: category,
                                subcategory: subc
                            });
                        }
                        return subc;
                    });
                }
                return category;
            });
        }
    },[data, categoryAndSubcategory]);

    if (!qss.productid) {
        return <Redirect to="/" />;
    }

    console.log(data);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gracewell - {`${data?.productsvariables[0]?.productname}`}</title>
            </Helmet>
            <Breadcrumb 
                title={data?.productsvariables[0]?.productname}         adClass="mb-2"
                parent1={ [ bCategory?.category?.category, `store/${bCategory?.category?.id}/${bCategory?.category?.category}` ]}
                parent2={
                [ bCategory?.subcategory?.subcategory, `store/${bCategory?.category?.id}-${bCategory?.subcategory?.id}/${bCategory?.subcategory?.subcategory}` ]
                }
            />
            <div className="col-sm-12" style={{minHeight:"450px"}}>
                <>
                    <div className="main">
                        <div className="page-content tw-mx-12">
                            <div className="container-fluid tw-bg-white pt-3">
                                <div className="product-details-top skeleton-body">
                                    {isLoading ? <LoadingDetail/>:
                                    <div className={`row skel-pro-single loaded`}>
                                        { !isLoading && data && data.status &&
                                        <ProductDetailOne
                                            product={data.productsvariables[0]}
                                            features={data.productfeatures}
                                            colors={data.productcolors}
                                            addFavourite={addFavourite}
                                            addToCart={addToCart}
                                            isAuthenticated={isAuthenticated}
                                            showModal={showModal}
                                            guestLogin={guestLogin}
                                            handleSizeGuideShow={handleShow}
                                            CartAddedShowComplete={CartAddedShowComplete}
                                            removeCartItem={removeCartItem}
                                            reviews={data?.productcomments}
                                        />}
                                    </div>
                                    }
                                </div>
                            </div>
                            {/* Similar Products */}
                            {!isLoading && data && data.status && <div className="tw-bg-white">
                                <div className="tw-max-w-full tw-mx-auto tw-py-8 tw-px-14 sm:tw-py-12 sm:tw-px-10 lg:tw-px-20">
                                    <h2 className="tw-text-4xl tw-font-extrabold tw-tracking-tight tw-text-gray-900">Customers also purchased</h2><hr/>
                                    <RelatedProducts products={data?.similarproducts} />
                                </div>
                            </div>
                            }
                            {/* End Similar Products */}
                        </div>
                        {/* <StickyBar id={ productId } /> */}
                        <QuickView />
                    </div>
                </>    
            </div>
            <SizeGuideModal show={show} handleClose={handleClose} categoryId={sizeGuide}/>

        </>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    isLoading: state.productDetailReducer.isLoading,
    data: state.productDetailReducer.data,
    cart : state.cartReducer.data,
    categoryAndSubcategory: state.CategoryAndSubcategoryReducer.data
});

export default connect(mapStateToProps, { getProductDetail, addFavourite, addToCart, removeCartItem, showModal,guestLogin, CartAddedShowComplete })(ProductDetailScreen);