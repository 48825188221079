import React, { useEffect, useState } from "react";
import { Modal, Table, } from 'react-bootstrap';
import { Switch } from "@headlessui/react";
import {connect} from "react-redux";

import { fetchingSizeChart } from "../../../actions/SizeChartAction";



const SizeGuideModal = ({ show, handleClose,categoryId,fetchingSizeChart, isLoading, size }) => {
    useEffect(()=>{
        fetchingSizeChart(categoryId);
    },[fetchingSizeChart,categoryId]);
    const [measurement, setMeasurement] = useState(false);

    const sizeTableRow = (item, index) => {
        return (<tr key={index}>
            <td className="tw-uppercase">{item.size} </td>
            <td>{item.underbust}</td>
            <td>{item.overbust}</td>
        </tr>);
    }
    return (
        <>
        <Modal size="tw-xxl" show={show} onHide={handleClose} scrollable={true}>
            <Modal.Header >
                <Modal.Title>Size Guide</Modal.Title>
                <div className="pull-right">
                <Switch.Group>
                    <Switch.Label className="mr-2">IN</Switch.Label>
                    <Switch
                        checked={measurement}
                        onChange={e => { setMeasurement(!measurement)}}
                        className={`${
                            measurement ? 'theme-color' :'tw-bg-gray-200'
                        } tw-relative tw-inline-flex tw-items-center tw-h-6 tw-rounded-full tw-w-11`}
                        >
                        <span
                            className={`${
                                measurement ? 'tw-translate-x-6' :'tw-translate-x-1'
                            } tw-inline-block tw-w-4 tw-h-4 tw-transform tw-bg-white tw-rounded-full`}
                        />
                    </Switch>
                    <Switch.Label className="ml-2">CM</Switch.Label>
                </Switch.Group>
                </div>
            </Modal.Header>
            <Modal.Body >
                {isLoading ? 
                    "Loading":
                    <div className="m-2">
                        {size && size.status ?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Size</th>
                                    <th>Underbust ({measurement ? 'cm/m' :'In Inches'})</th>
                                    <th>Overbust ({measurement ? 'cm/m' :'In Inches'})</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !measurement && size.sizevariablesininch.map((item, index)=> sizeTableRow(item,index))}
                                { measurement && size.sizevariablesincentimer.map((item, index)=> sizeTableRow(item,index))}
                            </tbody>
                        </Table>
                        :<div className="alert alert-danger">{size.message}</div>
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose} className="tw-bg-transparent hover:tw-bg-red-500 tw-text-red-700 tw-font-semibold hover:tw-text-white tw-py-2 tw-px-4 tw-border tw-border-red-500 hover:tw-border-transparent tw-rounded">
                    Close
                </button>
            </Modal.Footer>
        </Modal>
        </>
    );
};
const mapStateToProps = state => ({
    isLoading : state.SizeChartReducer.isLoading,
    size : state.SizeChartReducer.data
});
export default connect(mapStateToProps,{fetchingSizeChart})( React.memo(SizeGuideModal));