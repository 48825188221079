import React, { useState, useEffect } from "react";
import { Modal, Tab, Row, Col, } from 'react-bootstrap';
import { connect } from 'react-redux';

const CouponModal = ({ show, handleClose,coupons,applyCoupon}) => {

    return (
    <>
    <Modal 
        show={show} 
        onHide={handleClose} 
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Available Coupons </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tab.Container id="left-tabs-example" defaultActiveKey="discount" >
                <Row>
                    <Col>
                        {coupons && coupons.status && coupons.coupons.map((coupon)=>{
                            return <div className="m-4">
                                <Col sm={3} className="tw-border-2 tw-bg-gray-100 tw-border-dashed tw-py-4 tw-text-center tw-uppercase"><b>{coupon.coupon_code}</b></Col>
                                <h6 className="tw-py-2">READY2022Shop for ₹1125 more to get 22% off on your cart. Get extra 22% off on Zivame products and minimum order value of 2022</h6>
                                <button className="btn btn-outline-info" onClick={e=>{
                                    e.preventDefault();
                                    handleClose();
                                    applyCoupon(coupon.coupon_code,coupon.coupon_amount,coupon.end_date);
                                }}>APPLY</button>
                            </div>
                        })}
                    </Col>
                </Row>
            </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const mapStateToProps = (state) => {
    return {
        coupons: state.CouponReducer.coupons
    }
}
export default connect(mapStateToProps, {})(CouponModal);;