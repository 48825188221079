import { MY_ORDERS,MY_ORDERS_LOADING,MY_ORDERS_FAILED } from "../actions/types";

const initialState={
    isLoading:true,
    data:null,
};

function myOrdersReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case MY_ORDERS_LOADING:
            return {
                ...state,
                isLoading:true,
            };
        case MY_ORDERS:
            return {
                data:{...payload},
                isLoading:false,
            };
        case MY_ORDERS_FAILED:
            return {
                ...state,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default myOrdersReducer;