import React, {
  Fragment,
  useState,
  useEffect,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import {
  Provider
} from 'react-redux';
import {
  PersistGate
} from 'redux-persist/integration/react';
import './styles/output.css'
import Routes from "./Routes/Routes";
import store, {
  persistor
} from './store';
import {
  ToastContainer
} from 'react-toastify';
// import Utils
import {
  preventProductDefault,
  removePreventProductDefault,
  stickyHeaderHandler
} from './utils/index';

// import logo from './logo.svg';
import './App.css';

import {
  LOGOUT,
  TOKEN_EXISTENCE
} from './actions/types';
// import Custom Components
import Header from './Components/common/header';
import Footer from './Components/common/footer';
import MobileMenu from './Components/common/mobile-menu';

import setAuthToken from './utils/setAuthToken';

const App = (props) => {

  const [container, setContainer] = useState("container-fluid");
  const [prevPath, setPrevPath] = useState('');

  // useLayoutEffect( () => {
  //     overlayFlag = true;
  //     // set overlay
  //     for ( let i = 0; i < innerOverlayPaths.length; i++ ) {
  //         if ( prevPath.indexOf( innerOverlayPaths[ i ] ) > 0 && props.location.pathname.indexOf( innerOverlayPaths[ i ] ) > 0 ) {
  //             overlayFlag = false;
  //         }

  //         if ( prevPath === props.location.pathname ) {
  //             overlayFlag = false;
  //         }
  //     }

  //     if ( overlayFlag ) {
  //         document.querySelector( 'body' ).classList.remove( "loaded" );
  //         document.querySelector( "#root" ).classList.remove( "loaded" );
  //     }
  // } )

  useEffect(() => {
    // mobileMenu();

    // set sticky header
    stickyHeaderHandler();
    window.addEventListener('scroll', stickyHeaderHandler, true);

    // prevent product thumb icons
    preventProductDefault();

    return () => {
      window.removeEventListener('scroll', stickyHeaderHandler);

      // remove listeners of prevent product
      removePreventProductDefault();
    }
  }, [])

  useEffect(() => {
    // set prevPathname
    // setPrevPath( props.location.pathname );

    // remove overlay
    // setTimeout(() => {
    //   document.querySelector('body').classList.add("loaded");
    //   document.querySelector("#root").classList.add("loaded");
    // }, 200);

    // show 404 page
    // while ( matchedCount < props.children.length && !matchPath( window.location.pathname, { path: props.children[ matchedCount ].props.path, exact: true } ) ) {
    //     matchedCount++;
    // }

    // if ( props.children && !props.children.length && matchPath( window.location.pathname, { path: props.children.props.path, exact: true } ) ) {
    //     matchedCount = 1;
    // }

    // if ( matchedCount >= props.children.length || ( props.children && !props.children.length && matchedCount === 0 ) ) {
    //     window.location = process.env.PUBLIC_URL + "/pages/404";
    // }

    // close exiting quickview modal
    // if ( store.getState() && store.getState().data.quickView ) {
    //     store.dispatch( closeQuickViewModal() );
    // }

    // set the container type according to page grid type
    // if ( props.location.pathname.indexOf( "fullwidth" ) !== -1 ) {
    //     setContainer( "container-fluid" );
    // } else {
    //     setContainer( "container" );
    // }
  })

  useEffect(() => {
    // check for token in LS
    if (localStorage.__cust__id) {
      setAuthToken(localStorage.__cust__id);
      // console.log('__cust__id', localStorage.__cust__id);
      store.dispatch({
        type: TOKEN_EXISTENCE
      });
    }

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.__cust__id) store.dispatch({
        type: LOGOUT
      });
    });
  }, []);


  return ( 
  <Provider store = { store } > 
    {
        /* <PersistGate persistor={ persistor }> */
      } 
      <Router>
      <>
        <div className = "page-wrapper" >
        <Header 
          container = { container}
          urls = { prevPath }
        /> 
          {
            props.children
          } 
        <Switch >
          <Route component = {Routes} /> 
        </Switch > 
      <Footer container = { container }
    /> 
    <ToastContainer 
    autoClose = { 3000}
    className = "toast-container"
    position = "bottom-right"
    hideProgressBar = {
      true
    }
    newestOnTop = {
      false
    }
    closeOnClick rtl = {
      false
    }
    pauseOnFocusLoss draggable pauseOnHover / >
        </div> 
        <MobileMenu />
      </> 
    </Router > 
    {
      /* </PersistGate> */
    } 
  </Provider>);
};

export default App;