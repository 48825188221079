// import api from './api';

const setAuthToken=token=>{
    if(token){
        // api.defaults.headers.common['x-auth-token']=token;
        localStorage.setItem('__cust__id',token);
    }else{
        // delete api.defaults.headers.common['x-auth-token'];
        localStorage.removeItem('__cust__id');
        localStorage.removeItem("__cust__code");
        localStorage.removeItem("__cust__phone");
        localStorage.removeItem("__cust__is__account__active");
        localStorage.removeItem("__cust__email");
    }
}

export default setAuthToken;