import qs from 'querystring';
import api from "../utils/api";
import { NOTIFICATION_LOADING,NOTIFICATION,NOTIFICATION_FAILED } from "./types";

export const fetchingNotification = ()=> async dispatch => {
    const body ={
        method:'notification',
        custid:''
    };
    try{
        dispatch({
            type:NOTIFICATION_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:NOTIFICATION,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:NOTIFICATION_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}