import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link,} from 'react-router-dom';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
// import PropTypes from 'prop-types';
import { fetchingCart, removeCartItem, addRemoveCartProductQuantity } from '../../actions/CartAction';
import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../common/breadcrumb';
import { changeQty, changeShipping } from '../../actions/QuickViewModelsActions';
import CartItem from './CartItemComponent';
import LottieAnimation from '../anime/LottieAnimation';
import CouponComponent from './CouponComponent';
import animationData from "../../animations/empty-cart.json";

function CartScreen(props) {
    const { total, removeCartItem, prevShip, fetchingCart, addRemoveCartProductQuantity, isLoading, data,history,isCartUpdating, } = props;

    // const [shipping, setShipping] = useState(prevShip);
    //const shippingPrice = { "free": 0, "standard": 10, "express": 20 };
    //const [totalAmount, setTotalAmount]=useState(total);

    useEffect(() => {
        fetchingCart();
    }, [fetchingCart]);


    function onChangeShipping(val) {
      //  setTotalAmount((total + shippingPrice[val]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
    }

    function goToCheckout() {
        localStorage.setItem("total_amount",total);
        return history.push("/buy-address/1");
    }

    return (
        <>
            <Helmet>
                <title>Gracewell | Shopping Cart</title>
            </Helmet>

            <h1 className="d-none">Gracewell - Shopping Cart</h1>
            <div className="main">
                {/* <PageHeader title="Shopping Cart" subTitle="Shop" /> */}
                <Breadcrumb title="Cart"/>
                <div className="page-content">
                    <div className="cart">
                        <div className="tw-px-10">
                            <div className="row">
                                {!isLoading ? data &&
                                    data.status && data.cartvariables?.length > 0 ?
                                    <>
                                        <div className="col-lg-9">
                                            <Grid container spacing={1} xs={12}>
                                                {
                                                        data.cartvariables.map((item, index) =>
                                                            <CartItem
                                                                item={item}
                                                                index={index}
                                                                fetchingCart={fetchingCart}
                                                                removeCartItem={removeCartItem}
                                                                addRemoveCartProductQuantity={addRemoveCartProductQuantity}
                                                            />
                                                        )
                                                    }
                                            </Grid>
                                        </div>
                                        <aside className="col-lg-3">
                                            <div className="summary summary-cart">
                                                <h3 className="summary-title">Cart Total</h3>
                                                <table className="table table-summary">
                                                    <tbody>
                                                        <tr className="summary-subtotal">
                                                            <td>Subtotal:</td>
                                                            <td>₹ {Math.abs(total)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                        </tr>
                                                        <tr className="summary-shipping">
                                                            <td colspan="2">
                                                                <CouponComponent total={total} />
                                                            </td>
                                                        </tr>
                                                        <tr className="summary-shipping">
                                                            <td>Shipping:</td>
                                                            <td>&nbsp;</td>
                                                        </tr>

                                                        <tr className="summary-shipping-row">
                                                            <td>
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio"
                                                                        id="free-shipping"
                                                                        name="shipping"
                                                                        className="custom-control-input"
                                                                        onChange={(e) => onChangeShipping("free")}
                                                                        defaultChecked={"free" === prevShip ? true : false}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="free-shipping">Free Shipping</label>
                                                                </div>
                                                            </td>
                                                            <td>₹ 0.00</td>
                                                        </tr>

                                                        {/* <tr className="summary-shipping-row">
                                                            <td>
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio"
                                                                        id="standard-shipping"
                                                                        name="shipping"
                                                                        className="custom-control-input"
                                                                        onChange={(e) => onChangeShipping("standard")}
                                                                        defaultChecked={"standard" === prevShip ? true : false}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="standard-shipping">Standard:</label>
                                                                </div>
                                                            </td>
                                                            <td>₹ 10.00</td>
                                                        </tr> */}

                                                        {/* <tr className="summary-shipping-row">
                                                            <td>
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio"
                                                                        id="express-shipping"
                                                                        name="shipping"
                                                                        className="custom-control-input"
                                                                        onChange={(e) => onChangeShipping("express")}
                                                                        defaultChecked={"express" === prevShip ? true : false}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="express-shipping">Express:</label>
                                                                </div>
                                                            </td>
                                                            <td>₹ 20.00</td>
                                                        </tr> */}

                                                        {/* <tr className="summary-shipping-estimate">
                                                            <td>Estimate for Your Country<br /> <a href={`${process.env.PUBLIC_URL}/shop/dashboard`}>Change address</a></td>
                                                            <td>&nbsp;</td>
                                                        </tr> */}

                                                        <tr className="summary-total">
                                                            <td>Total:</td>
                                                            <td>
                                                                ₹ {Math.abs(total)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <button
                                                    className="btn btn-outline-primary-2 btn-order btn-block"
                                                    onClick={goToCheckout}
                                                >
                                                    PROCEED TO CHECKOUT
                                                </button>
                                            </div>

                                            <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-outline-dark-2 btn-block mb-3"><span>CONTINUE SHOPPING</span><i className="icon-refresh"></i></Link>
                                        </aside>
                                    </> :
                                    <>
                                    <Grid container xs={12} justifyContent="center" alignItems="center">
                                        <Grid item container xs={4} justifyContent="center">
                                            <LottieAnimation animationData={animationData} />
                                            <Typography variant="h2" component="h3"><strong>Your Cart is Empty.</strong></Typography>
                                            <Typography variant="h5" component="h5">Looks like you haven't added anything to your cart yet.</Typography>
                                            <Grid item xs={5}>
                                                <Button
                                                    component={Link}
                                                    variant="outlined"
                                                    size="large"
                                                    to="/"
                                                    sx={{
                                                    margin:1
                                                    }}
                                                >
                                                    {" "}
                                                    Continue Shopping
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </>
                                    :
                                    <center><i className="fa fa-spin fa-spinner"></i></center>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isCartUpdating ?
            <div className="tw-w-full tw-h-full tw-fixed tw-block tw-top-0 tw-left-0 tw-bg-white tw-opacity-75 tw-z-50">
                <span className="tw-text-green-500 tw-opacity-75 tw-top-1/2 tw-my-0 tw-mx-auto tw-block tw-relative tw-w-0 tw-h-0" style={{top:'50%'}}>
                    <div className="tw-flex tw-justify-center tw-items-center">
                        <i className="fa fa-spin fa-spinner fa-3x"></i>
                    </div>
                </span>
            </div>:''}
        </>
    )
}

export const mapStateToProps = (state) => ({
    cartlist: state.cartlist.cart,
    total: state.cartReducer.total,
    prevShip: state.cartlist.shipping,
    isLoading: state.cartReducer.isLoading,
    data: state.cartReducer.data,
    isCartUpdating : state.cartReducer.isCartUpdating,

})

export default connect(mapStateToProps, { changeQty, removeCartItem, changeShipping, fetchingCart, addRemoveCartProductQuantity, })(CartScreen);
