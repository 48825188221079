import qs from 'querystring';
import api from "../utils/api";
import { HOME_LOADING, HOME, FAILED, } from "./types";

import { FetchUserIdFromLocalStorage, FetchGuestUserIdFromLocalStorage } from '../utils/common';

export const fetchingHome = ()=> async dispatch => {
    const body ={
        method:'dashboarddata_v1',
        custid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
    };
    try{
        dispatch({
            type:HOME_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:HOME,
            payload : response.data
        });
        
    }
    catch(err){
        dispatch({type:FAILED,payload:{status:false,message:'Something went wrong.',},});
    }
}