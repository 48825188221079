import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../common/page-header';
import Breadcrumb from '../common/breadcrumb';

import { connect } from 'react-redux';

import { fetchingNotification } from  '../../actions/NotificationAction';
import NotificationComponent from  './NotificationComponent';

const NotificationScreen=({fetchingNotification,isLoading,notification})=>{
    useEffect(()=>{
        fetchingNotification();
    },[fetchingNotification]);
    return (
        <>
                <div className="main">
            <Helmet>
                <title>Gracewell - FAQ Page</title>
            </Helmet>

            <h1 className="d-none">Gracewell - FAQ Page</h1>

            <PageHeader title="Notifications" subTitle="Notification" />
            <Breadcrumb title="Notifications" parent1={ [ "Shop","/notification" ] } />

            <div className="page-content">
                <div className="container">
                    {isLoading ? 
                        <center><i className="fa fa-spin fa-spinner"></i></center>:
                    notification && notification.status?
                    notification.notification.map((item,index)=>{
                        return <NotificationComponent notification={item} index={index}/>;
                    })
                    :<p>{notification.message}</p>
                    }

                </div>
            </div>
        </div>
        </>
    );
}
export const mapStateToProps = (state) => ({
    isLoading: state.NotificationReducer.isLoading,
    notification: state.NotificationReducer.notification
})
export default connect(mapStateToProps,{fetchingNotification})( NotificationScreen);