import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from 'querystring';

import Breadcrumb from '../common/breadcrumb';
import Card from '../features/accordion/card';

import { connect } from 'react-redux';

import { fetchingOrderDetail } from '../../actions/MyOrdersAction';

import { FirstLetterCapitalize } from "../../utils/common";

import './step.css';

const OrderDetailScreen = ({ fetchingOrderDetail, isLoading, orderDetail, location }) => {

    const [orderid, setOrderId] = useState(qs.parse(location.search.substring(1)).orderid);

    useEffect(() => {
        if (orderid)
            fetchingOrderDetail(orderid);
    }, [fetchingOrderDetail]);

    var orderTotalValue = 0;

    const fetchStatus = (status)=>{
        switch (status) {
            case "ready to dispatch":
                return '60%';
            case "dispacted":
                return '80%';
            case "delivered":
                return '100%';
            default:
                return '20%';
        }
    }
    const orderStatus = orderDetail?.statusvariables?.[orderDetail.statusvariables.length -1 ]?.orderstatus;
    return (
        <>
            <div className="main" style={{minHeight:"500px"}}>
                <Helmet>
                    <title>Gracewell - Order Detail</title>
                </Helmet>

                <h1 className="d-none">Gracewell - Order Detail</h1>

                {/* <PageHeader title="Order Detail" subTitle="Order Details" /> */}
                <Breadcrumb title="Order Detail" parent1={["Orders", "my-orders"]} />

                <div className="page-content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-4">
                                <label>Enter Your Order Id</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Order Id Here"
                                    name="orderid"
                                    value={orderid}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setOrderId(e.target.value);
                                    }}
                                />
                                <button className='btn btn-primary' type="button" onClick={(e)=>{e.preventDefault(); fetchingOrderDetail(orderid);}}>
                                    Track Order
                                </button>
                                <br/><br/>
                                {
                                    isLoading ?
                                        <center><i className="fa fa-spin fa-spinner"></i></center> :
                                        orderDetail && !orderDetail.status &&
                                            <div className="alert alert-danger">
                                                {FirstLetterCapitalize(orderDetail && orderDetail.message) || "Please Enter your order Id for getting details."}
                                            </div>
                                }
                            </div>
                        </div>
                        <br/>
                        <div className="row form-group">
                            <div className="col-12">
                                { orderDetail && orderDetail.status && <>
                                    <Card title={`Order Status (${ FirstLetterCapitalize(orderStatus)})`} expanded={true} adClass="card-box card-sm bg-light">
                                        {orderStatus !== "cancelled"&&
                                        <div class="steps">
                                            <div class="steps-header">
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" style={{width: fetchStatus(orderStatus)}} 
                                                    aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div class="steps-body">
                                                <div class=
                                                {`step ${orderStatus === "order placed"? "step-active":""} `}><span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></span>Order confirmed</div>
                                                <div class={`step ${orderStatus === "order placed"? "step-active":""} `}><span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></span>Processing order</div>
                                                <div class={`step ${orderStatus === "ready to dispatch"? "step-active":""} `}><span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg></span>Quality check</div>
                                                <div class={`step ${orderStatus === "dispatched"? "step-active":""} `}><span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg></span>Product dispatched</div>
                                                <div class={`step ${orderStatus === "delivered"? "step-active":""} `}><span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg></span>Product delivered</div>
                                            </div>
                                        </div>
                                        }
                                        <br/>
                                        <table className="table table-cart table-mobile">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th className="pl-2">Product</th>
                                                    <th></th>
                                                    <th>Total ( Price * Quantity )</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderDetail.orderproductsvariables.length > 0 ? orderDetail.orderproductsvariables.map((item, index) => {
                                                    orderTotalValue += parseInt(item.producttotalprice);
                                                    return (
                                                        <tr>
                                                            <td>
                                                            <figure className="p-1">
                                                                <Link to={ `${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}` }>
                                                                    <img src={item.productphoto } alt="Product" width="120" className="tw-rounded" />
                                                                </Link>
                                                            </figure>
                                                            </td>
                                                            <td className="tw-capitalize">
                                                                {FirstLetterCapitalize(item.productname)}
                                                                <br/>
                                                                <div>Color :  <span className="tw-mt-1 tw-text-sm tw-text-gray-500 tw-h-8 tw-w-8" style={{backgroundColor:item.productcolor,display:'inline-block',paddingTop:'5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                                                                <br/>
                                                                <span>Size : {item.productsize}</span>
                                                            </td>
                                                            <td>₹{item.productprice} * {item.productquantity} = ₹{item.producttotalprice}</td>
                                                        </tr>
                                                    )
                                                }) : 'No Action Taken On Your Order Yet.'}
                                                {orderDetail.orderproductsvariables.length > 0 ? <tr>
                                                    <td>Total</td>
                                                    <td></td>
                                                    <td>₹{orderTotalValue}</td>
                                                </tr>

                                                    : ''}
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="col-6">
                                                    <h1>Billing Address</h1>
                                                <div className="card card-dashboard">
                                                    <div className="card-body">
                                                        <h3 class="card-title"><i className={`fa fa-${orderDetail?.addressvaliables[0]?.custaddresstype === "office" ? "briefcase" : "home"}`}></i> {FirstLetterCapitalize(orderDetail?.addressvaliables[0]?.custaddresstype)} Address</h3>
                                                        <hr />
                                                        <p>{orderDetail?.addressvaliables[0]?.custfirstname} {orderDetail?.addressvaliables[0]?.custlastname}</p>
                                                        <p>
                                                            {orderDetail?.addressvaliables[0]?.custaddress1}
                                                            <br />
                                                            {orderDetail?.addressvaliables[0]?.custaddress2 || ''}
                                                            <br />
                                                            {orderDetail?.addressvaliables[0]?.custcity}({orderDetail?.addressvaliables[0]?.custpincode})-{orderDetail?.addressvaliables[0]?.custstate}({orderDetail?.addressvaliables[0]?.custcountryname})
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <h1>Shipping Address</h1>
                                                <div className="card card-dashboard">
                                                    <div className="card-body">
                                                        <h3 class="card-title"><i className={`fa fa-${orderDetail?.addressvaliables[1]?.custaddresstype === "office" ? "briefcase" : "home"}`}></i> {FirstLetterCapitalize(orderDetail?.addressvaliables[1]?.custaddresstype)} Address</h3>
                                                        <hr />
                                                        <p>{orderDetail?.addressvaliables[1]?.custfirstname} {orderDetail?.addressvaliables[1]?.custlastname}</p>
                                                        <p>
                                                            {orderDetail?.addressvaliables[1]?.custaddress1}
                                                            <br />
                                                            {orderDetail?.addressvaliables[1]?.custaddress2 || ''}
                                                            <br />
                                                            {orderDetail?.addressvaliables[1]?.custcity}({orderDetail?.addressvaliables[1]?.custpincode})-{orderDetail?.addressvaliables[1]?.custstate}({orderDetail?.addressvaliables[1]?.custcountryname})
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export const mapStateToProps = (state) => ({
    isLoading: state.MyOrderDetailReducer.isLoading,
    orderDetail: state.MyOrderDetailReducer.data
})
export default connect(mapStateToProps, { fetchingOrderDetail })(OrderDetailScreen);