import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import qs from 'querystring';

import Breadcrumb from '../common/breadcrumb';
import { connect } from 'react-redux';

function OrderPlacedDetail({isLoading,location}) {
    var qss = qs.parse(location.search.substring(1));


    return (
        <div className="main">
            <Helmet>
                <title>Gracewell - Order Success</title>
            </Helmet>

            <h1 className="d-none">Gracewell - Order Success</h1>

            <Breadcrumb title="Success" />

            <div className="page-content">
                <div className="row justify-content-sm-center">
                    <div className="d-flex bg-white">
                        <div className="tw-px-24 tw-py-24 justify-content-sm-center">
                            <div className="tw-text-center">
                                <i className="fa fa-check-circle-o fa-4x tw-text-center text-success"></i>
                            </div>
                            <h1 className="tw-font-xl tw-text-center" style={{fontSize:"30px"}}>
                                <strong>Your order has been received.</strong>
                            </h1>
                            <p className="tw-text-center">Your order Id is {qss?.orderId}.</p>
                            <p className="tw-text-center">You'll receive an order confirmation email with detial of your order.</p>
                            <div className="text-center">
                                <Link to={`/my-order-detail?orderid=${qss?.orderId}`} className="btn btn-primary">
                                CHECK STATUS</Link>    
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    )
}
export const mapStateToProps = (state) => ({
    isLoading: state.FaqReducer.isLoading,
})
export default connect(mapStateToProps,{})(  React.memo( OrderPlacedDetail ));