import qs from 'querystring';
import api from "../utils/api";
import { FETCH_PINCODE,FETCH_PINCODE_LOADING,FETCH_PINCODE_FAILED } from "./types";

export const fetchingFromPincode = (pincode)=> async dispatch => {
    const body ={
        method:'pincode',
        pincode:pincode
    };
    try{
        dispatch({
            type:FETCH_PINCODE_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:FETCH_PINCODE,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:FETCH_PINCODE_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}