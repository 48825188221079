import { NOTIFICATION_LOADING,NOTIFICATION,NOTIFICATION_FAILED } from "../actions/types";

const initialState={
    isLoading:true,
    notification:[]
};

function notificationReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case NOTIFICATION_LOADING:
            return {
                ...state,
            };
        case NOTIFICATION:
            return {
                ...state,
                notification:payload,
                isLoading:false,
            };
        case NOTIFICATION_FAILED:
            return {
                ...state,
                notification:payload,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default notificationReducer;