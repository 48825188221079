import qs from 'querystring';
import api from "../utils/api";
import { SIZE_CHART_LOADING, SIZE_CHART, SIZE_CHART_FAILED } from "./types";

export const fetchingSizeChart = ( categoryId )=> async dispatch => {
    
    const body ={
        method:'sizechart',
        cateid: categoryId,
    };

    try{
        dispatch({
            type:SIZE_CHART_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:SIZE_CHART,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:SIZE_CHART_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}