import qs from 'querystring';
import api from "../utils/api";
import { PRODUCT_DETAIL,PRODUCT_DETAIL_FAILED } from "./types";

export const getProductDetail = (productId,) => async dispatch => {
    const body = {
        method: 'productdetailsnew_v1',
        productid: productId,
    };
    try {
        const response = await api.post('services.aspx', qs.stringify(body),);
        dispatch({
            type: PRODUCT_DETAIL,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({ type: PRODUCT_DETAIL_FAILED, payload: { status: false, message: err.response, }, });
    }
}