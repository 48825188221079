import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FirstLetterCapitalize } from '../../../utils/common';

function NotificationMenu( { notification } ) {

    return (
        <div className="dropdown cart-dropdown">
            <Link to={ `${process.env.PUBLIC_URL}/notification` } className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                {/* <i className="icon-shopping-cart"></i> */}
                <i className="fa fa-bell tw-text-white"></i>
                {/* <span className="cart-count">{cart && cart.status && cart.cartvariables ? getCartProductCount(cart.cartvariables) : <span>0</span>}</span> */}
                {/* <span className="cart-txt">&#8377; { total.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span> */}
            </Link>
            <div className={ `dropdown-menu dropdown-menu-right ${ notification.length === 0 ? 'text-center' : ''}` } >
                {
                    notification == null || 0 === notification.length || notification.status === false?
                        <p>No Notifications Available.</p> :
                        <>
                            <div className="dropdown-cart-products">
                                { notification.notification.map( ( item, index ) => (
                                    <div className="product" key={ index }>
                                        <div className="product-cart-details">
                                            <h4 className="product-title">
                                                {  FirstLetterCapitalize(item.title)}
                                            </h4>
                                            <span className="cart-product-info">
                                            {FirstLetterCapitalize(item.msg)}
                                            </span>
                                        </div>
                                        <figure className="product-image-container">
                                            <span className="product-image">
                                                <img src={ item.image} data-oi={ item.image } alt={item.image} />
                                            </span>
                                        </figure>
                                    </div>
                                ) ) }
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

function mapStateToProps( state ) {
    return {
        notification: state.NotificationReducer.notification
    }
}

export default connect( mapStateToProps, {} )( NotificationMenu );