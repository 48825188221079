import qs from 'querystring';
import api from "../utils/api";
import { FETCH_FILTERS, FETCH_FILTERS_FAILED } from "./types";

export const fetchingFilters = ()=> async dispatch => {
    const body ={
        method:'filter',
    };
    try{
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:FETCH_FILTERS,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:FETCH_FILTERS_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}