import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// import PropTypes from 'prop-types';
import { tryCheckout, } from '../../actions/CheckoutAction';
import { finalCheckout } from '../../actions/finalCheckoutAction';
import { fetchingPaymentGateway } from '../../actions/PaymentGatewayAction';
import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../common/breadcrumb';
import Accordion from '../features/accordion/accordion';
import Card from '../features/accordion/card';

import { FirstLetterCapitalize } from '../../utils/common';
import NotFoundAnime from '../anime/NotFoundAnime';
import { Divider } from 'antd';

function CheckoutScreen(props) {
    const { tryCheckout, finalCheckout, isLoading, isFinalCheckoutLoading, data, finalCheckoutData, paymentGateway, fetchingPaymentGateway } = props;

    useEffect(() => {
        tryCheckout();
    }, [tryCheckout]);

    const [option, setOption] = useState('');
    const onOptionChanged = (e) => {
        setOption(e.target.value);
    }
    const onProceed = (e) => {
        e.preventDefault();
        if (option === "cod") {
            finalCheckout(data.orderid, 'cash', '', 'unpaid', 'cash', 0);
        } else {
            fetchingPaymentGateway(data.orderid);
        }
    }

    if (paymentGateway?.status) {
        return window.location.replace(paymentGateway.message);
    }

    if(finalCheckoutData && finalCheckoutData.status){
        return window.location.assign(`/order/success?orderId=${data.orderid}`);
    }

    return (
        <>
            <Helmet>
                <title>Gracewell | Checkout</title>
            </Helmet>
            <h1 className="d-none">Gracewell - Checkout</h1>
            <div className="main">
                {/* <PageHeader title="Checkout" subTitle="Shop" /> */}
                <Breadcrumb title="Checkout" />
                <div className="">
                    <div className="cart">
                        <div className="container">
                            {
                                finalCheckoutData && !finalCheckoutData.status && (
                                    <div className="alert alert-danger">{finalCheckoutData.message}
                                    </div>
                                )
                            }
                            <div className="row">
                                {!isLoading ? data &&
                                    data.status && data?.orderid ?
                                    <>
                                        <Accordion adClass="accordion-rounded col-12" >
                                            <Card title="Payment Options" expanded={true} adClass="card-box card-sm">
                                                <ul>
                                                    <li class="form-check pt-2">
                                                        <input class="form-check-input" type="radio" name="option" id="exampleRadios1" value="online"
                                                            onChange={(e) => onOptionChanged(e)} />
                                                        <label class="form-check-label ml-2" for="exampleRadios1">
                                                            <h6><b>Pay via Credit/ Debit/ Internet Banking/ Paytm Wallet.</b></h6>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <Divider/>
                                                    </li>
                                                    <li>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="option" id="exampleRadios2"
                                                                value="cod" onChange={(e) => onOptionChanged(e)} />
                                                            <label class="form-check-label ml-2" for="exampleRadios2">
                                                                <h6><b>Pay via COD (Cash on Delivery)</b></h6>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                {
                                                    option ?
                                                        <div class="form-group pb-2">
                                                            <Divider/>
                                                            <button
                                                                onClick={onProceed}
                                                                className="btn btn-danger pull-right">
                                                                <i className="fa fa-times"></i> Cancel
                                                            </button>
                                                            <button
                                                                onClick={onProceed}
                                                                className="btn btn-primary pull-right">
                                                                {isFinalCheckoutLoading ?
                                                                    <>
                                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                        <span class="sr-only">Loading...</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <i className="fa fa-check"></i> Continue
                                                                    </>
                                                                }
                                                            </button>
                                                        </div>
                                                        : ""
                                                }
                                            </Card>
                                        </Accordion>
                                    </> :
                                    <div className="row justify-content-sm-center">
                                        <div className="form-group">
                                            <>
                                                <NotFoundAnime />
                                                <div className="alert alert-danger">
                                                    {FirstLetterCapitalize(data?.message) || "Something went wrong."}
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                    :
                                    <center><i className="fa fa-spin fa-spinner"></i></center>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.CheckoutReducer.isLoading,
    data: state.CheckoutReducer.data,
    isFinalCheckoutLoading: state.FinalCheckoutReducer.isFinalCheckoutLoading,
    finalCheckoutData: state.FinalCheckoutReducer.finalCheckoutData,
    paymentGateway: state.PaymentGatewayReducer.payment
});

export default connect(mapStateToProps, { tryCheckout, finalCheckout, fetchingPaymentGateway })(CheckoutScreen);