import { 
    CHECKOUT,
    CHECKOUT_FAILED
 } from "../actions/types";

const initialState={
    isLoading:true,
};

function CheckoutReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case CHECKOUT:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
            };
        case CHECKOUT_FAILED:
            return {
                ...state,
                isLoading:false,
                data:{...payload}
            };
        default:
            return state;
    }
}

export default CheckoutReducer;