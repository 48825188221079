import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormControl, Select, MenuItem } from "@mui/material";

import CustomizedDialogs from  "../common/CommonModel";
import { FirstLetterCapitalize } from '../../utils/common';
import { canceOrder } from "../../actions/MyOrdersAction";

function CancelOrderModel(props) {
    const { canceOrder, orderId, isLoading, remarks, setIsShown,isShown} = props;

    const initialState = {
        statusid:remarks?.remarkslist[0]?.id,
        remarks:'',
    };

    const [formData, setFormData] = useState({
        ...initialState,
        statusid:remarks?.remarkslist[0]?.id,
    });

    const onChange = ({
        target:{
            name,value
        }
    }) =>{
        const updates = {
            ...formData,
            errors:{
                ...formData.errors,
                [name]: false,
            },
            [name]: value
        };

        setFormData(updates);
    }
    const handleSave = e =>{
        e.preventDefault();
        const errors = {};
        if(formData.statusid === '10' && !formData.remarks) errors.remarks = true;

        if(Object.keys(errors).length > 0){
            setFormData({
                ...formData,
                errors,
            });
        }else{
            canceOrder({
                ...formData,
                remarks: formData.statusid === 10 ? formData.remarks : remarks.remarkslist.filter((rem) => rem.id === formData.statusid)[0].remark,
                orderid: orderId
            });
            setIsShown(false);
        }
    }
    
    return (
        <CustomizedDialogs 
            open={isShown} 
            headerText="Cancel Order ?" 
            handleClose={(e)=>{
                e.preventDefault();
                setIsShown(!isShown);
            }}
            handleSave={handleSave}
            isLoading={isLoading}
            buttonText="Cancel"
            maxWidth="xs"
        >
            <form onSubmit={handleSave} >
            <div className="row">
                    <div className="col">
                        <label htmlFor="statusid">Option *</label>
                        <br/>
                        <FormControl sx={{ margin:1}}>
                        <Select
                            name="statusid"
                            value={formData.statusid}
                            onChange={onChange}
                            displayEmpty
                            sx={{ border: "1px solid", fontSize: 14,}}
                        >
                            {remarks?.remarkslist?.map((remark) => <MenuItem value={remark.id}>{  FirstLetterCapitalize(remark.remark) }</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="remark">Remark *</label>
                        <input
                            type="text"
                            className={`form-control ${formData?.error?.remarks ? "is-invalid" : ""}`}
                            name="remarks"

                            onChange={onChange}
                            required />
                        {formData?.errors?.remarks ? (<p className="text text-danger">Required</p>) : null}
                    </div>
                </div>
            </form>
        </CustomizedDialogs>
    )
}

export const mapStateToProps = (state) => ({
    isPincodeLoading : state.CancelRemarksReducer.isLoading,
    remarks : state.CancelRemarksReducer.remarks
})

export default connect(mapStateToProps, {canceOrder})(CancelOrderModel);
