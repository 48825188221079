import { 
    ADD_RATING, 
    ADD_RATING_FAILED, 
    ADD_RATING_LOADING,
    FETCH_RATING
 } from "../actions/types";

const initialState={
    isLoading:false,
    data : null,
    isRatingFetching: true,
    ratings: null,
};

function RatingReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case ADD_RATING_LOADING:
            return {
                ...state,
                isLoading:true,
            };
        case ADD_RATING:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
            };
        case ADD_RATING_FAILED:
            return {
                ...state,
                isLoading:false,
                data:{...payload}
            };
        case FETCH_RATING:
            return {
                ...state,
                isRatingFetching: false,
                ratings:{...payload}
            };
        default:
            return state;
    }
}

export default RatingReducer;