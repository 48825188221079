import React from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Rating } from "@mui/material";

import { FirstLetterCapitalize } from '../../utils/common';

function ProductDetail(props) {
    const { product, isWishlist, showQuickView, onAddToCompare, onAddToWishlist } = props;

    const wishlistHandler = (productId) => {
        onAddToWishlist(productId);
    }

    const quickViewHandler = () => {
        showQuickView(product.ProductID);
    }

    const addToCompareHandler = () => {
        onAddToCompare(product);
    }

    return (
        product ?
            <div className="product w-100">
                <figure className="product-media" style={props.style}>
                    {/* { product.stock === 0 ? <span className="product-label label-out">Out</span> : '' }
                    { product.discount ? <span className="product-label label-sale">Sale</span> : '' } */}
                    {product.productdiscount ? <span className="product-label label-sale">{product.productdiscount}</span> : ''}
                    {/* { 0 === product.stock ? <span className="product-label label-out">Out Of Stock</span> : '' } */}
                    {/* { 0 < product.discount && product.until ?
                        <div className="product-countdown" id={ product.id }><Countdown date={ `2021-02-01T01:02:03` } renderer={ rendererOne } /></div>
                        : ''
                    } */}
                    <Link to={`${process.env.PUBLIC_URL}/product-detail?productid=${product.productid}`}>
                        <LazyLoadImage
                            alt="product"
                            visibleByDefault={true}
                            src={product.productphoto}
                            threshold={100}
                            style={{ height:"100%" }}
                        />

                        {/* { product.pictures[ 1 ] ?
                            <LazyLoadImage
                                alt="product"
                                visibleByDefault={ true }
                                src={ `${process.env.PUBLIC_URL}/${product.pictures[ 1 ]}` }
                                threshold={ 100 }
                                wrapperClassName="product-image-hover product-image"
                            />
                            : ''
                        } */}
                    </Link>

                    <div className="product-action-vertical">
                        {/* <button className="btn-product-icon btn-quickview" title="Quick view" onClick={quickViewHandler}>
                            <span>Quick view</span>
                        </button>

                        <button className="btn-product-icon btn-compare" title="Compare" onClick={addToCompareHandler}>
                            <span>Compare</span>
                        </button> */}
                    </div>
                    <div className="product-action">
                        <Link className="btn-product" title="view detail" to={`/product-detail?productid=${product.productid}`}>
                            <span>View Detail</span>
                        </Link>
                    </div>
                </figure>

                <div className="product-body product-action-inner">
                    <button
                        className={`btn-product-icon btn-wishlist btn-expandable ${isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'}`}
                        onClick={(e) => wishlistHandler(product.productid)}
                        title={isWishlist ? "Go to wishlist" : "Add to wishlist"}
                    >
                        <span>{isWishlist ? "go to wishlist" : "add to wishlist"}</span>
                    </button>
                    <h3 className="product-title" style={{ minHeight: "39px !important"}}>
                        <Link className="tw-text-xl tw-capitalize theme-text-color" to={`${process.env.PUBLIC_URL}/product-detail?productid=${product.productid}`} >{FirstLetterCapitalize(product.productname.length > 50 ? `${product.productname.substr(0, 50)}...` : product.productname)}</Link>
                    </h3>
                    {
                        0 === product.stock ?
                            <div className="product-price">
                                <span className="out-price">${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </div> :
                            0 < product.discount ?
                                <div className="product-price">
                                    <span className="new-price">Now ₹{product.productdisprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    <span className="old-price">Was ₹{product.productprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                </div> :
                                <div className="product-price">
                                    <span className="new-price tw-text-2xl">
                                        ₹{product.productdisprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>
                                    <del className="tw-text-xl">₹{product.productprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </del>
                                </div>
                    }

                    {
                        product.variants ?
                            product.variants[0].model ?
                                <div className="product-nav product-nav-thumbs">
                                    {product.variants.map((vari, i) =>
                                        <Link to="#" key={`vari_${i}`} className={0 === i ? 'active' : ''}>
                                            <img src={process.env.PUBLIC_URL + '/' + vari.model} alt="product desc" />
                                        </Link>
                                    )}
                                </div>
                                :
                                <div className="product-nav product-nav-dots">
                                    {product.variants.map((vari, i) =>
                                        <Link to="#" key={`vari_${i}`} className={0 === i ? 'active' : ''} style={{ background: vari.color }}>
                                        </Link>
                                    )}
                                </div>
                            : ''
                    }
                    <div class="ratings-container">
                        <Rating name="read-only" value={product.productrating} readOnly />
                        {/* <span class="ratings-text">( {product.productratingcount} Reviews )</span> */}
                    </div>
                </div>
            </div> : ''
    );
}

function mapStateToProps(state, ownprops) {
    
}

export default connect(mapStateToProps)(ProductDetail);