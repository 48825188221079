import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// import Custom Components
import PageHeader from "../common/page-header";
import Breadcrumb from "../common/breadcrumb";

import { fetchingProfile } from "../../actions/MyProfileAction";
import Link from "react-router-dom/Link";

import ProfileAnime from '../anime/MyProfileAnime';

function ChangeProfileScreen(props) {
  const { isLoading,profile,fetchingProfile } = props;

  // const [FullName, setFullName] = useState("");
  // const [EmailAddress, setEmailAddress] = useState("");
  // const [MobileNumber, setMobileNumber] = useState("");

  // const [FullNameError, setFullNameError] = useState("");
  // const [EmailAddressError, setEmailAddressError] = useState("");
  // const [MobileNumberError, setMobileNumberError] = useState("");

  // const changeProfileRequest = (e) => {
  //   e.preventDefault();
  // };

  useEffect(()=>{
    fetchingProfile();
  },[fetchingProfile]);
console.log('profile',profile);
  return (
    <>
      <Helmet>
        <title>Gracewell | My Profile</title>
      </Helmet>
      <h1 className="d-none">Gracewell - My Profile</h1>
      <div className="main">
        {/* <PageHeader title="My Profile" subTitle="Edit Profile here" /> */}
        <Breadcrumb
          title="My Profile"
          parent1={["Shop", "shop/sidebar/list"]}
        />
        <div className="page-content">
          <div className="container">
            <div className="row justify-content-sm-center">
              {/* <div className="col-4">
                <div className="form-group">
                  <label>
                    Full Name <span className="text text-danger">*</span>
                  </label>
                  <input
                    type="Profile"
                    minLength="3"
                    className={`form-control ${
                      FullNameError ? "is-invalid" : ""
                    }`}
                    name={FullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <span className="text text-danger">
                    {FullNameError || ""}
                  </span>
                </div>
                <div className="form-group">
                  <label>
                    Email Address <span className="text text-danger">*</span>
                  </label>
                  <input
                    type="Profile"
                    className={`form-control ${
                      EmailAddressError ? "is-invalid" : ""
                    }`}
                    name={EmailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                  <span className="text text-danger">
                    {EmailAddressError || ""}
                  </span>
                </div>
                <div className="form-group">
                  <label>
                    Mobile Number <span className="text text-danger">*</span>
                  </label>
                  <input
                    type="Profile"
                    minLength="8"
                    maxLength="16"
                    className={`form-control ${
                      MobileNumberError ? "is-invalid" : ""
                    }`}
                    name={MobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />

                  <span className="text text-danger">
                    {MobileNumberError || ""}
                  </span>
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => changeProfileRequest(e)}
                  >
                    <i className="fa fa-lock"></i> EDIT PROFILE
                  </button>
                </div>
              </div>
               */}
                 <div className="col-4">
                   {!isLoading ? profile && profile.status && profile.loginvariables.length > 0?
                    <>
                    <div className="form-group">
                    <label>Name</label>
                    <label className="form-control">{profile.loginvariables[0].custname}</label>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <label className="form-control">{profile.loginvariables[0].custemail}</label>
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <label className="form-control">{profile.loginvariables[0].custphone}</label>
                  </div>
                  <div className="form-group">
                    <label>Password <Link to="/changepassword">Change Password</Link></label>
                    <label className="form-control">********</label>
                  </div>
                  </>                   
                    : <div className="justify-content-sm-center">{profile?.message}</div> 
                    : <div className="justify-content-sm-center"><i className="fa fa-spin fa-spinner"></i></div>
                  }
                 </div>
                 <div className="col-4">
                  <ProfileAnime />  
                 </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapStateToProps = (state) => ({
  isLoading: state.MyProfileReducer.isLoading,
  profile: state.MyProfileReducer.data,
});

export default connect(mapStateToProps, {
  fetchingProfile,
})(ChangeProfileScreen);
