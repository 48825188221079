import qs from 'querystring';
import api from "../utils/api";
import { SHOP_LOADING,SHOP_ITEMS,SHOP_FAILED } from "./types";

export const fetchingShop = (shopId,subCategoryId,sizes,colors,pricerange,padding,brastyle,discounted,wired,coverage,orderby,limit)=> async dispatch => {
    
    const body ={
        method:'productsbygroup_v1',
//        GroupId : shopId,
        cateid:shopId,
        subcateid:subCategoryId,
        size:sizes, //32,28,36
        colors: colors,//1,5,9   // colorid
        pricerange:pricerange,//300-1200
        padding:padding,  //1    // productype bhejana yaha jo bhi aa yaha hoga
        brastyle: brastyle,//1   // productype bhejana yaha jo bhi aa yaha hoga
        discounted: discounted,//0/10/50
        wired: wired, //1    // productype bhejana yaha jo bhi aa yaha hoga
        coverage: coverage, // 1  // productype bhejana yaha jo bhi aa yaha hoga
        orderby: orderby, //top/new/low/high
        limit:limit
    };

    try{
        dispatch({
            type:SHOP_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:SHOP_ITEMS,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:SHOP_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}