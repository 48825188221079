import { parse } from "commander";
import { FETCH_COUPONS, FETCH_COUPONS_FAILED, APPLY_COUPON_LOADING, APPLY_COUPON, APPLY_COUPON_FAILED, REMOVE_COUPON } from "../actions/types";

const initialState={
    isLoading:true,
    coupons:{},
    isCouponApplying:false,
    couponAmount:0,
    isCouponApplied:{}
};

function CouponReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case FETCH_COUPONS:
            return {
                ...state,
                isCouponApplied:{},
                coupons:payload,
                isLoading:false,
            };
        case FETCH_COUPONS_FAILED:
            return {
                ...state,
                coupons:payload,
                isLoading:false,
            };
        case APPLY_COUPON_LOADING:
            return {
                ...state,
                isCouponApplying:true,
            };
        case APPLY_COUPON:
            return{
                ...state,
                isCouponApplying : false,
                couponAmount : payload?.status ? parseInt(payload?.discount_amount) : 0,
                isCouponApplied:payload
            };
        case APPLY_COUPON_FAILED:
            return{
                ...state,
                isCouponApplying : false,
                isCouponApplied:payload
            };
        case REMOVE_COUPON : 
            return {
                ...state,
                isCouponApplied:{},
                couponAmount : 0
            };
        default:
            return state;
    }
}

export default CouponReducer;