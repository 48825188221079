import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
// import PropTypes from 'prop-types';
import { fetchingCoupons, applyingCoupon, RemoveCoupon } from '../../actions/CouponsActions';

import { isIEBrowser } from '../../utils/index';
import CouponsModal from './CouponsModal';

function CouponComponent(props) {
    const { fetchingCoupons, applyingCoupon, isCouponApplying, isCouponApplied,couponAmount, RemoveCoupon, total } = props;

    const [visible,setVisible] = useState(false);
    const[coupon,setCoupon]=useState('');

    useEffect(() => {
        fetchingCoupons();
    }, [fetchingCoupons]);

    const showDrawer = () => {
        setVisible(true);
      };
    const onClose = () => {
        setVisible(false);
    };

    const applyCoupon=(couponCode,isApplyingThroughTextbox,endDate)=>{
        console.log(total, couponAmount);
        if((couponAmount > 0 && total > couponAmount && new Date() > new Date(endDate) ) || isApplyingThroughTextbox ==='1'){
            if(coupon !== couponCode){
                if(couponAmount > 0)
                    RemoveCoupon(couponAmount);
                setCoupon(couponCode);
                applyingCoupon(couponCode);
            }else if(isApplyingThroughTextbox ==='1'){
                if(couponAmount > 0)
                    RemoveCoupon(couponAmount);
                applyingCoupon(couponCode);
            }
        }else{
            notification.error({
                message: 'Error',
                key:'adding',
                placement:'bottomRight',
                description:
                  'Coupon Expired or Not Applicable.',
                onClick: () => {
                  console.log('Notification Clicked!');
                },
              });
        }
    }
    const removeCoupon = ()=>{
        RemoveCoupon(couponAmount);
        setCoupon('');
    }
    return (
        <>
            <div className="cart-discount" style={{ minHeight: isIEBrowser() ? '40px' : 'auto' }}>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control"
                    required="" 
                    placeholder="coupon code" 
                    value={coupon}
                    name="coupon"
                    //disabled={coupon?'disabled':''}
                    onChange={e=>{
                        const couponCodeValue= e.target.value;
                        setCoupon(couponCodeValue);
                        if(couponAmount){
                            removeCoupon();
                        }
                    }}
                    />
                    {isCouponApplying ? <center><i className="fa fa-spin fa-spinner"></i></center> :
                    isCouponApplied?.status ?
                    <div className="input-group-append">
                    <button className="btn btn-outline-primary-2" type="button" onClick={e=>{
                        e.preventDefault();
                        removeCoupon();
                    }}>
                        <i className="fa fa-times"></i>
                    </button>
                </div>:
                <div className="input-group-append">
                    <button className="btn btn-outline-primary-2" type="button" onClick={e=>{
                        e.preventDefault();
                        applyCoupon(coupon,'1', new Date());
                    }}>
                        <i className="icon-long-arrow-right"></i>
                    </button>
                </div>}
            </div>
            <p><i className="fa fa-coupon"></i> Select a promo code <button className="btn btn-link text-danger" onClick={showDrawer}>View Coupons</button></p>
            <CouponsModal
                handleClose={onClose}
                show={visible}
                applyCoupon={applyCoupon}
            />
            </div>
            <hr/>
        </>
    )
}

export const mapStateToProps = (state) => ({
    isCouponApplying : state.CouponReducer.isCouponApplying,
    isCouponApplied : state.CouponReducer.isCouponApplied,
    couponAmount : state.CouponReducer.couponAmount
})

export default connect(mapStateToProps, { fetchingCoupons,applyingCoupon, RemoveCoupon })(CouponComponent);