import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchingHomePageCategoryAndSubcategory } from '../../../actions/CategoryAndSubcategory';
import { FirstLetterCapitalize} from '../../../utils/common';
function MainMenu({ isLoading, fetchingHomePageCategoryAndSubcategory, data }) {
    const [path, setPath] = useState("");
    // const PUBLIC_URL = "/react/";

    useEffect(() => {
        setPath( window.location.href );
        fetchingHomePageCategoryAndSubcategory();
    }, [fetchingHomePageCategoryAndSubcategory]);

    return (

        <nav className="main-nav">
            <ul className="menu sf-arrows">
                {isLoading ?
                    <div className="p-4">
                        <center><i className="fa fa-spin fa-spinner"></i></center>
                    </div>
                    :
                    data && data.status ?
                        (
                            data.category.map((category) =>
                                <li key={category.category} className={path.indexOf("shop") > -1 ? 'active' : ''}>
                                    <a href={`${process.env.PUBLIC_URL}/store/${category.id}/${category.category}`} className={`${category.subcategory.length > 0 && 'sf-with-ul'} tw-text-white`}>{category.category}</a>
                                    {category.subcategory.length > 0 &&
                                    <div className="megamenu megamenu-md">
                                        <div className="row no-gutters">
                                            <div className="col-md-8">
                                                <div className="menu-col">
                                                    <div className="row">
                                                        <ul>
                                                            {category.subcategory.map((subcategory) =>
                                                                <li key={subcategory.subcategory} 
                                                                className={path.indexOf("shop/sidebar/list") > -1 ? "active" : ''}>
                                                                    <a className="hover:tw-bg-gray-100" 
                                                                    href={`${process.env.PUBLIC_URL}/store/${category.id}-${subcategory.id}/${subcategory.subcategory}`}>
                                                                        <b>{FirstLetterCapitalize(subcategory.subcategory)}</b>
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="banner banner-overlay">
                                                    <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list`} className="banner banner-menu">
                                                        <img src={category.image} alt={category.category} />

                                                        {/* <div className="banner-content banner-content-top">
                                                            <div className="banner-title text-white">Last <br />Chance<br /><span><strong>Sale</strong></span></div>
                                                        </div> */}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </li>
                            )
                        ) : <p className="alert alert-info"> {data?.message || "Something went wrong."} </p>}
            </ul>
        </nav>
    );
}
const mapStateToProps = state => ({
    isLoading: state.CategoryAndSubcategoryReducer.isLoading,
    data: state.CategoryAndSubcategoryReducer.data
});

export default connect(mapStateToProps, { fetchingHomePageCategoryAndSubcategory })(MainMenu);