import { combineReducers } from "redux";

import authReducer from './authenticationReducer';
import homeReducer from './DashboardReducer';
import cartReducer from './CartReducer';
import favouriteReducer from "./FavouriteReducer";
import productDetailReducer from './ProductDetailReducer';
import changePasswordReducer from './ChangePasswordReducer';

import AddressReducer from './AddressReducer';
import CategoryAndSubcategoryReducer from './CategoryAndSubcategoryReducer';
import MyProfileReducer from './MyProfileReducer';
import MyOrdersReducer from './MyOrdersReducers';
import MyOrderDetailReducer from './MyOrderDetailReducer';
import FaqReducer from './FaqReducer';
import NotificationReducer from './NotificationReducer';
import ShopReducer from "./ShopReducer";
import CheckoutReducer from "./CheckoutReducer";
import RatingReducer from "./RatingReducer";
import FinalCheckoutReducer from "./FinalCheckoutReducer";
import VerifyAccountReducer from "./VerifyAccountReducer";
import PincodeReducer from "./PincodeReducer";
import FilterReducer from "./FiltersReducer";
import PaymentGatewayReducer from "./PaymentGatewayReducer";
import SupportReducer from "./SupportReducer";
import ForgotReducer from "./ForgotPasswordReducer";
import CouponReducer from "./CouponsReducer";
// Import custom components
import productReducer from './products';
import cartReducer1 from './cartGuestReducer';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import filterReducer from './filter';
import modalReducer from './modal';
import SizeChartReducer from "./SizeChartReducer";
import CancelRemarksReducer from "./CancelRemarks";

export default combineReducers({
    authReducer,
    homeReducer,
    cartReducer,
    favouriteReducer,
    productDetailReducer,
    AddressReducer,
    CategoryAndSubcategoryReducer,
    changePasswordReducer,
    MyProfileReducer,
    MyOrdersReducer,
    MyOrderDetailReducer,
    FaqReducer,
    NotificationReducer,
    ShopReducer,
    CheckoutReducer,
    RatingReducer,
    FinalCheckoutReducer,
    VerifyAccountReducer,
    PincodeReducer,
    FilterReducer,
    PaymentGatewayReducer,
    SupportReducer,
    ForgotReducer,
    CouponReducer,
    SizeChartReducer,
    CancelRemarksReducer,
    data: productReducer,
    cartlist: cartReducer1,
    wishlist: wishlistReducer,
    compare: compareReducer,
    filters: filterReducer,
    modal: modalReducer
});