import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import Utils
import { safeContent } from '../../utils';

function Banner( props ) {
    const { banner,height,type,adClass,title } = props;

    return (
        <div className={ `banner banner-overlay ${adClass}` }>
            <Link to="#">
                <div className="lazy-overlay bg-3"></div>
                <LazyLoadImage
                    src={ banner.bannerphoto}
                    alt="Banner"
                    // width={ 100 }
                    height={ height }
                    effect="blur"
                />
            </Link>
            {/* <div className={ `banner-content banner-content-${type}` }>
                <h4 className="banner-subtitle text-white"><Link to="#">{ banner.bannerdesc }</Link></h4>
                <h3 className="banner-title text-white"><Link to="#" dangerouslySetInnerHTML={ safeContent( title ) }></Link></h3>
                <Link to={ `${process.env.PUBLIC_URL}/shop/sidebar/list` } className="banner-link">Shop Now<i className="icon-long-arrow-right"></i></Link>
            </div> */}
        </div>
    )
}

export default Banner;