import { 
    SHOP_LOADING,
    SHOP_ITEMS,
    SHOP_FAILED
 } from "../actions/types";

const initialState = {
    isLoading: true,
};

function ShopReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SHOP_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case SHOP_ITEMS:
            return {
                ...state,
                shop: payload,
                isLoading: false,
            };
        case SHOP_FAILED:
            console.log(state);
            return {
                ...state,
                shop: payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default ShopReducer;