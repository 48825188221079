import qs from 'querystring';
import api from "../utils/api";
import { FAQ_LOADING,FAQ,FAQ_FAILED } from "./types";

export const fetchingFaq = ()=> async dispatch => {
    
    const body ={
        method:'gracewellfaq',
    };
    try{
        dispatch({
            type:FAQ_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:FAQ,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:FAQ_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}