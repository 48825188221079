import { FETCH_FILTERS, FETCH_FILTERS_FAILED } from "../actions/types";

const initialState={
    isLoading:true,
};

function FilterReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case FETCH_FILTERS : 
            return {
                ...state,
                isLoading : false,
                data : payload,
            };
        case FETCH_FILTERS_FAILED:
            return {
                ...state,
                token:null,
                isAuthenticated : false,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default FilterReducer;