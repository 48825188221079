import { useLottie } from 'lottie-react';
import animationData from '../../animations/delivery-truck.json';

export default function LoadingOrdersAnime() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
      const { View } = useLottie(defaultOptions);
      return View;
  }