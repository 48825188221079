import { CART,FAILED,CART_LOADING,CART_ADD,CART_UPDATION,CART_UPDATION_DONE,CART_ADDED_COMPLETE, APPLY_COUPON, REMOVE_COUPON } from "../actions/types";

import  { getCartTotal,getCartProductCount }  from '../services';
const initialState={
    isLoading:true,
    data:null,
    total:0,
    isCartUpdating:false,
    isAddNewItem:false
};

function cartReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case CART_LOADING:
            return {
                ...state,
                isLoading:true,
            };
        case CART:
            return {
                ...state,
                data:{...payload},
                isLoading:false,
                total : payload.cartvariables ? getCartTotal(payload.cartvariables) : 0,
                totalProducts : payload.cartvariables ? getCartProductCount(payload.cartvariables) : 0
            };
            case CART_ADDED_COMPLETE:
                return {
                    ...state,
                    isAddNewItem:false,
                };
        case CART_ADD:
            return {
                ...state,
                isLoading:false,
                isAddNewItem:true,
                //data:{...payload}
            };
        case CART_UPDATION:
            return {
                ...state,
                isCartUpdating : true
            };
        case CART_UPDATION_DONE:{
            return {
                ...state,
                isCartUpdating : false
            };
        }
        case APPLY_COUPON :
            return {
                ...state,
                total : state.total - parseInt(payload.discount_amount)
            };
        case REMOVE_COUPON :
            return {
                ...state,
                total : state.total + parseInt(payload.couponAmount)
            };
        case FAILED:
            return {
                ...state,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default cartReducer;