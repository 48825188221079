import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { fetchingHomePageCategoryAndSubcategory } from '../../../actions/CategoryAndSubcategory';
import { mobileMenu } from '../../../utils/index';
import { FirstLetterCapitalize } from '../../../utils/common';

function MobileMainNav( {isLoading, data} ) {

    const [path, setPath] = useState("");

    useEffect(() => {
        setPath( window.location.href );
        mobileMenu();
        fetchingHomePageCategoryAndSubcategory();
    }, [path]);

    // const splitPath = window.location.href.split('/');
    // const catAndSubcat = splitPath[splitPath.length - 2];
    // const pathCategory = catAndSubcat.split('-')[0];
    // const pathSubcategory = catAndSubcat.split('-')[1];

    // console.log(catAndSubcat,pathCategory, pathSubcategory);

    return (
        <nav className="mobile-nav">
            <ul className="mobile-menu">
                {isLoading ?
                    <div className="p-4">
                        <center><i className="fa fa-spin fa-spinner"></i></center>
                    </div>
                    :
                    data && data.status &&
                    (
                        data.category.map((category) =>
                            <li key={category.category}>
                                <Link to={ `${process.env.PUBLIC_URL}/store/${category.id}/${category.category}` } className="sf-with-ul">
                                    {category.category}
                                </Link>

                                <ul>
                                {category.subcategory.map((subcategory) =>
                                    <li key={subcategory.subcategory} >
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/store/${category.id}-${subcategory.id}/${subcategory.subcategory}`}>
                                            {FirstLetterCapitalize(subcategory.subcategory)}
                                        </Link>
                                    </li>
                                )}
                                </ul>
                            </li>
                        )
                    )
                }
            </ul>
        </nav>
    );
}
const mapStateToProps = state => ({
    isLoading: state.CategoryAndSubcategoryReducer.isLoading,
    data: state.CategoryAndSubcategoryReducer.data
});

export default connect(mapStateToProps, { fetchingHomePageCategoryAndSubcategory })(MobileMainNav);