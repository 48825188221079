import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PinInput from "react-pin-input";
import qs from 'querystring';
import { Helmet } from "react-helmet";
import { Card } from 'react-bootstrap';
// import PropTypes from 'prop-types';
import { sendOtp,} from '../../actions/authentications';
import { verifyAccount } from "../../actions/VerifyAccountAction";
import { CheckPasswordComplexity,FirstLetterCapitalize } from '../../utils/common';

const VerifyOtpScreen = ({ sendOtp, verifyAccount, isAuthenticated, isLoading, data, location, history,error }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword:'',
    otp: ''
  });

  const { firstName, lastName, password,confirmPassword, otp } = formData;

  const [otpError, setOtpError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  var qss = qs.parse(location.search.substring(1));

  const onPinChange = e => {
    setFormData({ ...formData, otp: e });
  };

  if (!qss.email || !qss.phone) {
    return <Redirect to="/" />;
  }
  const onResend = e => {
    e.preventDefault();
    sendOtp(qss.email, qss.phone);
  }
  const onChangeConfirmPassword=e=>{
    if(formData.password !== formData.confirmPassword)
      setConfirmPasswordError("Password and Confirm Password Should be same.");
    else
      setConfirmPasswordError('');
  }
  const checkPasswordStrength=e=>{
    e.preventDefault();
    if (!CheckPasswordComplexity(password))
      setPasswordError('Password must be Strong(Contain Special Character, UpperCase letter and LowerCaseLetter.).');
    else
      setPasswordError('');
  }
  const onVerify = e => {
    e.preventDefault();
    if (password.length < 8 || password.length > 16)
      return setPasswordError('Password must be in between 8 to 16 digit.');
    else if (!CheckPasswordComplexity(password))
      return setPasswordError('Password must be Strong(Contain Special Character, UpperCase letter and LowerCaseLetter.).');
    else setPasswordError('');
    if(formData.password !== formData.confirmPassword)
      return setConfirmPasswordError('Password and Confirm Password Should be same.');
    else setConfirmPasswordError('');
    if (otp && otp.length === 6) {
      setOtpError('');
      verifyAccount(firstName, lastName, qss.phone, qss.email, password, 0, '', otp);
    }
    else
      return setOtpError('Please Provide Valid OTP value.');

    }
    if (data && data.status) {
      return history.push("/");
    }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gracewell - Verify OTP</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="row" style={{margin:'45px 0 45px 0'}}>
        <div className="col-sm-3"></div>
        <Card className="col-sm-5  p-4 bg-light">
          {/* <h1 className="large text-primary">Verify</h1> */}
          <p className="lead">
            <i className="fa fa-user" /> Verify OTP for Your Account
      </p>
          <hr />
          {data ? (<p className={data.status ? "alert alert-info" : "alert alert-danger"}> {FirstLetterCapitalize(data.message)} </p>) : null}
          <form className="form">
            <div className="row">
              <div className="col-6">
                <label>First Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  maxLength="30"
                  value={firstName}
                  onChange={onChange}
                />
              </div>
              <div className="col-6">
                <label>Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  maxLength="30"
                  value={lastName}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label>Create Password</label>
                <input
                  className={`form-control ${passwordError ? "is-invalid" : ""}`}
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  onKeyUp={checkPasswordStrength}
                  minLength="6"
                  maxLength="15"
                />
                {passwordError ? <span className="text text-danger"> {passwordError}</span> : null}
              </div>
              <div className="col-6">
                <label>Confirm Password</label>
                <input
                  className={`form-control ${confirmPasswordError ? "is-invalid" : ""}`}
                  type="password"
                  placeholder="Password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChange}
                  onKeyUp={onChangeConfirmPassword}
                  minLength="6"
                  maxLength="15"
                />
                {confirmPasswordError ? <span className="text text-danger"> {confirmPasswordError}</span> : null}
              </div>
            </div>
            <div className="form-group">
              <label>OTP</label>
              <PinInput
                length={6}
                focus
                secret
                type="numeric"
                onChange={onPinChange}
                inputStyle={{
                  borderBottom:"1px solid black"
                }}
              />
              <span className="pull-right"><button type="button" className="btn btn-link" onClick={e=>onResend(e)}><i className="fa fa-refresh"></i> Resend Otp ?</button></span>
              {otpError ? <span className="text text-danger"> {otpError}</span> : null}
            </div>
            <br />
            <div className="form-group">
              {!isLoading ? (<>
                <button type="button" className="btn btn-success" onClick={e=>onVerify(e)} ><i className="fa fa-check"></i> Verify</button>
              </>) : <i className="fa fa-spin fa-spinner"></i>}
            </div>
          </form>
          <p className="my-1">
            {/* Don't have an account? <Link to="/register">Login Up</Link> */}
          </p>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.isAuthenticated,
  isLoading: state.VerifyAccountReducer.isLoading,
  data: state.VerifyAccountReducer.data,
});

export default connect(mapStateToProps, { sendOtp, verifyAccount })(VerifyOtpScreen);