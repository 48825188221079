import { 
    CHECKOUT_FINAL_LOADING,
    CHECKOUT_FINAL,
    CHECKOUT_FINAL_FAILED
 } from "../actions/types";

const initialState={
    isFinalCheckoutLoading:false,
    finalCheckoutData: null,
};

function FinalCheckoutReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case CHECKOUT_FINAL_LOADING :
            return {
                ...state,
                isFinalCheckoutLoading:true,
            };
        case CHECKOUT_FINAL:
            return {
                ...state,
                finalCheckoutData:{...payload},
                isFinalCheckoutLoading:false,
            };
        case CHECKOUT_FINAL_FAILED:
            return {
                ...state,
                isFinalCheckoutLoading:false,
                finalCheckoutData:{...payload}
            };
        default:
            return state;
    }
}

export default FinalCheckoutReducer;