import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Rate } from 'antd';
// import Custom Components
import { addRating } from '../../actions/RatingAction';

import { FirstLetterCapitalize, } from '../../utils/common';

function AddNewRatingScreen(props) {
    const { productId,isLoading,data, addRating } = props;

    const [rating, setRating] = useState('');
    const [comment, setComment] = useState('');

    const [ratingError, setRatingError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');

    const addRatingRequest = (e) => {
        e.preventDefault();
        
        setRatingError('');
        setNewPasswordError('');

        if (!rating || parseInt(rating) > 5)
            return setRatingError('Please Provide a Valid Rating.');
        else
            return addRating(productId,rating,comment);
    }
    return (
        <>
            <div className="row justify-content-sm-center">
                <div className="col-6">
                    <div className="form-group">
                        <label>Rating <span className="text text-danger">*</span></label>
                        <Rate
                            onChange={e => setRating(e)}
                        />
                    </div>
                    <span className="text text-danger">{ratingError || ''}</span>
                    <div className="form-group">
                        <label>Comment</label>
                        <textarea
                            type="password"
                            minLength="8"
                            maxLength="16"
                            className={`form-control ${newPasswordError ? 'is-invalid' : ''}`}
                            name={comment}
                            onChange={e => setComment(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="form-group">
                    <>
                        <button type="button" className="btn btn-primary" disabled={isLoading ? 'disabled':''}
                            onClick={(e) => addRatingRequest(e)}  >
                                {
                                    isLoading ?
                                <>    
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span></> :
                                <><i className="fa fa-comment"></i> Add Comment.</>
                                }
                        </button>
                        <br/><br/>
                        {data ? data.status ?<div className="alert alert-primary">{FirstLetterCapitalize("Your Review Successfully Added.")}</div>  : <div className="alert alert-danger">{ FirstLetterCapitalize(data.message)}</div>:''}
                        </>
                    </div>
                </div>
            </div>          
        </>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.RatingReducer.isLoading,
    data: state.RatingReducer.data
});

export default connect(mapStateToProps, { addRating })(AddNewRatingScreen);