import { useLottie } from "lottie-react";

import animationData from "../../animations/password.json";

export default function PasswordAnime() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(defaultOptions);
  return View;
}
