import { MY_ORDER_DETAIL,MY_ORDER_DETAIL_LOADING,MY_ORDER_DETAIL_FAILED } from "../actions/types";

const initialState={
    isLoading:false,
    data:null,
};

function myOrderDetailReducer(state=initialState,action){
    const {type,payload} = action;
    switch(type){
        case MY_ORDER_DETAIL_LOADING:
            return {
                ...state,
                isLoading:true,
            };
        case MY_ORDER_DETAIL:
            console.log('order detail',payload);
            return {
                data:{...payload},
                isLoading:false,
            };
        case MY_ORDER_DETAIL_FAILED:
            return {
                ...state,
                isLoading:false,
            };
        default:
            return state;
    }
}

export default myOrderDetailReducer;