import React, { useState,useEffect } from 'react';
import { Link,Redirect } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { notification } from 'antd';
import { closeModal } from '../../../actions/QuickViewModelsActions';
import { tryLogin,sendOtp,removeLoginRegisterError } from '../../../actions/authentications';
import { verifyAccount, } from '../../../actions/VerifyAccountAction';

import {FirstLetterCapitalize} from '../../../utils/common';

import SocialButton from "../SocialLogin/SocilaLoginComponent";

const customStyles = {
    content: {
        top: '50%',
        transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(77,77,77,0.6)',
        zIndex: '10000'
    }
};

Modal.setAppElement( '#root' );

function LoginModal( props ) {
    const { showModal, modal , tryLogin,sendOtp, isLoading, data,verifyAccount,verifyData,isVerifying,removeLoginRegisterError } = props;
    let timer;

    function closeModal() {
        document.getElementById( "login-modal" ).classList.remove( "ReactModal__Content--after-open" );
        
        timer = setTimeout( () => {
            props.closeModal( 'login' );
        }, 200 );
    }

    useEffect( () => {
        return () => {
            if ( timer ) clearTimeout( timer );
        }
    } )
    const [formRegisterData, setFormRegisterData] = useState({
        email: '',
        phone: ''
    });
    const [requestType,setRequestType] = useState(1);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    
// --- login section
    const [isPasswordShown,setIsPasswordShown] = useState(false);
    const [formData, setFormData] = useState({
        account: '',
        password: ''
    });
    const [mobileError, setMobileError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { account, password } = formData;
    const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        if (!account.includes("@") && (account.length !== 10 || isNaN(account))) return setMobileError("Invalid Email or Mobile.");
        else setMobileError('');
        if (password.length < 6 || password.length > 16) return setPasswordError('Please Enter Valid Password. Password must be in between 8 to 16 digit.');
        else setPasswordError('');
        setRequestType(1);
        tryLogin(account,password);
    };
    if (data && data.status && requestType === 1) {
        return <Redirect to="/" />
    //    return <Redirect to={`/verifyotp/${account}`} />;
      }
// -- end login section

// -- start register section

const { email, phone } = formRegisterData;

const onRegisterChange = e =>
    setFormRegisterData({ ...formRegisterData, [e.target.name]: e.target.value });

const onRegisterSubmit = e => {
    e.preventDefault();
    if (!email || !email.includes("@")) return setEmailError("Invalid Email.");
    else setEmailError('');
    if (phone.length !== 10 || isNaN(phone)) return setPhoneError('Please Enter Valid Phone Number.');
    else setPhoneError('');
    setRequestType(2);
    sendOtp(email, phone);
};

if (data && data.status && requestType === 2) {
    return <Redirect to={`/verifyotp?email=${email}&phone=${phone}`} />;
}
// -- end register section
const handleSocialLogin = (user) => {
    const {firstName,lastName,email,id,profilePicURL} = user._profile;
    verifyAccount(firstName,lastName,'',email,id,3,profilePicURL,'');
  };
  
  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  if(verifyData){
    if(verifyData.status)
        notification.success({
            message: 'Success',
            key:'deleting',
            placement:'bottomRight',
            description:
            'Your Request Completed.',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
    else if(!verifyData.status)
        notification.warn({
            message: 'Error',
            key:'deleting',
            placement:'bottomRight',
            description:
            'Something went wrong.',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
}
if( (data && !data.status ) || (verifyData && !verifyData.status) ){
    setTimeout(()=>{
        removeLoginRegisterError();
    },3000);
}
    return (
        <Modal
            isOpen={ showModal && 'login' === modal }
            onRequestClose={ closeModal }
            style={ customStyles }
            contentLabel="Login Modal"
            className="modal-dialog modal-dialog-centered"
            id="login-modal" >
            <div className="modal-content">
                <div className="modal-body">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={ closeModal }>
                        <span aria-hidden="true"><i className="icon-close"></i></span>
                    </button>
                    <div className="form-box">
                        <div className="form-tab">
                            <Tabs selectedTabClassName="show" defaultIndex={ 0 }>
                                <TabList className="nav nav-pills nav-fill">
                                    <Tab className="nav-item">
                                        <span className="nav-link">Sign In</span>
                                    </Tab>
                                    <Tab className="nav-item">
                                        <span className="nav-link">Register</span>
                                    </Tab>
                                </TabList>
                                <div className="tab-content">
                                    <TabPanel style={ { paddingTop: "2rem" } }>
                                        <div>
                                        {data && requestType === 1 ? (<p className={data.status  ? "alert alert-success" : "alert alert-danger"}> {FirstLetterCapitalize(data.message)} </p>) : null}
                                            <form onSubmit={onSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="account" className="tw-font-bold" >Mobile or Email address *</label>
                                                    <input 
                                                        type="text" 
                                                        className={`form-control ${mobileError ? "is-invalid" :""}`}
                                                        name="account"
                                                        value={account}
                                                        onChange={onChange}
                                                        required />
                                                    {mobileError ? (<p className="text text-danger">{mobileError}</p>) : null}
                                                </div>

                                                    <label htmlFor="password" className="tw-font-bold">Password *</label>
                                                <div class="input-group mb-3">
                                                    
                                                    <input type={`${isPasswordShown?'text':'password'}`} 
                                                        className={`form-control ${passwordError ? "is-invalid" :""}`}
                                                        name="password"
                                                        value={password}
                                                        onChange={onChange}
                                                        minLength="6"
                                                        maxLength = "16"
                                                        required />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary" type="button" onClick={(e)=>{
                                                            e.preventDefault();
                                                            setIsPasswordShown(!isPasswordShown);
                                                        }}> {isPasswordShown ?<i className="fa fa-eye"></i> :<i className="fa fa-eye-slash"></i>}</button>
                                                    </div>
                                                    {passwordError ? (<p className="text text-danger">{passwordError}</p>) : null}
                                                </div>
                                                <div className="form-footer">
                                                    <button type="submit" className="btn btn-outline-primary-2" disabled={isLoading ? 'disabled' : ''}>
                                                    {isLoading ?
                                                        <>    
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span className="sr-only tw-font-bold">Loading...</span></> :
                                                        <>
                                                            <span className="tw-font-bold">LOG IN</span>
                                                            <i className="icon-long-arrow-right"></i>
                                                        </>}
                                                    </button>

                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="signin-remember-2" />
                                                        <label className="custom-control-label tw-font-bold" htmlFor="signin-remember-2">Remember Me</label>
                                                    </div>

                                                    <Link to="/forgot-password" onClick={closeModal} className="forgot-link tw-font-bold">Forgot Your Password?</Link>
                                                </div>
                                            </form>
                                            <div className="form-choice">
                                                <p className="text-center tw-font-bold">or sign in with</p>
                                                <div className="row">
                                                <div className="col-sm-6">
                                                    {isVerifying ? 
                                                        <>    
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span className="sr-only tw-font-bold">Loading...</span>
                                                        </> :
                                                        <SocialButton
                                                            provider="google"
                                                            appId="426642445599-fdgemlmjf8h323qusniolbfa4uaahdfa.apps.googleusercontent.com"
                                                            onLoginSuccess={handleSocialLogin}
                                                            onLoginFailure={handleSocialLoginFailure}
                                                            className="btn btn-login btn-g"
                                                            isSignedIn={false}
                                                        >
                                                            <i className="icon-google"></i>
                                                            <span className="tw-font-bold">Login With Google</span>
                                                        </SocialButton>
                                                        }
                                                    </div>

                                                    {/* <div className="col-sm-6">
                                                    <SocialButton
                                                            provider="facebook"
                                                            appId="4441127615931953"
                                                            onLoginSuccess={handleSocialLogin}
                                                            onLoginFailure={handleSocialLoginFailure}
                                                            className="btn btn-login btn-f"
                                                            >
                                                            <i className="icon-facebook-f"></i>
                                                            <span className="tw-font-bold">Login With Facebook</span>
                                                        </SocialButton>
                                                        
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        { (data && requestType === 2) || verifyData ? (<p className={data?.status || verifyData?.status ? "alert alert-success" : "alert alert-danger"}> {FirstLetterCapitalize(data?.message || verifyData?.message)} </p>) : null}
                                        <form onSubmit={onRegisterSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email" className="tw-font-bold">Your email address *</label>
                                                <input 
                                                    type="email" 
                                                    className={`form-control ${emailError ? "is-invalid":""}`}
                                                    name="email"
                                                    value={email}
                                                    onChange={onRegisterChange}
                                                    required 
                                                />
                                                {emailError ? (<p className="text text-danger">{emailError}</p>) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phone" className="tw-font-bold">Your phone number *</label>
                                                <input 
                                                    type="text" 
                                                    className={`form-control ${phoneError ? "is-invalid":""}`}
                                                    name="phone"
                                                    value={phone}
                                                    onChange={onRegisterChange}
                                                    maxLength="10"
                                                    minLength="10"
                                                    required 
                                                />
                                                {phoneError ? (<p className="text text-danger">{phoneError}</p>) : null}
                                            </div>

                                            <div className="form-footer">
                                                <button type="submit" className="btn btn-outline-primary-2">
                                                    <span className="tw-font-bold">SEND OTP</span>
                                                    <i className="icon-long-arrow-right"></i>
                                                </button>

                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="register-policy-2" required />
                                                    <label className="custom-control-label" htmlFor="register-policy-2">I agree to the <Link to="#">privacy policy</Link> *</label>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="form-choice">
                                            <p className="text-center tw-font-bold">or sign in with</p>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    {isVerifying ? 
                                                            <>    
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="sr-only tw-font-bold">Loading...</span>
                                                            </> :<SocialButton
                                                            provider="google"
                                                            appId="426642445599-fdgemlmjf8h323qusniolbfa4uaahdfa.apps.googleusercontent.com"
                                                            onLoginSuccess={handleSocialLogin}
                                                            onLoginFailure={handleSocialLoginFailure}
                                                            className="btn btn-login btn-g"
                                                            isSignedIn={false}
                                                        >
                                                            <i className="icon-google"></i>
                                                            <span className="tw-font-bold">Login With Google</span>
                                                        </SocialButton>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-6">
                                                    <Link to="#" className="btn btn-login  btn-f">
                                                        <i className="icon-facebook-f"></i>
                                                        <span className="tw-font-bold">Login With Facebook</span>
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

function mapStateToProps( state ) {
    return {
        showModal: state.modal.showModal,
        modal: state.modal.modal,
        isAuthenticated: state.isAuthenticated,
        isLoading: state.authReducer.isLoading,
        data: state.authReducer.data,
        verifyData :state.VerifyAccountReducer.data,
        isVerifying : state.VerifyAccountReducer.isLoading
    }
}

export default connect( mapStateToProps, { closeModal,tryLogin,sendOtp,verifyAccount,removeLoginRegisterError } )( LoginModal );