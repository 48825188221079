import {  VERIFYACCOUNT, ACCOUNT_CREATION_LOADING, ACCOUNT_CREATION_ERROR, REMOVE_LOGIN_REGISTER_ERROR } from "../actions/types";

const initialState = {
    token: localStorage.getItem('__cust__id'),
    isAuthenticated: null,
    isLoading: false,
    user: null,
};

function VerifyAccountReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ACCOUNT_CREATION_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case VERIFYACCOUNT:
            return {
                ...state,
                data: payload,
                isAuthenticated: true,
                isLoading: false
            };
        case ACCOUNT_CREATION_ERROR :
            return {
                ...state,
                isAuthenticated : false,
                data : payload,
                isLoading: false
            }
        case REMOVE_LOGIN_REGISTER_ERROR :
            return {
                ...state,
                data:null
            };
        default:
            return state;
    }
}

export default VerifyAccountReducer;