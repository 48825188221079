import qs from 'querystring';
import api from "../utils/api";
import {
    CHECKOUT,
    CHECKOUT_FAILED,
} from "./types";

import {
    FetchUserIdFromLocalStorage,
    FetchBillingAddressIdFromLocalStorage,
    FetchShippingAddressIdFromLocalStorage,
    FetchTotalAmountAddressIdFromLocalStorage,
    FetchGuestUserIdFromLocalStorage,
} from '../utils/common';

export const tryCheckout = (isCod) => async dispatch => {
    const body = {
        method: 'checkout',
        userid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        couponcode: '',
        couponamount: '',
        totalamount: FetchTotalAmountAddressIdFromLocalStorage(),
        gst: '',
        billingaddressid: FetchBillingAddressIdFromLocalStorage(),
        shippingaddressid: FetchShippingAddressIdFromLocalStorage(),
        paymode:isCod ? 'offline':'online'
    };
    try {
        const response = await api.post('services.aspx', qs.stringify(body), );
        if (response.status) localStorage.setItem("__order__id", response.data.orderid);
        dispatch({
            type: CHECKOUT,
            payload: response.data
        });
    } catch (err) {
        dispatch({
            type: CHECKOUT_FAILED,
            payload: {
                status: false,
                message: 'Something went wrong.',
            }
        });
    }
}
