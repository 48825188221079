import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InputNumber } from "antd";
import { Grid, 
    Card, 
    CardMedia, 
    Box, 
    Typography,
    Button, 
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Rating,
   } from "@mui/material";

const CartItem = ({item,index, removeCartItem,addRemoveCartProductQuantity}) => {

    function onChangeQty( e, cartId,productId,lastQuantity,productquantity) {
        const plusMinus = (e > lastQuantity)?'plus':'minus';
        console.log(productquantity);
        
        
          addRemoveCartProductQuantity( cartId,productId,plusMinus );
        
        
    }
    const preventMinus = (e) => {
        
        if (e.code === 'Minus') {
            e.preventDefault();
        }
        
    };
    const [open, setOpen] = useState(false);

    const handleConfirmationPopup = () => {
        setOpen(!open);
    }
    console.log("item", item);
return (
    <>
        <Grid item xs={12} key={index}>
            <Card sx={{ display: 'flex' }}>
                <CardMedia
                    component="img"
                    sx={{ width: 151, padding: 2 }}
                    image={item.productphoto}
                    alt=""
                />                
                <Grid container xs={12} spacing={2} sx={{
                    padding:1
                }}>
                    <Grid item xs={12} sm={10}>
                        <Typography component="div" variant="h5" sx={{ margin:1}}>
                            <Link className="tw-capitalize" to={ `${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}` }>{ item.productname }</Link>
                        </Typography>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" color="text.secondary" component="div" sx={{ margin:1}}>
                                Color :  <span className="tw-mt-1 tw-text-sm tw-text-gray-500 tw-h-8 tw-w-8" style={{backgroundColor:item.productcolor,display:'inline-block',paddingTop:'5px'}}></span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" color="text.secondary" component="div" sx={{ margin:1}}>
                                Size : {item.productsize}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ padding: 1 }}>
                            <Rating name="read-only" value={item.productrating} readOnly />({item.productratingcount})
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" color="text.secondary" component="div" sx={{ margin:1}}>
                                ₹{item.productamount}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={2} container justifyContent="space-between">
                        <Grid item xs={12}>
                            <InputLabel id="demo-simple-select-label">Quantity</InputLabel>
                            <InputNumber min={1} max={10} 
                            type = "Number"
                            step="1"
                            onKeyPress={preventMinus}
                            value={item.productquantity && Math.max( 1 , item.productquantity)} 
                            onChange={(e)=> onChangeQty(e,item.cartid,item.productid,item.productquantity)}  />
                        </Grid>
                        <Grid xs={12}>
                        <Typography variant="h5" color="text.secondary" component="div" sx={{ margin:1}}>
                        <strong>
                            ₹ {(Math.abs(item.productamount * item.productquantity)).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 } ) }
                            </strong>
                        </Typography>
                        </Grid>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                            <Button autoFocus size="large" variant="outlined" color="error" onClick={handleConfirmationPopup}>
                                REMOVE
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Dialog
        open={open}
        keepMounted
        onClose={handleConfirmationPopup}
        aria-describedby="alert-dialog-slide-description"
      >
            <DialogTitle>{"Are you sure?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Are you sure you want to remove this item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={handleConfirmationPopup}>Cancel</Button>
                <Button variant="contained" onClick={(e) => 
                    {
                        setOpen(false);
                        removeCartItem( item.cartid );
                    }
                    }>Confirm</Button>
            </DialogActions>
        </Dialog>
    </>);}
            {/* <Grid item spacing={2} xs={12}>
                <Grid item xs={4}>
                    <figure className="p-1">
                        <Link to={ `${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}` }>
                            <img src={item.productphoto } alt="Product" width="120" className="tw-rounded" />
                        </Link>
                    </figure>
                </Grid>
                <Grid item xs={8}>
                    <Link className="tw-capitalize" to={ `${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}` }>{ item.productname }</Link>
                    <br/>
                    <div>
                    Color :  <span className="tw-mt-1 tw-text-sm tw-text-gray-500 tw-h-8 tw-w-8" style={{backgroundColor:item.productcolor,display:'inline-block',paddingTop:'5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                    <br/>
                    <span>Size : {item.productsize}</span>
                    ₹ { (item.productamount).toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } )}
                    <InputNumber min={1} max={10} 
                    value={item.productquantity} 
                    onChange={(e)=> onChangeQty(e,item.cartid,item.productid,item.productquantity)} />
                    ₹ {(item.productamount * item.productquantity).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 } ) }

                </Grid>
            </Grid> */}
        {/* <tr key={ 'cart-item-' + index }>
            <td className="total-col">
            </td>
            <td className="remove-col">

            </td>
        </tr> */}

    
export default CartItem;