import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// import Custom Components
import PageHeader from '../common/page-header';
import Breadcrumb from '../common/breadcrumb';
import { changePassword } from '../../actions/ChangePasswordAction';

import PasswordAnime from '../anime/PasswordAnime';
import { FirstLetterCapitalize, CheckPasswordComplexity } from '../../utils/common';

function ChangePasswordScreen(props) {
    const { isLoading,data, changePassword } = props;

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
console.log('data',data);
    const changePasswordRequest = (e) => {
        e.preventDefault();
        
        setConfirmNewPasswordError('');
        setCurrentPasswordError('');
        setNewPasswordError('');
        if (!CheckPasswordComplexity(currentPassword))
            return setCurrentPasswordError('Please Provide a Strong Password.');
        if (!CheckPasswordComplexity(newPassword))
            return setNewPasswordError('Please Provide a Strong Password.');
        if (!CheckPasswordComplexity(confirmNewPassword))
            return setConfirmNewPasswordError('Please Provide a Strong Password.');
        else if (newPassword !== confirmNewPassword)
            return setConfirmNewPasswordError('New and Confirm New Password does not match.');
        else
            return changePassword(currentPassword,newPassword);
    }
    return (
        <>
            <Helmet>
                <title>Gracewell | Change Password</title>
            </Helmet>
            <h1 className="d-none">Gracewell - Change Password</h1>
            <div className="main">
                {/* <PageHeader title="Change Password" subTitle="Shop" /> */}
                <Breadcrumb title="Change Password" parent1={["Shop", "shop/sidebar/list"]} />
                <div className="page-content">
                    <div className="container">
                        <div className="row justify-content-sm-center">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Current Password <span className="text text-danger">*</span></label>
                                    <input
                                        type="password"
                                        minLength="8"
                                        maxLength="16"
                                        className={`form-control ${currentPasswordError ? 'is-invalid' : ''}`}
                                        name={currentPassword}
                                        onChange={e => setCurrentPassword(e.target.value)}
                                    />
                                    <span className="text text-danger">{currentPasswordError || ''}</span>
                                </div>
                                <div className="form-group">
                                    <label>New Password <span className="text text-danger">*</span></label>
                                    <input
                                        type="password"
                                        minLength="8"
                                        maxLength="16"
                                        className={`form-control ${newPasswordError ? 'is-invalid' : ''}`}
                                        name={newPassword}
                                        onChange={e => setNewPassword(e.target.value)}
                                    />
                                    <span className="text text-danger">{newPasswordError || ''}</span>
                                </div>
                                <div className="form-group">
                                    <label>Confirm New Password <span className="text text-danger">*</span></label>
                                    <input
                                        type="password"
                                        minLength="8"
                                        maxLength="16"
                                        className={`form-control ${confirmNewPasswordError ? 'is-invalid' : ''}`}
                                        name={confirmNewPassword}
                                        onChange={e => setConfirmNewPassword(e.target.value)}
                                    />

                                    <span className="text text-danger">{confirmNewPasswordError || ''}</span>
                                </div>
                                <div className="form-group">
                                
                                <>
                                    <button type="button" className="btn btn-primary" disabled={isLoading ? 'disabled':''}
                                        onClick={(e) => changePasswordRequest(e)}  >
                                            {
                                                isLoading ?
                                           <>    
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="sr-only">Loading...</span></> :
                                           <><i className="fa fa-lock"></i> CHANGE PASSWORD</>
                                            }
                                    </button>
                                    <br/><br/>
                                    {data ? data.status ?<div className="alert alert-success">{FirstLetterCapitalize(data.message)}</div>  : <div className="alert alert-danger">{ FirstLetterCapitalize(data.message)}</div>:''}
                                    </>
                                
                                </div>
                            </div>
                            <div className="col-4">
                                <PasswordAnime />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.changePasswordReducer.isLoading,
    data: state.changePasswordReducer.data
});

export default connect(mapStateToProps, { changePassword })(ChangePasswordScreen);