import React, { Fragment, useState } from 'react';
import { connect, } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {Helmet} from "react-helmet";
// import PropTypes from 'prop-types';
import { sendOtp } from '../../actions/authentications';

const RegisterScreen = ({ sendOtp, isLoading, data }) => {
    const [formData, setFormData] = useState({
        email: '',
        phone: ''
    });

    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const { email, phone } = formData;

    const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        if (!email || !email.includes("@")) return setEmailError("Invalid Email.");
        else setEmailError('');
        if (phone.length !== 10 || isNaN(phone)) return setPhoneError('Please Enter Valid Phone Number.');
        else setPhoneError('');
        sendOtp(email, phone);
    };

    if (data && data.status) {
        return <Redirect to={`/verifyotp?email=${email}&phone=${phone}`} />;
    }

    return (
        <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Gracewell -Register</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
        <div className="row">
            <div className="col-offset-sm-3 col-sm-4">
                <h1 className="large text-primary">Sign Up</h1>
                <p className="lead">
                    <i className="fa fa-user" /> Register Your Account
                </p>
                {data ? (<p className={data.status ? "alert alert-success" : "alert alert-danger"}> {data.message} </p>) : null}
                <form className="form" onSubmit={onSubmit}>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Email Address"
                            name="email"
                            value={email}
                            onChange={onChange}
                        />
                        {emailError ? (<p className="text text-danger">{emailError}</p>) : null}
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Phone Number"
                            name="phone"
                            value={phone}
                            onChange={onChange}
                            maxLength="10"
                        />
                        {phoneError ? (<p className="text text-danger">{phoneError}</p>) : null}
                    </div>
                    {!isLoading ? <button type="submit" className="btn btn-primary "><i className="fa fa-lock"></i> Register </button> : <i className="fa fa-spin fa-spinner"></i>}
                </form>
                <p className="my-1">
                    Do have an account? <Link to="/">Login</Link>
                </p>
            </div>
        </div>
        </>
    );
};

// LoginScreen.propTypes = {
//   login: PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    isLoading: state.authReducer.isLoading,
    data: state.authReducer.data
});

export default connect(mapStateToProps, { sendOtp })(RegisterScreen);