import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Drawer, } from 'antd';
import { Grid, Rating } from "@mui/material";

import DescOne from '../description/desc-one';
import { FirstLetterCapitalize, FetchGuestUserIdFromLocalStorage } from '../../../../utils/common';
import MediaOne from '../media/media-one';
import { isIEBrowser, isEdgeBrowser, } from '../../../../utils/index';

import './detail-one.css';

function ProductDetailOne(props) {
    const { product, features, colors, isWishlist, addToCart, removeCartItem, addFavourite, isAuthenticated, reviews, handleSizeGuideShow, CartAddedShowComplete } = props;

    useEffect(() => {
        changeColor(colors[0].colorid);
    }, [])

    const cart = useSelector(state => state.cartReducer);
    const reviewRef = React.useRef(null);
    const onClose = () => {
        CartAddedShowComplete();
    };
    const [open, setOpen] = useState(true)

    const [sizes, setSizes] = useState();
    const [size, setSize] = useState('');
    const [colorId, setColorId] = useState(colors[0].colorid);
    const [images, setImages] = useState();
    const [cartLoading, setCartLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const [error, setError] = useState();

    const [featureIndex, setFeatureIndex] = useState(0);
    const [featureDetail, setFeatureDetail] = useState({ desc: features ? features[0].featuredesc : '' });

    const changeColor = (colorId) => {
        var _item = colors.find(item => item.colorid === colorId);
        setSizes(_item.productsize);
        setImages(_item.productphoto);
        setColorId(colorId);
        setSize('');
    }
    const handleSizeChange = (e) => {
        setError('');
        setSize(e);
    }
    const onChangeQuantity = (e, action) => {
        const quantityP = parseInt(quantity);
        if (action === "1" || action === "2" || (quantityP > 1 && action === "0")) {
            setQuantity(action === "1" ?
                quantityP + 1 :
                action === "2" ? parseInt(e.target.value) < 1 ? 1 : e.target.value : quantityP - 1);
        }
    }
    const addToCartHandler = (productId) => {
        if (!size) return setError('Please select size first.');
        if (!colorId) return setError('Please select color first.');
        if (!quantity || parseInt(quantity) < 1) return setError('Please Select a Valid Quantity Amount.');
        setCartLoading(true);
        if (isAuthenticated) {
            setError('');
            addToCart(productId, quantity, size, colorId);
        } else {
            if (FetchGuestUserIdFromLocalStorage()) {
                addToCart(productId, quantity, size, colorId);
            }
            else
                setError('Something went wrong. Please try again after sometime.');
        }
        setCartLoading(false);
    }

    const wishlistHandler = (productId) => {
        if (isAuthenticated) {
            setError('');
            addFavourite(productId);
        }
        else {
            if (FetchGuestUserIdFromLocalStorage())
                addFavourite(productId);
            else
                setError('Something went wrong. Please try again after sometime.');
        }
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [imageDimension, setImageDimension] = useState();

    function onImageLoad({ target: img }) {
        setImageDimension({
            dimensions: {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        });
    }

    return (
        <>
            <div className="col-md-5 tw-relative lg:tw-sticky" style={{ zIndex: 0, overflow: 'hidden', top: 0 }}>
                <div className="skel-product-gallery">
                </div>
                {images?.length > 0 ?
                    <MediaOne product={images} onImageLoad={onImageLoad} /> : ''
                }
            </div>

            <div className="col-md-7 tw-mt-0 md:tw-mt-10">
                <div className="entry-summary row">
                    <div className="col-md-12">
                        <div className="entry-summary1"></div>
                    </div>
                    <div className="col-md-12">
                        <div className="entry-summary2"></div>
                    </div>
                </div>
                <div className={"product-details"}>
                    <h1 className="product-title tw-capitalize">{FirstLetterCapitalize(product.productname)}</h1>
                    <hr className='tw-py-4' />
                    {0 === product.stock ?
                        <div className="product-price">
                            <span className="out-price">${product.productprice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</span>
                        </div> :
                        0 < product.productdisprice ?
                            <>
                                <Grid container xs={12}>
                                    <Grid item xs={12} md={8}>
                                        <span className="new-price"><strong>₹{product.productdisprice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</strong></span>
                                        <span className="old-price">₹{product.productprice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</span>
                                    </Grid>
                                    <Grid item xs={12} md={4} pt={1}>
                                        <Rating name="read-only" value={product.productrating} readOnly />
                                        <button className="ratings-text" id="review-link" onClick={reviewRef.current?.focus()}>
                                            ({product.productratingcount || 0} Reviews)
                                        </button>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <div className="product-price">₹{product.productprice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</div>
                    }

                    {features &&
                        <>
                            <hr />
                            <div className="mt-2 mb-2">
                                <h3>PRODUCT FEATURES</h3>
                                <div className="tw-max-w tw-rounded tw-overflow-hidden tw-shadow-lg tw-p-8">
                                    {features.map((feature, index) => {
                                        return <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFeatureIndex(index);
                                                setFeatureDetail({ desc: feature?.featuredesc, link: feature?.featurelink });
                                            }}
                                            className={`${featureIndex === index ? 'tw-px-4 tw-py-1 btn-primary border tw-border-pink-800 tw-text-white ' : 'tw-px-4 tw-py-1 border tw-border-gray-200 hover:tw-border-pink-800'} mr-3`}>{FirstLetterCapitalize(feature.featurename)}</button>
                                    })
                                    }
                                    <div className="tw-bg-gray-50 tw-p-10 mt-2">
                                        {FirstLetterCapitalize(featureDetail.desc)}
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    {colors ?
                        <div className="details-filter-row details-row-size">
                            <label>Colors :</label>
                            {colors ?
                                <div className="filter-colors">
                                    {
                                        colors.map((color, i) =>
                                            <>
                                                <OverlayTrigger
                                                    key="bottom"
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip-bottom`}>
                                                            <strong className="tw-text-xl">{FirstLetterCapitalize(color.colorname)}</strong>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="round" key={i}>
                                                        <input type="checkbox"
                                                            id={`color-${i + 1}`}
                                                            onClick={(e) => {
                                                                changeColor(color.colorid);
                                                                setError('');
                                                            }}
                                                            checked={colorId === color.colorid ? true : false}
                                                        />
                                                        <label
                                                            htmlFor={`color-${i + 1}`}
                                                            style={{ backgroundColor: color.color }}
                                                        >
                                                        </label>
                                                    </div>
                                                </OverlayTrigger>

                                            </>
                                        )
                                    }
                                </div> :
                                <div className="product-nav product-nav-thumbs">
                                    {
                                        product.variants[0].model ?
                                            product.variants.map((vari, i) =>
                                                <Link to="#" key={i} className={0 === i ? 'active' : ''}>
                                                    <img src={process.env.PUBLIC_URL + '/' + vari.model} alt="product desc" />
                                                </Link>
                                            ) :
                                            product.variants[0].image ?
                                                product.variants.map((vari, i) =>
                                                    <Link to="#" key={i} className={0 === i ? 'active' : ''}>
                                                        <img src={process.env.PUBLIC_URL + '/' + vari.image} alt="product desc" />
                                                    </Link>
                                                ) : ''
                                    }
                                </div>
                            }
                        </div> : ''
                    }

                    {/* testing */}
                    <div className='tw-mb-4'>
                        <div className="tw-flex items-center tw-justify-between">
                            <label>Available Sizes : {error ? <span className="text-danger">{error}</span> : ''}</label>
                            <button
                                onClick={e => { e.preventDefault(); handleSizeGuideShow(product.categoryid) }}
                                className="tw-text-lg tw-font-medium tw-text-red-600 hover:tw-text-red-500">
                                Size Guide
                            </button>
                        </div>

                        <RadioGroup value={size} onChange={handleSizeChange} className="tw-mt-4">
                            <RadioGroup.Label className="tw-sr-only">Choose a size</RadioGroup.Label>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6 xl:tw-grid-cols-10 sm:tw-grid-cols-3 lg:tw-grid-cols-8">
                                {sizes?.map((size) => (
                                    <RadioGroup.Option
                                        key={size.size}
                                        value={size.sizeid}
                                        disabled={!parseInt(size.productsizequantity) > 0}
                                        className={({ active }) =>
                                            classNames(
                                                parseInt(size.productsizequantity) > 0
                                                    ? 'tw-bg-white tw-shadow-sm tw-text-gray-900 tw-cursor-pointer'
                                                    : 'tw-bg-gray-50 tw-text-gray-200 tw-cursor-not-allowed',
                                                //active ? 'tw-ring-2 tw-ring-gray-500' : '',
                                                'tw-group tw-relative tw-border tw-rounded-md pt-1 tw-px-4 tw-flex tw-items-center tw-justify-center tw-text-sm tw-font-medium tw-uppercase hover:tw-bg-gray-50 focus:tw-outline-none tw-sm:tw-flex-1 tw-sm:tw-py-6'
                                            )
                                        }
                                    >
                                        {({ active, checked }) => (
                                            <>
                                                <RadioGroup.Label as="label"><strong>{size.size}</strong></RadioGroup.Label>
                                                {parseInt(size.productsizequantity) > 0 ? (
                                                    <div
                                                        className={classNames(
                                                            checked ? 'tw-border-gray-500' : 'tw-border-transparent',
                                                            'tw-border-2 tw-absolute tw--inset-px tw-rounded-md tw-pointer-events-none'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <div
                                                        aria-hidden="true"
                                                        className="tw-absolute tw--inset-px tw-rounded-md tw-border-2 tw-border-gray-200 tw-pointer-events-none"
                                                    >
                                                        <svg
                                                            className="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-text-gray-200 tw-stroke-2"
                                                            viewBox="0 0 100 100"
                                                            preserveAspectRatio="none"
                                                            stroke="currentColor"
                                                        >
                                                            <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </div>
                        </RadioGroup>
                    </div>
                    {/* end testing */}
                    <div className="details-filter-row details-row-size">
                        <div class="input-group mb-2">
                            <label htmlFor="qty">Qty:</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text" onClick={(e) => onChangeQuantity(e, '0')} id="basic-addon1">-</span>
                            </div>
                            <input type="text"
                                className="form-control"
                                defaultValue={quantity}
                                min="1"
                                // max={ item.stock }
                                value={quantity}
                                // value={quantity}
                                data-decimals="0"
                                onChange={(e) => onChangeQuantity(e, '2')}
                                required
                                style={{ textAlign: 'center', minWidth: '60px', maxWidth: '3px' }}
                            />
                            <div class="input-group-append">
                                <span class="input-group-text" onClick={(e) => onChangeQuantity(e, '1')} id="basic-addon1"><b>+</b></span>
                            </div>
                        </div>
                    </div>

                    <div className="product-details-action">
                        {isIEBrowser() || isEdgeBrowser() ?
                            <button className="btn-product btn-primary btn-cart text-white" onClick={(e) => addToCartHandler(product.productid)} style={{ minHeight: "4rem" }}><span>add to cart</span></button>
                            :
                            <button className="btn-product btn-primary btn-cart text-white" onClick={(e) => addToCartHandler(product.productid)} disabled={cartLoading ? 'disabled' : ''}>
                                {
                                    cartLoading ?
                                        <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span class="sr-only">Loading...</span></> :
                                        <span>add to cart</span>
                                }
                            </button>
                        }
                        {isIEBrowser() || isEdgeBrowser() ?
                            <div className="details-action-wrapper IE-detail-action-wrapper">
                                <button
                                    className={`btn-product btn-wishlist pl-0 pr-0 ${isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'}`}
                                    onClick={(e) => wishlistHandler(product.productid)}
                                    title={isWishlist ? "Go to wishlist" : "Add to wishlist"}
                                >
                                    <span>{isWishlist ? "go to wishlist" : "add to wishlist"}</span>
                                </button>
                            </div> :
                            <div className="details-action-wrapper">
                                <button
                                    className={`btn-product btn-wishlist pl-0 pr-0 ${isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'}`}
                                    onClick={(e) => wishlistHandler(product.productid)}
                                    title={isWishlist ? "Go to wishlist" : "Add to wishlist"}
                                >
                                    <span>{isWishlist ? "Go to Wishlist" : "Add to Wishlist"}</span>
                                </button>
                            </div>
                        }
                    </div>

                    {features &&
                        <>
                            <hr />
                            <Grid container xs={12} justifyContent="center" alignItems="center">
                                <Grid item container xs={12} sm={6} padding={1} sx={{
                                    backgroundColor: "#fff"
                                }}>
                                    <Grid xs={4} sx={{
                                        height: "60px",

                                    }} className="tw-bg-gray-100" container justifyContent="center" alignItems="center">
                                        <i className="fa fa-money"></i>
                                    </Grid>
                                    <Grid xs={8} container justifyContent="center" alignItems="center">
                                        <strong>{FirstLetterCapitalize(product.cod)}</strong>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={6} padding={1} sx={{
                                    backgroundColor: "#fff"
                                }}>
                                    <Grid xs={4} item sx={{
                                        height: "60px",

                                    }} className="tw-bg-gray-100" container justifyContent="center" alignItems="center">
                                        <i className="fa fa-undo"></i>
                                    </Grid>
                                    <Grid xs={8} item container justifyContent="center" alignItems="center" padding={.5}>
                                        <strong>{FirstLetterCapitalize(product.exchange)}</strong>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr />
                        </>
                    }
                    <div className="product-details-footer">
                        <div className="social-icons social-icons-sm">
                            <span className="social-label">Share:</span>
                            <Link to="#" className="social-icon social-facebook" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></Link>
                            <Link to="#" className="social-icon social-twitter" title="Twitter" target="_blank"><i className="icon-twitter"></i></Link>
                            <Link to="#" className="social-icon social-instagram" title="Instagram" target="_blank"><i className="icon-instagram"></i></Link>
                            <Link to="#" className="social-icon social-pinterest " title="Pinterest" target="_blank"><i className="icon-pinterest"></i></Link>
                        </div>
                    </div>
                    {props.children}
                </div>

                <DescOne ref={reviewRef}
                    isAuthenticated={isAuthenticated}
                    productDescription={FirstLetterCapitalize(product?.productdesc)}
                    productReturnDescription={FirstLetterCapitalize(product?.returnpolicy)}
                    productId={product?.productid}
                    ProductRatingCount={product?.productratingcount || 0}
                    reviews={reviews} />

            </div>
            {cart?.data?.cartvariables?.length > 0 &&
                <Drawer
                    title="Shopping Cart"
                    placement="right"
                    width={440}
                    closable={false}
                    onClose={onClose}
                    visible={cart?.isAddNewItem}
                >

                    <div class="flex flex-col h-screen justify-between">
                        <div className="tw-mt-8">
                            <div className="tw-flow-root">
                                <ul role="list" className="tw--my-6 tw-divide-y tw-divide-gray-200">
                                    {cart?.data?.cartvariables?.map((item, index) => (
                                        <li key={index} className="tw-py-6 tw-flex">
                                            <div className="tw-flex-shrink-0 tw-w-40 h-40 tw-border tw-border-gray-200 tw-rounded-md tw-overflow-hidden">
                                                <img
                                                    src={item.productphoto}
                                                    alt={item.productname}
                                                    className="tw-w-full tw-h-full tw-object-center tw-object-cover"
                                                />
                                            </div>

                                            <div className="tw-ml-4 tw-flex-1 tw-flex tw-flex-col">
                                                <div>
                                                    <div className="tw-flex tw-justify-between tw-text-base tw-font-medium tw-text-gray-900">
                                                        <h3>
                                                            <Link className="tw-capitalize tw-text-2xl theme-text-color" to={`${process.env.PUBLIC_URL}/product-detail?productid=${item.productid}`} >{FirstLetterCapitalize(item.productname)}</Link>
                                                        </h3>
                                                        <p className="tw-ml-4">₹{(item.productamount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                    </div>
                                                    <p className="tw-mt-1 tw-text-sm tw-text-gray-500 tw-h-8 tw-w-8" style={{ backgroundColor: item.productcolor }}></p>
                                                </div>
                                                <div className="tw-flex-1 tw-flex tw-items-end tw-justify-between tw-text-sm">
                                                    <p className="tw-text-gray-500">Size : {item.productsize}</p>
                                                    <p className="tw-text-gray-500">Qty : {item.productquantity}</p>

                                                    <div className="flex">
                                                        <button type="button" className=" tw-text-red-600 tw-text-xl hover:tw-text-red-500" onClick={() => removeCartItem(item.cartid)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-2 tw-border-t tw-border-gray-200 tw-py-6 tw-px-4 sm:tw-px-6">
                            <div className="tw-flex tw-justify-between tw-text-base tw-font-medium tw-text-gray-900">
                                <p>Subtotal</p>
                                <p className="tw-text-bold">₹{cart?.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                            <p className="tw-mt-0.5 tw-text-sm tw-text-gray-500">Shipping and taxes calculated at checkout.</p>
                            <div className="tw-mt-6">
                                <Link
                                    to={`${process.env.PUBLIC_URL}/cart`}
                                    className="tw-flex tw-justify-center tw-items-center tw-px-6 tw-py-3 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-white theme-color"
                                >
                                    Checkout
                                </Link>
                            </div>
                            <div className="tw-mt-6 tw-flex tw-justify-center tw-text-sm tw-text-center tw-text-gray-500">
                                <p>
                                    or{' '}
                                    <button
                                        type="button"
                                        className="theme-text-color tw-font-medium "
                                        onClick={onClose}
                                    >
                                        Continue Shopping<span aria-hidden="true"> &rarr;</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </Drawer>
            }
        </>
    )
}

export default ProductDetailOne;