import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { Grid } from "@mui/material";
import AddAddressScreen from '../AddAddress/AddAddressScreen';
// import PropTypes from 'prop-types';
import { fetchingAddress, deleteAddressAction } from '../../../actions/AddressAction';

import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';

import AddressItem from '../AddressItemComponent';
import { FirstLetterCapitalize } from '../../../utils/common';

import { showModal } from '../../../actions/QuickViewModelsActions';

function BuyAddressScreen(props) {
    const { fetchingAddress,deleteAddressAction, isLoading, data,isDeleteLoading,isDeleted, match, history } = props;
    const addressType = match.params.addresstype;
    const selectButtonUrl = addressType === "1" ? '2' : '/checkout';
    
    const [isShown,setIsShown]= useState(false);
    const [addressIndex,setAddressIndex]= useState(-1);

    useEffect(() => {
        fetchingAddress();
    }, [fetchingAddress]);

    const deleteAddressItem=(e,addressId,index)=>
    {
        e.preventDefault();
        setAddressIndex(index);
        deleteAddressAction(addressId);
        notification.open({
            message: 'Deleting Address',
            key:'deleting',
            placement:'bottomRight',
            description:
              'Your Request is in Progress.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
        });
    }
    if(isDeleted && isDeleted.status){
        if(isDeleted.status)
            notification.success({
                message: 'Address Deleted',
                key:'deleting',
                placement:'bottomRight',
                description:
                'Your Request Completed.',
                onClick: () => {
                console.log('Notification Clicked!');
                },
            });
        else if(!isDeleted.status)
            notification.warn({
                message: 'Error',
                key:'deleting',
                placement:'bottomRight',
                description:
                'Something went wrong.',
                onClick: () => {
                console.log('Notification Clicked!');
                },
            });
        delete isDeleted.status;
    }
    
    return (
        <>
            <Helmet>
                <title>Gracewell | {addressType === "1" ? 'Billing' : 'Shipping'} Addresses</title>
            </Helmet>
            <h1 className="d-none">Gracewell - {addressType === "1" ? 'Billing' : 'Shipping'} Addresses</h1>
            <div className="main" style={{minHeight:"500px"}}>
                {/* <PageHeader title={`${addressType === "1" ? 'Billing' : 'Shipping'} Address List`} subTitle="Addresses" /> */}
                <Breadcrumb title="Addresses" />
                <div className="page-content">
                    <div className="dashboard">
                        <div className="container">
                        <Grid container spacing={2} mb={1}>
                                <Grid item xs={12} md={8}>
                                    The following addresses will be used on the checkout page by default.
                                </Grid>
                                <Grid item xs={12} md={4}>{data &&
                                    data.status &&
                                    data.addvariables.length > 0 ? 
                                    <button className="btn btn-primary pull-right" onClick={(e)=> {e.preventDefault();setIsShown(true);}}><i className="fa fa-plus"></i> Add New Address</button>
                                    :''
                                }
                            </Grid>
                            </Grid>
                            {isDeleteLoading ? <p className="alert alert-warning">Deleting in progress.</p>:''}
                            <Grid container spacing={1}>
                                {!isLoading ? data &&
                                    data.status ?
                                    data.addvariables.map((item, index) =>
                                        <AddressItem
                                            item={item}
                                            index={index}
                                            deleteAddress={deleteAddressItem}
                                            isAddressDeleting={isDeleteLoading}
                                            AddressIndex = {addressIndex}
                                            isBillingOrShippingAddress={addressType}
                                            addressType= {addressType}
                                            selectButtonUrl={selectButtonUrl}
                                            history={history}
                                        />
                                    ) :
                                    <>
                                        <div className="col-lg-12">
                                            {/* <NotFoundScreen /> */}
                                            <center>
                                                <p className="text text-danger">{ FirstLetterCapitalize( data && data.message) || "No Adresses in your directory."}</p>
                                                <button className="btn btn-primary" onClick={(e)=> {
                                                    e.preventDefault();
                                                    setIsShown(true);
                                                }}><i className="fa fa-plus"></i> Add New Address</button>
                                            </center>
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-12">
                                        <center><i className="fa fa-spin fa-spinner"></i></center>
                                    </div>
                                }
                            </Grid>
                        </div>
                    </div>
                </div>
            <AddAddressScreen isShown ={isShown} setIsShown={setIsShown}/>
            </div>
        </>
    )
}

export const mapStateToProps = (state) => ({
    isLoading: state.AddressReducer.isLoading,
    data: state.AddressReducer.data,
    isDeleteLoading : state.AddressReducer.isDeleteLoading,
    isDeleted : state.AddressReducer.isDeleted,
    add : state.AddressReducer.addAddress
})

export default connect(mapStateToProps, { showModal,fetchingAddress, deleteAddressAction})(BuyAddressScreen);