import { FETCH_PINCODE, FETCH_PINCODE_LOADING, FETCH_PINCODE_FAILED } from "../actions/types";

const initialState = {
    isLoading: false,
};

function PincodeReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PINCODE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_PINCODE:
            return {
                ...state,
                data: payload,
                isLoading: false,
            };
        case FETCH_PINCODE_FAILED:
            return {
                ...state,
                data: payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default PincodeReducer;