import qs from 'querystring';
import api from "../utils/api";
import { notification } from 'antd';
import { MY_ORDERS,
        MY_ORDERS_LOADING,
        MY_ORDERS_FAILED,
        MY_ORDER_DETAIL,
        MY_ORDER_DETAIL_LOADING,
        MY_ORDER_DETAIL_FAILED,
        
    } from "./types";

import { FetchUserIdFromLocalStorage, FetchGuestUserIdFromLocalStorage } from '../utils/common';

export const fetchingOrders = ()=> async dispatch => {
    const body ={
        method:'myorders',
        custid:FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
    };
    try{
        dispatch({
            type:MY_ORDERS_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:MY_ORDERS,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:MY_ORDERS_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}

export const fetchingOrderDetail=(orderid)=> async dispatch => {
    const body={
        method:'trackmyorder',
        orderid:orderid
    }
    try{
        dispatch({
            type:MY_ORDER_DETAIL_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:MY_ORDER_DETAIL,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:MY_ORDER_DETAIL_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}

export const canceOrder=({orderid, statusid, remarks})=> async dispatch => {
    const body={
        method:'ordercancel',
        orderid,
        remark: remarks,
        statusid,
    }
    try{
        notification.open({
            message: 'Order Cancel',
            key:'adding',
            placement:'bottomRight',
            description:
              'Your Request is in Progress.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        const response = await api.post('services.aspx',qs.stringify(body),);
          if(response.data.status){
            notification.success({
                message: 'Order Cancel',
                key:'adding',
                placement:'bottomRight',
                description:
                  'Your Order is Cancelled.',
                onClick: () => {
                  console.log('Notification Clicked!');
                },
              });
          }else{
            notification.error({
                message: 'Order Cancel',
                key:'adding',
                placement:'bottomRight',
                description:
                  response.data.message,
                onClick: () => {
                  console.log('Notification Clicked!');
                },
              });
          }
    }
    catch(err){
        notification.error({
            message: 'Order Cancel',
            key:'adding',
            placement:'bottomRight',
            description:
              'Something went wrong.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
    }
}
