// useraddresslist

import qs from 'querystring';
import api from "../utils/api";
import {
    CANCEL_REMARKS
} from "./types";

export const fetchCancelRemarks = (productid) => async dispatch => {
    const body = {
        method: 'cancelremarks',
        productid:productid,
    };
    try {
        const response = await api.post('services.aspx', qs.stringify(body),);
        dispatch({
            type: CANCEL_REMARKS,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({ 
            type: CANCEL_REMARKS, 
            payload: { 
                status: false, 
                message: 'Something went wrong.', 
            }, 
        });
    }
}
