import qs from 'querystring';
import api from "../utils/api";
import { MY_PROFILE,MY_PROFILE_LOADING,MY_PROFILE_FAILED } from "./types";

import { FetchUserIdFromLocalStorage, FetchGuestUserIdFromLocalStorage } from '../utils/common';

export const fetchingProfile = ()=> async dispatch => {
    const body ={
        method:'userprofile',
        custid:FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
    };
    try{
        dispatch({
            type:MY_PROFILE_LOADING,
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        dispatch({
            type:MY_PROFILE,
            payload : response.data
        });
    }
    catch(err){
        dispatch({
            type:MY_PROFILE_FAILED,
            payload:{
                status:false,
                message:'Something went wrong.',
            },
        });
    }
}