import React, { Fragment, } from 'react';

import './partials/details/detail-one.css';

function LoadingDetail(props) {
    return (
        <>
        <div className={`row skel-pro-single`}>
            <div className="col-md-5">
                <div className="skel-product-gallery">
                </div>
            </div>
            <div className="col-md-7">
                    <div className="entry-summary row">
                        <div className="col-md-12">
                            <div className="entry-summary1"></div>
                        </div>
                        <div className="col-md-12">
                            <div className="entry-summary2"></div>
                        </div>
                    </div>  
            </div>
            </div>
        </>
    )
}

export default LoadingDetail;