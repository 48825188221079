import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddNewRatingScreen from "../../../Rating/AddNewRatingScreen";
import ViewRatingsScreen from "../../../Rating/ViewRatingsScreen";

function DescOne(props) {
  const {
    isAuthenticated,
    productDescription,
    productReturnDescription,
    productId,
    ProductRatingCount,
  } = props;

  return (
    <Tabs selectedTabClassName="show" selectedTabPanelClassName="active show">
      <div className="product-details-tab pb-2">
        <TabList className="nav nav-pills justify-content-center">
          <Tab className="nav-item">
            <span className="nav-link"> Description</span>
          </Tab>

          {/* <Tab className="nav-item">
                        <span className="nav-link"> Additional information</span>
                    </Tab> */}

          <Tab className="nav-item">
            <span className="nav-link">Shipping & Returns</span>
          </Tab>

          <Tab className="nav-item">
            <span className="nav-link">
              Reviews ({ProductRatingCount || 0})
            </span>
          </Tab>
        </TabList>

        <div className="tab-content">
          <TabPanel className="tab-pane">
            <div
              className="product-desc-content p-1 tw-capitalize"
              dangerouslySetInnerHTML={{ __html: productDescription }}
            ></div>
          </TabPanel>

          <TabPanel className="tab-pane">
            <div
              className="product-desc-content p-3"
              dangerouslySetInnerHTML={{ __html: productReturnDescription }}
            ></div>
          </TabPanel>

          <TabPanel className="tab-pane">
            {isAuthenticated ? (
              <AddNewRatingScreen productId={productId} />
            ) : (
              ""
            )}
            <ViewRatingsScreen productId={productId} />
          </TabPanel>
        </div>
      </div>
    </Tabs>
  );
}

export default DescOne;
