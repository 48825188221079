import qs from 'querystring';
import api from "../utils/api";
import { CART_LOADING, CART,CART_ADD, FAILED,CART_UPDATION,CART_UPDATION_DONE, CART_ADDED_COMPLETE } from "./types";

import { FetchUserIdFromLocalStorage, FetchGuestUserIdFromLocalStorage } from '../utils/common';

export const fetchingCart = ()=> async dispatch => {
    const body ={
        method:'mycart',
        custid:FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
    };
    try{
        // dispatch({
        //     type:CART_LOADING,
        // });
        const response = await api.post('services.aspx',qs.stringify(body),);

        dispatch({
            type:CART,
            payload : response.data
        });
        dispatch({
            type:CART_UPDATION_DONE
        });
    }
    catch(err){
        dispatch({type:FAILED,payload:{status:false,message:'Something went wrong.',},},);
    }
}

export const addToCart = (productId,productQuantity,productSizeId,productColorId)=> async dispatch=>{
    const body ={
        method:'addcart',
        custid:FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        productid:productId,
        quantity:productQuantity,
        size:productSizeId,
        color:productColorId
    }
    try{
        dispatch({
            type:CART_LOADING
        });
        const response = await api.post("services.aspx",qs.stringify(body));
        if(response.data.status){
            dispatch({
                type:CART_ADD,
                payload:response.data,
            });
            dispatch(fetchingCart());
        }
    }catch(err){
        dispatch({type:FAILED,payload:{status:false,message:err,},},);
    }
}

export const removeCartItem = (cartId)=> async dispatch => {
    const body ={
        method:'deletecart',
        cartid:cartId,
    };
    try{
        dispatch({
            type:CART_UPDATION
        });
        const response = await api.post('services.aspx',qs.stringify(body),);
        if(response.data.status){
            dispatch(fetchingCart());
        }
    }
    catch(err){
        dispatch({type:FAILED,payload:{status:false,message:err,},},);
    }
}
export const addRemoveCartProductQuantity=(cartid,productid,type)=>async dispatch => {
    const body={
        method:'productquantity',
        cartid:cartid,
        productid:productid,
        type:type
    }
    try{
        dispatch({
            type:CART_UPDATION
        });
        const response = await api.post('services.aspx',qs.stringify(body));
        if(response.data.status){
            dispatch(fetchingCart());
            dispatch({
                type:CART,
                payload : null
            });
        }
    }
    catch(err){
        dispatch({
            type:FAILED,
            payload:{
                status:false,
                message:err,
            }
        });
    }
}

export const CartAddedShowComplete = () => async dispatch =>{
    dispatch({
        type:CART_ADDED_COMPLETE
    });
}