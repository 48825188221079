// useraddresslist

import qs from 'querystring';
import api from "../utils/api";
import {
    ADD_RATING_LOADING,
    ADD_RATING,
    ADD_RATING_FAILED,
    FETCH_RATING
} from "./types";
import { FetchUserIdFromLocalStorage, FetchGuestUserIdFromLocalStorage } from '../utils/common';

export const fetchRating = (productid) => async dispatch => {
    const body = {
        method: 'productcomments',
        // custid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        productid:productid,
        // comments:comment,
        // rating : rating
    };
    try {
        const response = await api.post('services.aspx', qs.stringify(body),);
        dispatch({
            type: FETCH_RATING,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({ 
            type: FETCH_RATING, 
            payload: { 
                status: false, 
                message: 'Something went wrong.', 
            }, 
        });
    }
}

export const addRating = (productid,rating,comment) => async dispatch => {
    const body = {
        method: 'docomments_v1',
        // custid:"G895201",
        custid: FetchUserIdFromLocalStorage() || FetchGuestUserIdFromLocalStorage(),
        productid:productid,
        comments:comment,
        rating : rating
    };
    try {
        dispatch({
            type: ADD_RATING_LOADING,
        });
        const response = await api.post('services.aspx', qs.stringify(body),);
        if(response.data.status){
            dispatch(fetchRating(productid));
        }
        dispatch({
            type: ADD_RATING,
            payload: response.data
        });
    }
    catch (err) {
        dispatch({ 
            type: ADD_RATING_FAILED, 
            payload: { 
                status: false, 
                message: 'Something went wrong.', 
            }, 
        });
    }
}