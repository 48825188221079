// Category and subcategory
export const FETCH_CATEGORY_SUBCATEGORY_HEADER_LOADING = "FETCH_CATEGORY_SUBCATEGORY_HEADER_LOADING";
export const FETCH_CATEGORY_SUBCATEGORY_HEADER="FETCH_CATEGORY_SUBCATEGORY_HEADER";
export const FETCH_CATEGORY_SUBCATEGORY_HEADER_FAILED = "FETCH_CATEGORY_SUBCATEGORY_HEADER_FAILED";

export const GUEST_LOGIN="GUEST_LOGIN";
export const LOGIN = "LOGIN";
export const VERIFYACCOUNT="VERIFYACCOUNT";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_CREATION_LOADING = "ACCOUNT_CREATION_LOADING";
export const ACCOUNT_CREATION_ERROR="ACCOUNT_CREATION_ERROR";

export const REMOVE_LOGIN_REGISTER_ERROR = 'REMOVE_LOGIN_REGISTER_ERROR';

export const LOADING="LOADING";
export const HOME_LOADING = "HOME_LOADING";
export const HOME="HOME";
export const FAILED="FAILED";

export const TOKEN_EXISTENCE ='TOKEN_EXISTENCE';

export const CART_LOADING = 'CART_LOADING';
export const CART = "CART";
export const CART_ADD = 'CART_ADD';
export const CART_UPDATION = 'CART_UPDATION';
export const CART_UPDATION_DONE = 'CART_UPDATION_DONE';

export const FAVOURITE_LOADING = 'FAVOURITE_LOADING';
export const FAVOURITE = "FAVOURITE";
export const ADD_FAVOURITE = 'ADD_FAVOURITE';

export const SENDOTP = 'SENDOTP';

export const PRODUCT_DETAIL_LOADING = 'PRODUCT_DETAIL_LOADING';
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';
export const PRODUCT_DETAIL_FAILED  = 'PRODUCT_DETAIL_FAILED';

// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

//Quick     
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW';
export const CLOSE_QUICKVIEW = 'CLOSE_QUICKVIEW';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_QTY = 'CHANGE_QTY';
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING';
// cart Local
export const ADD_TO_CART_LOCAL = 'ADD_TO_CART_LOCAL';
export const REMOVE_FROM_CART_LOCAL = 'REMOVE_FROM_CART_LOCAL';
export const CHANGE_QTY_LOCAL = 'CHANGE_QTY_LOCAL';
export const CART_ADDED_COMPLETE = 'CART_ADDED_COMPLETE';
// WishList
export const TOGGLE_WISHLIST = 'TOGGLE_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
export const RESET_COMPARE = 'RESET_COMPARE';

//Product Filters
export const SORT_BY = 'SORT_BY';
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SIZE_FILTER = 'SIZE_FILTER';
export const COLOR_FILTER = 'COLOR_FILTER';
export const BRAND_FILTER = 'BRAND_FILTER';
export const PRICE_FILTER = 'PRICE_FILTER';
export const RATING_FILTER = 'RATING_FILTER';

//Newsletter
export const HIDE_NEWSLETTER_MODAL = 'HIDE_NEWSLETTER_MODAL';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_NEWSLETTER = 'REMOVE_NEWSLETTER';

// Addresses
export const ADDRESS_LOADING="ADDRESS_LOADING";
export const FETCH_ADDRESS="FETCH_ADDRESS";
export const ADDRESS_FAILED="ADDRESS_FAILED";

export const DELETE_ADDRESS_LOADING = "DELETE_ADDRESS_LOADING";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const DELETE_ADDRESS_FAILED="DELETE_ADDRESS_FAILED";

export const ADD_ADDRESS_LOADING = "ADD_ADDRESS_LOADING";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_FAILED="ADD_ADDRESS_FAILED";
// change password
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAILED="CHANGE_PASSWORD_FAILED";

// my-profile
export const MY_PROFILE_LOADING = "MY_PROFILE_LOADING";
export const MY_PROFILE = "MY_PROFILE";
export const MY_PROFILE_FAILED="MY_PROFILE_FAILED";

// my-orders
export const MY_ORDERS_LOADING = "MY_ORDERS_LOADING";
export const MY_ORDERS = "MY_ORDERS";
export const MY_ORDERS_FAILED="MY_ORDERS_FAILED";

// my-order-detail
export const MY_ORDER_DETAIL_LOADING = "MY_ORDER_DETAIL_LOADING";
export const MY_ORDER_DETAIL = "MY_ORDER_DETAIL";
export const MY_ORDER_DETAIL_FAILED="MY_ORDER_DETAIL_FAILED";

// faq
export const FAQ_LOADING = "FAQ_LOADING";
export const FAQ = "FAQ";
export const FAQ_FAILED="FAQ_FAILED";

// faq
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATION_FAILED="NOTIFICATION_FAILED";

//category and subcategory items
export const SHOP_LOADING = "SHOP_LOADING";
export const SHOP_ITEMS = "SHOP_ITEMS";
export const SHOP_FAILED = "SHOP_FAILED";

//checkout
export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_FAILED =  "CHECKOUT_FAILED";

//final checkout
export const CHECKOUT_FINAL_LOADING='CHECKOUT_FINAL_LOADING';
export const CHECKOUT_FINAL = "CHECKOUT_FINAL";
export const CHECKOUT_FINAL_FAILED =  "CHECKOUT_FAILED";

//fetch ratings
export const FETCH_RATING = "FETCH_RATING";

// Add Rating
export const ADD_RATING_LOADING = "ADD_RATING_LOADING";
export const ADD_RATING = "ADD_RATING";
export const ADD_RATING_FAILED="ADD_RATING_FAILED";

//state, city with pincode
export const FETCH_PINCODE_LOADING = "FETCH_PINCODE_LOADING";
export const FETCH_PINCODE = "FETCH_PINCODE";
export const FETCH_PINCODE_FAILED = "FETCH_PINCODE_FAILED";

//filters
export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_FILTERS_FAILED = "FETCH_FILTERS_FAILED";

//payment_gateway
export const PAYMENT_GATEWAY="PAYMENT_GATEWAY";

//contact_us
export const CONTACT_US='CONTACT_US';
export const CONTACT_US_FAILED = 'CONTACT_US_FAILED';

// forgot password
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const FORGOT_PASSWORD = 'FROGOT_PASSWORD';
export const FORGOT_PASSWORD_FAILED = 'FROGOT_PASSWORD_FAILED';

//coupons
export const FETCH_COUPONS = 'FETCH_COUPONS';
export const FETCH_COUPONS_FAILED = 'FETCH_COUPONS_FAILED';
export const APPLY_COUPON_LOADING = 'APPLY_COUPON_LOADING';
export const APPLY_COUPON = 'APPLY_COUPON';
export const APPLY_COUPON_FAILED = 'APPLY_COUPON_FAILED';

export const REMOVE_COUPON = 'REMOVE_COUPON';

export const SIZE_CHART_LOADING = "SIZE_CHART_LOADING";
export const SIZE_CHART = "SIZE_CHART";
export const SIZE_CHART_FAILED = "SIZE_CHART_FAILED";

export const CANCEL_REMARKS = "CANCEL_REMARKS";
