import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Card from "react-bootstrap/Card";
import { Button } from "@mui/material";
// import Custom Components
import CancelOrderModel from "./CancelOrderModel";
import Breadcrumb from "../common/breadcrumb";

import { fetchingOrders } from "../../actions/MyOrdersAction";
import { fetchCancelRemarks } from "../../actions/CancelRemarksAction";
import LoadingOrdersAnime from "../anime/LoadingOrdersAnime";
import NotFoundAnime from "../anime/NotFoundAnime";
import { FirstLetterCapitalize } from "../../utils/common";

function MyOrderScreen(props) {
  const { isLoading, orders, fetchingOrders, fetchCancelRemarks } = props;

  useEffect(() => {
    fetchingOrders();
  }, [fetchingOrders]);

  useEffect(() => {
    fetchCancelRemarks();
  },[]);

  const [isShow, setIsShown] = useState(false);
  const [orderId, setOrderId] = useState('');

  const showOrderCancelModal = (orderId) => {
    setIsShown(true);
    setOrderId(orderId);
  }
  const hideOrderCancelModal = () =>{
    setIsShown(false);
  }

  return (
    <>
      <Helmet>
        <title>Gracewell | My Order</title>
      </Helmet>
      <h1 className="d-none">Gracewell - My Order</h1>
      <div className="main">
        <Breadcrumb title="My Order" />
        <div className="page-content">
          <div className="container">
            <div className="row justify-content-sm-center">
              {!isLoading ? (
                orders && orders.status ? (
                  <div className="col-12">
                    {orders.orderidvariables.map((item, index) => {
                      return (
                        <Card className="mb-2">
                          <Card.Header className="p-4 border-bottom row">
                            <span className="t-w-full col-8 col-xs-6">
                              <div className="p-2 pull-left">
                                <h4>Order Number</h4>
                                <h3 className="tw-text-gray-400">
                                  #{item.orderid}
                                </h3>
                              </div>
                              <div className="p-2 pull-left ml-4">
                                <h4>Date Placed</h4>
                                <h3 className="tw-capitalize tw-text-gray-400">
                                  {item.orderdate}
                                </h3>
                              </div>
                              <div className="p-2 pull-left ml-4">
                                <h4>Total Amount</h4>
                                <h3 className="tw-capitalize">
                                  ₹
                                  {item.ordervariables.reduce(
                                    (prev, item) =>
                                      prev + parseInt(item.producttotalprice),
                                    0
                                  )}
                                </h3>
                              </div>
                            </span>
                            <span className="col-3 col-xs-6 pull-right" spacing={2}>
                              {item.orderstatus !== "cancelled" && 
                              <Button
                                component={Link}
                                variant="outlined"
                                size="large"
                                to={`my-order-detail?orderid=${item.orderid}`}
                                sx={{
                                  margin:1
                                }}
                              >
                                {" "}
                                Track Order
                              </Button>
                              }
                              {item.orderstatus !== "cancelled" && 
                              <Button
                                target="_blank"
                                rel="noreferrer"
                                // className="btn btn-outline-secondary mr-1"
                                variant="outlined"
                                color="success"
                                size="large"
                                href={`https://api.prod.gracewell.co.in/invoice.aspx?orderid=${item.orderid}`}
                              >
                                {" "}
                                View Invoice
                              </Button>
                              }
                              {item.orderstatus === "cancelled" && 
                              <Button variant="outlined" color="error" >Cancelled</Button>
                              }
                            </span>
                          </Card.Header>
                          {item.ordervariables.map((singleItem, index) => {
                            return (
                              <>
                                <Card.Body key={index}>
                                  <div className="row no-gutters p-4">
                                    <div className="col-md-1">
                                      <Link
                                        to={`/product-detail?productid=${singleItem.productid}`}
                                      >
                                        <img
                                          className="img-fluid"
                                          src={singleItem.productphoto}
                                          alt={singleItem.productname}
                                        />
                                      </Link>
                                    </div>
                                    <div className="col-md-8 p-4">
                                      <p className="tw-font-bold pull-left tw-capitalize">
                                        <Link
                                          to={`/product-detail?productid=${singleItem.productid}`}
                                        >
                                          {" "}
                                          {singleItem.productname}{" "}
                                        </Link>
                                      </p>
                                      <br />
                                      <br />
                                      <p className="tw-capitalize">
                                        <strong>Size :</strong>{" "}
                                        {singleItem.productsize}
                                      </p>
                                      <p className="tw-capitalize">
                                        <strong>Color :</strong>{" "}
                                        <span
                                          className="tw-mt-1 tw-text-sm tw-text-gray-500 tw-h-8 tw-w-8"
                                          style={{
                                            backgroundColor:
                                              singleItem.productcolor,
                                            display: "inline-block",
                                            paddingTop: "5px",
                                          }}
                                        >
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                      </p>
                                    </div>
                                    <div className="col-md-3">
                                      <small className="text-muted pull-right">
                                        ₹{singleItem.productprice} *{" "}
                                        {singleItem.productquantity} = ₹
                                        {singleItem.producttotalprice}
                                      </small>
                                    </div>
                                  </div>
                                </Card.Body>
                                <Card.Footer class="bg-transparent p-4 ">
                                  <span className="tw-capitalize">
                                    <i className="fa fa-check-circle"></i>{" "}
                                    Estimated Delivery : {item.estimatedelivery}
                                  </span>
                                  <span className="pull-right">
                                    <Link
                                      className="btn btn-link mr-1"
                                      to={`/product-detail?productid=${singleItem.productid}`}
                                    >
                                      <i className="fa fa-compass"></i> Buy
                                      Again
                                    </Link>{" "}
                                    {item.orderstatus !== "delivered" && item.orderstatus !== "cancelled" && (<>                                    
                                    |
                                    <button
                                      className="btn btn-link mr-1"
                                      onClick={(e)=>{
                                        e.preventDefault();
                                        showOrderCancelModal(item.orderid);
                                      }}
                                    >
                                      <i className="fa fa-times"></i> Cancel
                                    </button>
                                    </>)}
                                  </span>
                                </Card.Footer>
                              </>
                            );
                          })}
                        </Card>
                      );
                    })}
                  </div>
                ) : (
                  <div className="col-lg-12">
                    <NotFoundAnime />
                    <center>
                      <p className="text text-danger">
                        {FirstLetterCapitalize(orders.message) ||
                          "No Orders in your directory."}
                      </p>
                    </center>
                  </div>
                )
              ) : (
                <LoadingOrdersAnime />
              )}
            </div>
            <CancelOrderModel
              orderId={orderId}
              isShown={isShow}
              setIsShown={hideOrderCancelModal}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const mapStateToProps = (state) => ({
  isLoading: state.MyOrdersReducer.isLoading,
  orders: state.MyOrdersReducer.data,
});

export default connect(mapStateToProps, {
  fetchingOrders,
  fetchCancelRemarks,
})(MyOrderScreen);
